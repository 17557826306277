import { toast } from "react-toastify"
import { setAuthentication } from '../store/authenticationSlice';

export const statusNameById = (status) => {
  switch (status) {
    case 1:
      return "Received to Warehouse"
    case 2:
      return "Information required"
    case 3:
      return "Consolidating"
    case 4:
      return "Ready for shipment"
    case 5:
      return "Payment required"
    case 6:
      return "Processing started"
    case 7:
      return "Will be shipped soon"
    case 8:
      return "Delivered"
    case 9:
      return "On the way"
    case 10:
      return "On Hold"
    case 11:
      return "On Hold"
    case 12:
      return 'Return a Package'
    default:
      return null;
  }
}

export const statusImgById = (status) => {
  switch (status) {
    case 1:
      return "/images/recieveToWarehouse.svg"
    case 2:
      return '/images/informationrequired.svg'
    case 3:
      return '/images/consolidation.svg'
    case 4:
      return '/images/readytoship.svg'
    case 5:
      return '/images/paymentrequired.svg'
    case 6:
      return '/images/processing_started_img.svg'
    case 7:
      return "/images/willBeShipSoon.svg"
    case 8:
      return '/images/delivered.svg'
    case 9:
      return "/images/onTheWay.svg"
    case 10:
      return '/images/onHoldPackage.svg'
    case 11:
      return '/images/onHoldPackage.svg'
    case 12:
      return '/images/returnPackage.svg'
    default:
      return null;
  }
}

export const getImageByCardName = (name) => {
  switch (name) {
    case 'Visa':
      return "/images/VISA-Logo.png"
    case 'MasterCard':
      return '/images/MasterCard_Logo.svg.png'
    case 'City':
      return '/images/Citi.svg.png'
    case 'Chase':
      return '/images/Chase_Bank-Logo.wine.png'
    case 'American Express':
      return '/images/American-Express-Color.png'
    default:
      return null;
  }
}

export const convertDataToBinary = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    }
    reader.onerror = (err) => {
      reject(err);
    }
  })
}

export const httpStatus = (err) => {
  if (err?.code === 'ERR_NETWORK') {
    toast(err.message, { type: 'error' });
    return
  }
  else if (err?.code === 'ERR_BAD_RESPONSE') {
    toast(`Internal Server, Please contact customer support`, { type: 'error' });
    return;
  }
  else if (err?.response?.status === 423) {
    localStorage.clear()
    setAuthentication(false)
    window.document.location.replace('/')
    return
  }
  else if (err?.response?.data === "Unauthorized") {
    localStorage.clear()
    setAuthentication(false)
    window.document.location.replace('/')
    return
  }
  else if (err?.response?.status === 403 || err?.response?.data === "invalid token") {
    localStorage.clear()
    setAuthentication(false)
    window.document.location.replace('/')
    return
  }
  else if (err?.response?.data?.error) {
    toast(err.response.data.error, { type: 'error' });
    return
  }
  else if (err?.response?.data?.data?.message) {
    toast(err.response.data.data.message, { type: 'error' });
    return
  }
  else if (err?.response?.data?.data) {
    toast(err.response.data.data, { type: 'error' });
    return
  }
  else if (err?.response?.data) {
    toast(err.response.data, { type: 'error' });
    return
  }
  else if (Object.keys(err).length) {
    toast(`Internal Server, Please contact customer support`, { type: 'error' });
    // toast(JSON.stringify(err), { type: 'error' });
    return
  }
  else {
    toast(`Internal Server, Please contact customer support`, { type: 'error' });
    // toast(err.toString(), { type: 'error' });
    return;
  }

}

export const dateTimeFormat = (date, time = false) => {
  const tdate = new Date(date)
  if (time) return `${tdate.getMonth() + 1}-${tdate.getDate()}-${tdate.getFullYear()} ${tdate.getHours()}:${tdate.getMinutes()}:${tdate.getSeconds()}`
  return `${tdate.getMonth() + 1}-${tdate.getDate()}-${tdate.getFullYear()}`
}

export function getTimeDifference(serverTimeZone, startDate, endDate = null) {
  const present = new Date()
  const offset = present.getTimezoneOffset()
  let startTime = new Date(startDate?.slice(0, 19)).getTime();
  const absValue = Math.abs(offset)
  
  if (serverTimeZone !== offset) {
    if (offset > 0) {
      startTime = new Date(startTime - absValue * 60000).getTime()
    } else {
      startTime = new Date(startTime + absValue * 60000).getTime()
    }
  }
  const endTime = endDate ? new Date(endDate?.slice(0, 19)).getTime() : present.getTime();

  // Calculate the difference in milliseconds
  let timeDifference = Math.abs(endTime - startTime);

  // Calculate days, hours, minutes, and seconds
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  timeDifference -= days * (1000 * 60 * 60 * 24);

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  timeDifference -= hours * (1000 * 60 * 60);

  const minutes = Math.floor(timeDifference / (1000 * 60));
  timeDifference -= minutes * (1000 * 60);

  const seconds = Math.floor(timeDifference / 1000);

  let daysTime = ''
  if (seconds !== 0 && minutes === 0 && hours === 0 && days === 0)
    daysTime = daysTime + `${seconds}s ago`
  else {
    if (days <= 1) {
      if (days === 1)
        daysTime = daysTime + `${days}d`
      if (hours !== 0)
        daysTime = daysTime + ` ${hours}h`
      if (minutes !== 0)
        daysTime = daysTime + ` ${minutes}m`

      daysTime = daysTime + ' ago'
    } else daysTime = dateTimeFormat(startDate, true)
  }
  return daysTime;
}

export const getTicketID = (type, pacakgeId, id) => {
  switch (type) {
    case 1:
      return `PK${pacakgeId ? pacakgeId : id}`
    case 2:
      return `WT${id}`
    case 3:
      return `AC${id}`
    case 4:
      return `GT${id}`
    case 5:
      return `FB${id}`
    case 6:
      return `OT${id}`
    default:
      return null;
  }
}

export const getNotificationImage = (type) => {
  switch (type) {
    case 1:
      return `/images/package-notify.svg`
    case 2:
      return `/images/wallet-payment.svg`
    case 3:
      return `/images/account.svg`
    case 4:
      return `/images/general.svg`
    case 5:
      return `/images/feedback.svg`
    case 6:
      return `/images/other.svg`
    default:
      return null;
  }
}