import React, { useState } from 'react';

const SelectInput = (props) => {
  const [ focused , setFocused ] = useState(false);
  const { label , errorMessage, options,  ...restElements } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };


  return (
    <div className='normal-input-container'>
        <label className='label'>
              { props.required 
              ? <span>{label} <span className='red-text'>*</span></span> 
              : <span>{label}</span> 
              }
            <select className='input' {...restElements} onBlur={handleFocus} focused={focused.toString()} >
                <option value=''>Select Country</option>
                {
                    options.map( element => <option key={element.id} value={element.id}>{element.name}</option>)
                }
            </select>
            <div className='normal-input-error'>{errorMessage}</div>
        </label>
    </div>
  )
}

export default SelectInput