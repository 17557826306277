import React, { useState , useEffect } from 'react';
import { Pagination } from '../../components';
import { Axios } from '../../hooks';
import { dateTimeFormat, httpStatus } from '../../utils';
import './RecentActivity.css';
import { hotjar } from 'react-hotjar';

const RecentActivity = () => {
  const [ recentActivities , setrecentActivities ] = useState([]);
  const [ pageNo , setPageNo ] = useState(1);
  const [ allPages , setAllPages ] = useState(0);
  const [ date , setDate ] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    hotjar.initialize(3576088, 6);
  }, []);

  useEffect(() => {
    (async() => {
      try{
        let res;
        new Date().toISOString().slice(0, 10) !== date
        ? res = await Axios.get(`/customers/activities?page=${pageNo}&date=${date}` , { headers : { accessToken : localStorage.getItem('AccessToken')}})
        : res = await Axios.get(`/customers/activities?page=${pageNo}` , { headers : { accessToken : localStorage.getItem('AccessToken')}});
        if(res.data.count > 10){
          let allPagesCount = Math.floor(res.data.count / 10);
          let reminder = res.data.count % 10;
          if(reminder > 0){
            setAllPages(allPagesCount + 1);
          }else{
            setAllPages(allPagesCount);
          }
        }
        setrecentActivities(res.data.data);
      }catch(err){
        httpStatus(err);
      }
    })();
  },[ pageNo, date ]);

  const getImgBaseOnActivityName = (name) => {
    switch(name){
      case 'package': return '/images/package-icon.svg'
      case 'address': return '/images/location-icon.svg'
      case 'payment': return '/images/card-icon.svg'
      default: return '/images/package-icon.svg'
    }
  }

  const filterActivities = async(selectedDate) => {
    setPageNo(1);
    setDate(selectedDate);
    setAllPages(0);
    try{
      const res = await Axios.get(`/customers/activities?page=${pageNo}&date=${selectedDate}` , { headers : { accessToken : localStorage.getItem('AccessToken')}});
      if(res.data.count > 10){
        let allPagesCount = Math.floor(res.data.count / 10);
        let reminder = res.data.count % 10;
        if(reminder > 0){
          setAllPages(allPagesCount + 1);
        }else{
          setAllPages(allPagesCount);
        }
      }
      setrecentActivities(res.data.data);
    }catch(err){
      httpStatus(err);
    }
  }

  return (
    <div className='recent-activity-container'>
        <div className='page-headings'>
          <div className='checkout-calc-heading-subheading'>
              <div className='shipping-carrier-heading'> Recent Activities </div>
              <div className='checkout-calc-subheading gray-text'>Dashboard → Recent Activities </div>
          </div>
          <div className='date-filter'>
            <input className='recent-activity-date-input' type='date' value={date} onChange={ e => filterActivities(e.target.value)} />
          </div>
        </div>
        <div className='recent-activities-container'>
          { recentActivities.length > 0 
          ? recentActivities.map( activity => 
          (
            <div className='page-activity-container cursor' key={activity.id}>
              <div className='activity-image-heading-content'>
                <div className='page-activity-img'><img className='activity-image' src={getImgBaseOnActivityName(activity.activityType)} alt='activity-img' /></div>
                <div className='activity-heading-content'>
                  <div>{activity.activity}</div>
                  <div className='activity-content'>{activity.content}</div>
                </div>
              </div>
              <div className='activity-time'>{dateTimeFormat(activity.time,true)}</div>
            </div>
          ))
          :  <h3>No Activities</h3>
          }
        </div>
        { allPages > 1 ? <Pagination pageNo={pageNo} setPageNo={setPageNo} allPages={allPages} /> : ''}
    </div>
  )
}

export default RecentActivity