const isValidAlignment = element => {
    return element ? element + ',' : ''
}

export const getAlignedAddress = ({ addressLine1 , addressLine2, cityName , stateName, countryName , zip, phone }) => {
    return `${isValidAlignment(addressLine1)} ${isValidAlignment(addressLine2)} ${isValidAlignment(cityName)} ${isValidAlignment(stateName)} ${isValidAlignment(zip)} ${isValidAlignment(countryName)} ${isValidAlignment(phone)}`
}

export const getAlignedAddressWithBrs = ({ addressLine1 , addressLine2, cityName , stateName, countryName , zip, phone }) => {
    return <>
    {isValidAlignment(addressLine1)} { addressLine1?.length > 8 ? <br /> : '' }
    { addressLine2 ? '\n' + addressLine2 + ',' : ''}   { addressLine2 ? <br /> : '' }
    {isValidAlignment(cityName)} {isValidAlignment(stateName)}  { ( cityName && stateName ) ? <br /> : '' }
    {isValidAlignment(zip)}  {isValidAlignment(countryName)} { ( zip || countryName ) ? <br /> : '' }
    {isValidAlignment(phone)} <br /> </>
}