import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showPopupFunc } from '../store/warehouseSlice';

const PopupModal = () => {
  const { marketingData , showPopup } = useSelector( state => state.warehouseDetails );
  const  { popupUrl  }  = marketingData?.popup.length &&  marketingData?.popup[0];
  const dispatch = useDispatch();

    window.onclick = function(event){
        if(event.target.className === 'popup-div-active'){
          dispatch(showPopupFunc(false))
        }
    }

  return (
    <div className={ showPopup ? 'popup-div-active' : 'popup-div'}>
       { popupUrl && 
        <div className='popup'>
            <div className='close-button' onClick={() => dispatch(showPopupFunc(false))}>&times;</div>
            <img className='popup-img' src={popupUrl} alt='popup-img' style={{ maxHeight: '85vh', maxWidth: '85vw' }}   />
        </div>
       }
    </div>
  )
}

export default PopupModal