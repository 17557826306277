import React from 'react'
import { Col, Form } from 'react-bootstrap'
import { useInput } from '../../../hooks'

const CyberSourcePaymentForm = ({ data , setData }) => {
  const { inputHandler } = useInput();
  return (
        <div>
          <div className='payment-img-text'>
              <img src='/images/1.svg' alt='1-img' />
              <div className='secondary-bold-medium-text'>Payment Info</div>
          </div>
          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Card Number</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="1234 1234 1234 1234"
              className='form-input'
              maxLength="16"
              minLength='16'
              name='cardNumber'
              value={data.cardNumber}
              onChange={(e) => inputHandler(e , setData )}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide Card Number.
            </Form.Control.Feedback>
          </Form.Group>
          <div className='two-inputs'>
          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Expiry Date</Form.Label>
            <Form.Control
              required
              type="month"
              placeholder="02/24"
              min="2023-05" 
              className='form-input'
              name='expiryDate'
              value={data.expiryDate}
              onChange={(e) => inputHandler(e , setData )}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please Provide Valid Expiry Date.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>CVV</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="100"
              className='form-input'
              name='CVV'
              min="3" max="3"
              value={data.CVV}
              onChange={(e) => inputHandler(e , setData )}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide Unit Cost.
            </Form.Control.Feedback>
          </Form.Group>
          </div>
          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Card Holder Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Jhon Hanks"
              className='form-input'
              name='cardHolderName'
              value={data.cardHolderName}
              onChange={(e) => inputHandler(e , setData )}
            />
            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide a valid Name.
            </Form.Control.Feedback>
          </Form.Group>
    </div>
  )
}

export default CyberSourcePaymentForm