import React , { useState ,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReviewOrderDetails, ReviewOrderInvoice, toast } from '../../components';
import { Axios } from '../../hooks';
import { httpStatus } from '../../utils';
import './ReviewOrderPage.css';
import { hotjar } from 'react-hotjar';

const ReviewOrderPage = () => {
  const location = useLocation();
  const { state } = location;
  const [ packagesDetail , setPackagesDetail ] = useState({});
  const [ total , setTotal ] = useState(0);
  const [ splitPaymentData , setSplitPaymentData ] = useState([]);
  const [ selectedSplitId , setSelectedSplitId ] = useState(null);
  const [ allSplitData , setAllSplitData ] = useState([]);
  const navigate = useNavigate();
  const [ isNigeria, setIsNigeria] = useState(true)

  useEffect(() => {
    hotjar.initialize(3576088, 6);
  }, []);

  function any(data){
    let flag=false
    for(let i=0; i<data.length;i++){
      if(data[i]===true){
        flag = true
        break
      }
    }
    return flag
  }

  useEffect(() => {
    if(state){
      (async() => {
        for(let i = 0 ; i < state.length ; i++ ){
          try{
            const SummaryRes = await Axios.get(`/packages/summary/${state[i]}?full=true` , { headers : { accessToken: localStorage.getItem('AccessToken')}});
            setPackagesDetail( prev => ({ ...prev , [state[i]] : SummaryRes.data.data  }));
            const res = await Axios.get(`customers/paymentsplits` , { headers : { accessToken: localStorage.getItem('AccessToken')}});
            setAllSplitData(res.data.data);
          }catch(err){
            httpStatus(err);
          }
        }
      })();
    }
  }, [ state ]);

  useEffect(() => {
    if( packagesDetail && state.length > 0 ){
      setTotal( state.map( id => packagesDetail[id]?.package?.totalCost ).reduce( (accu , curr) => accu = Number(accu) + Number(curr) , 0).toFixed(2) );
      setIsNigeria(any(state.map(id => packagesDetail[id]?.shippingAddress[0].countryName==='Nigeria')))
    }
  }, [ packagesDetail , state ]);

  const splitPayment = () => {
    let minimumTotal = allSplitData[0].fromAmount;
    if( total < minimumTotal ){
      toast(`Order Total Should be Minimun ${minimumTotal}`, { type : 'info' });
    }else{
      setSplitPaymentData( allSplitData.filter( element => (parseFloat(element.toAmount) < parseFloat(total)) || (parseFloat(element.fromAmount) < parseFloat(total))));
    }
  }

  const paymentHandler = () => {
    if(selectedSplitId){
      let selectedSplitData = allSplitData.filter( element => Number(element.id) === Number(selectedSplitId))[0];
      navigate('/dashboard/payment-method' , { state: { total , selectedSplitData , state ,isNigeria}});
    }else{
      navigate('/dashboard/payment-method' , { state: { total , state, isNigeria }});
    }
  }

  return (
    <div className='review-order-container'>
      <div className='heading-details-container'>
        <div className='review-heading-details checkout-calc-heading-subheading'>
          <div className='shipping-carrier-heading'>Review your order</div>
          <div className='checkout-calc-subheading gray-text'>Dashboard → Checkout Shipping Calculator → Review Order</div>
        </div>
        <div className='review-second-heading-details second-heading-details'>
          <div className='shipping-carrier-heading'>Invoice Summary</div>
        </div>
      </div>
      <div className='review-order-details-container'>
        { Object.keys(packagesDetail).length > 0 && Object.keys(packagesDetail).map( packageKey => (
          <div className='review-order-detail-container'  key={packageKey}>
            <div className='review-heading-details order-details-container'><ReviewOrderDetails isMultiple={ state.length > 1 } id={packageKey} reviewOrderDetail={packagesDetail[packageKey]} /></div>
            <div className='review-second-heading-details order-invoice-container'>
              <ReviewOrderInvoice isMultiple={ state.length > 1 } shipmentCost={packagesDetail[packageKey].package.shipmentCost} extras={packagesDetail[packageKey].extras[packageKey]} id={packageKey}  reviewOrderDetail={packagesDetail[packageKey]} />
            </div>
          </div>
        ))}
      </div>
      <div className='review-order-details-container'>
          <div className='review-order-detail-container'>
            <div className='review-heading-details order-details-container'></div>
            <div className='review-second-heading-details'>
               <div className='order-total-amount'>Order Total: <span>${total}</span></div>
               <div className='two-buttons'>
                  { state.length === 1 && allSplitData.length > 0 && <div className='modal-form-button primary-border-button cursor' onClick={() => splitPayment()}>Split Payment</div>}
                  <div className='modal-form-button secondary-white-button cursor' onClick={() => paymentHandler()}>Payment Now</div>
               </div>
               <div className='split-payments-container'>
                {
                  state.length === 1 && splitPaymentData.length > 0 && 
                  <select className='split-select-box-container' onChange={(e) => setSelectedSplitId(e.target.value)}>
                    <option>Select Split</option>
                    {
                      splitPaymentData.map( data => <option key={data.id} value={data.id} className='split-option-box'>{data.splits} / ${data.price}</option>)
                    }
                  </select>
                }
               </div>
            </div>
          </div>
        
      </div>
    </div>
  )
}

export default ReviewOrderPage