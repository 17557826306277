import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { AddressCard, PersonalDetailsForm, toast } from '../../components';
import { Axios } from '../../hooks';
import { TopSection } from '../../layout';
import { AddAddressModal, AddPaymentMethodModal, ConfirmationModal } from '../../Modals';
import { loadStripe } from '@stripe/stripe-js';
import './profile.css';
import { Elements } from '@stripe/react-stripe-js';
import { refreshTopSectionData } from '../../store/topSectionData';
import { getImageByCardName, httpStatus } from '../../utils';
import { hotjar } from 'react-hotjar';
import { getAlignedAddressWithBrs } from '../../global';
import { setFlagNotifications } from '../../store/userDetailsSlice';

const ProfilePage = () => {
  const [ cards , setCards ] = useState([]);
  const [ address , setAddresses ] = useState([]);
  const [ showAddressModal, setShowAddressModal ] = useState(false);
  const [ showAddPaymentModal, setShowAddPaymentModal ] = useState(false);
  const [ refreshAddress , setRefreshAddress ] = useState(false);
  const [ openConfirmationModal , setOpenConfirmationModal ] = useState(false);
  const [ dataToEdit , setDataToEdit ] = useState({});
  const [ addressToDelete , setAddressToDelete ] = useState('');
  const [ isDataToEdit , setisDataToEdit ] = useState(false);
  const dispatch = useDispatch();
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_TEST}`);
  const { flagNotifications } = useSelector((state) => state.userData);

  useEffect(() => {
    hotjar.initialize(3576088, 6);
  }, []);

 const openAddressModal = () => {
    setDataToEdit({});
    setShowAddressModal(true);
    setisDataToEdit(false);
 }

  const deleteAddress = async() => {
      try{
        await Axios.delete(`/customers/address/${addressToDelete.id}`, { headers: { accessToken: localStorage.getItem('AccessToken')}} , {...addressToDelete});
        setAddressToDelete('');
        setOpenConfirmationModal(false);
        toast( 'Address deleted Successfully!' , { type : 'success' });
        setRefreshAddress(!refreshAddress);
      }catch(err){
        setAddressToDelete('');
        setOpenConfirmationModal(false);
        httpStatus(err);
      }
  }


  useEffect(() => {
    (async() => {
      try{
        const res = await Axios.get('/customers/profile' , { headers: { accessToken: localStorage.getItem('AccessToken')}});
        setAddresses(res.data.data.customerAddresses.map( element => element.id === res.data.data.customer.defaultAddress ? { ...element , selected: true } : { ...element , selected: false }));
        res.data.data?.customerCards && setCards(res.data.data.customerCards.map( data => {
          return { card:data.card , address: data.addressId , id: data.id }
        }));
        dispatch(setFlagNotifications(flagNotifications?false:true))
      }catch(err){
        httpStatus(err);
      }
    })();
  },[ refreshAddress , dispatch ]);

  const openEditAddressModal = (address) => {
    setDataToEdit(address);
    setShowAddressModal(true);
    setisDataToEdit(true)
  }

  const deleteCard = async(cardId) => {
    try{
      await Axios.delete(`/customers/profile/cards/${cardId}` , { headers: { accessToken: localStorage.getItem('AccessToken')}});
      setRefreshAddress(!refreshAddress);
      toast( 'Deleted Card Successfully!' , { type : 'success' });
    }catch(err){
      httpStatus(err);
    }
  }

  const makeDefault = async ( id ) => {
    try{
      await Axios.put(`/customers/profile/defaultaddress`, { defaultAddress: id } , { headers: { accessToken: localStorage.getItem('AccessToken')}});
      setRefreshAddress(!refreshAddress);
      dispatch(refreshTopSectionData());
    }catch(err){
      httpStatus(err);
    }
  }

  const openConfirmationModalFunc = (address) => {
    setAddressToDelete(address);
    setOpenConfirmationModal(true);
  }

  return (
   <div className='profile-page-container'>
      <TopSection/>
      <div className='address-payments-container'>
        <div className='profile-addresses-container card'>
          <div className='profile-card-heading-icon'>
            <div className='profile-heading-icon'>Address</div>
            <div className='plus-icon-img cursor' onClick={() => openAddressModal()}><img src='/images/plus-icon.svg' alt='plus-icon' /></div>
          </div>
          <div className='addresses-container'>
            {
              address.length > 0 ? address.map( address => <div key={address.id} className={address.selected ? 'selected-profile-address-container cursor' : 'profile-address-container card cursor'} ><AddressCard makeDefault={makeDefault} address={address} features={true} openEditAddressModal={openEditAddressModal} deleteAddress={openConfirmationModalFunc} defaultFeature={true}  /></div>) : 'No Addresses'
            }
          </div>
          <div className='personal-details-container'>
             <div className='profile-heading-icon'>Personal Details</div>
             <div className='personal-details-form'>
              <PersonalDetailsForm />
             </div>
          </div>
        </div>
        <div className='profile-payments-container card'>
          <div className='profile-card-heading-icon'>
            <div className='profile-heading-icon'>Payment Method</div>
            <div className='plus-icon-img cursor' onClick={() => setShowAddPaymentModal(true)}><img src='/images/plus-icon.svg' alt='plus-icon' /></div>
          </div>
          <div className='payment-details'>
            { cards.length > 0 ? cards.map( ({ card , address ,id }) => (
                <div className='payment-detail-container cursor' key={card.id}>
                  <div className='card-detail-section'>
                    <div className='card-name'>{card.name}</div>
                    <div className='card-number'>XXXX-XXXX-XXXX-{card.last4}</div>
                    <div className='card-expire-date'>Expires on: {card.exp_month}/{card.exp_year}</div>
                    <div className='card-provider-icon'><img width={50} src={getImageByCardName(card.brand)} alt='card-provider-img' /></div>
                  </div>
                  <div className='card-address-section'>
                      <div className='address-name-edit-delete-buttons'>
                        <h1 className='address-fname-lname'>{address?.firstName}{' '}{address?.lastName}</h1>
                      </div>
                      <div className='main-address'>
                        { getAlignedAddressWithBrs(address)  || " "}
                      </div>
                  </div>
                  <span className="material-symbols-outlined red-color delete-card-button" onClick={() => deleteCard(id)}>delete</span>
                </div>
            )) : 'No Card Saved'}
          </div>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <AddPaymentMethodModal show={showAddPaymentModal} setShow={setShowAddPaymentModal} address={address} refreshAddress={refreshAddress} setRefreshAddress={setRefreshAddress} />
      </Elements>
      <ConfirmationModal show={openConfirmationModal} setShow={setOpenConfirmationModal} Func={deleteAddress} title='Delete Address' longText='are you sure you wants to delete this address' />
        <AddAddressModal show={showAddressModal} setShow={setShowAddressModal} dataToEdit={isDataToEdit?dataToEdit:null} setRefreshAddress={setRefreshAddress} refreshAddress={refreshAddress} isDataToEdit={isDataToEdit} />
    </div>
  )
}

export default ProfilePage