import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useCountries, useInput } from '../../../hooks';
import StripeForm from '../StripeForm/StripeForm';
import './PaymentInfoBillingAddressForm.css';
import { useSelector } from 'react-redux';
import CyberSourcePaymentForm from '../CyberSourcePaymentForm/CyberSourcePaymentForm';

const PaymentInfoBillingAddressForm = ({ cyberSourcePaymentData, setCyberSourcePaymentData ,formData , setFormData ,CardExpiryElement,CardCvcElement,CardNumberElement , setSaveCard , saveCard}) => {
  const { inputHandler } = useInput();
  const { countries , states , cities } = useCountries( formData.countryId?formData.countryId:null , formData.stateId?formData.stateId:null );
  const { settings } = useSelector( state => state.userData );                   

  return (
    <div className='payment-info-billing-address-container'>
        <div className='two-forms'>
          {
          settings.paymentService === 'Stripe' 
          ? <StripeForm CardExpiryElement={CardExpiryElement} CardCvcElement={CardCvcElement} CardNumberElement={CardNumberElement}/>
          : <CyberSourcePaymentForm data={cyberSourcePaymentData} setData={setCyberSourcePaymentData} />
        }
          <div className='billing-info-form'>
            <div className='payment-img-text'>
              <img src='/images/2.svg' alt='1-img' />
              <div className='secondary-bold-medium-text'>Billing Info</div>
            </div>
            <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>First name<spam className='start-mark'> *</spam></Form.Label>
                        <Form.Control
                        required
                        type="text"
                        placeholder="eg: Jhon"
                        className='form-input'
                        name='firstName'
                        value={formData.firstName}
                        onChange={(e) => inputHandler(e, setFormData)}
                        />

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a valid First Name.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                    <Form.Label className='form-label'>Last name<spam className='start-mark'> *</spam></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="eg: Hanks"
                        className='form-input'
                        name='lastName'
                        value={formData.lastName}
                        onChange={(e) => inputHandler(e, setFormData)}
                    />
                    <Form.Control.Feedback type="invalid" className='feedback-text'>
                    Please provide a valid Last Name.
                    </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>Address Line1<spam className='start-mark'> *</spam></Form.Label>
                        <Form.Control
                        required
                        type="text"
                        placeholder="eg: street,PO,Box,Company,C/O"
                        className='form-input'
                        name='addressLine1'
                        value={formData.addressLine1}
                        onChange={(e) => inputHandler(e, setFormData)}
                        />

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a valid Address.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                    <Form.Label className='form-label'>Address Line2
                    {/* <spam className='start-mark'> *</spam> */}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="eg: Apartment, suite,building, etc"
                        className='form-input'
                        name='addressLine2'
                        value={formData.addressLine2}
                        onChange={(e) => inputHandler(e, setFormData)}
                    />
                    <Form.Control.Feedback type="invalid" className='feedback-text'>
                    Please provide a valid Address.
                    </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>Country<spam className='start-mark'> *</spam></Form.Label>
                        <Form.Select aria-label="Default select example" 
                        required
                        className='form-input'
                        name='countryId'
                        value={formData.countryId}
                        onChange={(e) => inputHandler(e, setFormData)}>
                            <option value={''}>Select Country</option>
                            { countries.length > 0 && countries.map( country => (<option key={country.id} value={country.id}>{country.name}</option>))}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                            Please provide a valid Country.
                        </Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                    <Form.Label className='form-label'>State{states.length > 0 ? <spam className='start-mark'> *</spam>:null}</Form.Label>
                    <Form.Select aria-label="Default select example" 
                        required={ states.length > 0 ? true : false }
                        className='form-input'
                        name='stateId'
                        value={formData.stateId}
                        onChange={(e) => inputHandler(e, setFormData)}>
                            <option value={null}>Select State</option>
                            { states.length > 0 && states.map( state => (<option key={state.id} value={state.id}>{state.name}</option>))}
                        </Form.Select>
                    <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a valid State.
                    </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>City{cities.length > 0 ? <spam className='start-mark'> *</spam>:null}</Form.Label>
                        <Form.Select aria-label="Default select example" 
                        required={ cities.length > 0 ? true : false }
                        className='form-input'
                        name='cityId'
                        value={formData.cityId}
                        onChange={(e) => inputHandler(e, setFormData)}>
                            <option value={null}>Select City</option>
                            { cities.length > 0 && cities.map( city => (<option key={city.id} value={city.id}>{city.name}</option>))}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                            Please provide a valid City.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                    <Form.Label className='form-label'>Zip Code<spam className='start-mark'> *</spam></Form.Label>
                    <Form.Control
                        required
                        type="string"
                        placeholder="eg: de4766"
                        className='form-input'
                        name='zip'
                        value={formData.zip}
                        onChange={(e) => inputHandler(e, setFormData)}
                    />
                    <Form.Control.Feedback type="invalid" className='feedback-text'>
                    Please provide a valid Zip.
                    </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>Phone<spam className='start-mark'> *</spam></Form.Label>
                        <Form.Control
                        required
                        type="number"
                        placeholder="eg: 9876543212"
                        className='form-input'
                        name='phone'
                        value={formData.phone}
                        onChange={(e) => inputHandler(e, setFormData)}
                        />

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a valid Phone Number.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className='form-label-input-container'></div>
                </div>
          </div>
        </div>
        <div className='checkbox-buttons'>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Save my payment details for future parchases" value={saveCard} onChange={() => setSaveCard(!saveCard)} />
          </Form.Group>
        </div>
    </div>
  )
}

export default PaymentInfoBillingAddressForm