import React  from 'react';
import { Modal } from 'react-bootstrap';

const ConsolidatePopup = ({ show , setShow, combinedPackage,unSelectMultiplePackages  }) => {

  const consolidateFun = async ()=>{
    setShow(false)
    combinedPackage()
  }

  return (
    <Modal size="md" aria-labelledby="contained-modal-title-vcenter" show={show} >
    <div className='modal-container consol-modal'>
      <div className='modal-heading' onClick={() => setShow(false)}>
        <img src='/images/consolidation.svg' className='img-fluid' width='100px'/>
        <div className='modal-heading-name'>Consolidation Confirmation</div>
        {/* <div className='modal-close-button' onClick={() => setShow(false)}><CloseButton /></div> */}
      </div>
      {/* <hr /> */}
      <div className='modal-package-summary'>
        <h4>Are you confirming combining the packages?</h4>
      </div>
      {/* <hr /> */}
      <div className='consol-modal-buton'>
        <div className='primary-white-button cursor' onClick={() => consolidateFun()}><span className="material-symbols-outlined">done</span></div>
        <div className='primary-white-button cursor btn-red' onClick={() => unSelectMultiplePackages()}><span className="material-symbols-outlined">close</span></div>
      </div>
    </div>
</Modal>
  )
}

export default ConsolidatePopup