import React, { useState , useEffect } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { Axios } from '../hooks';
import { toast } from '../components';
import { httpStatus } from '../utils';

const CustomizationModal = ({ unSelectPackage,show , setShow, selectedPackage, setSelectedPackage , alreadySelectedCustomization, setCustomizations, setAlreadySelectedCustomization, customizations, allPackages , setPackages }) => {
  const [ selectedCustomization , setSelectedCustomization ] = useState([]);
  const [ total , setTotal ] = useState(0);

  const closeModal = () => {
    setSelectedCustomization([]);
    setTotal(0);
    setShow(false);
  }


  useEffect(() => {
    if( show === true){
      setCustomizations(customizations.map( c => alreadySelectedCustomization.includes(c.id) ? {...c,selected: true } : {...c,selected: false } ));
    }
  }, [ show , alreadySelectedCustomization ]);

  window.addEventListener('click' , (e) => {
    if(e.target.className === 'fade modal show'){
      closeModal();
    }
   });

  const selectCustomization = (customization) => {
    if(alreadySelectedCustomization.includes(customization.id)){
      toast( "You Can't Remove This Extras! ", { type : 'info' });
    }else{
      if(selectedCustomization.filter( prevCustomization => prevCustomization === customization.id).length > 0){
        setCustomizations( prev => prev.map( prevCustomization => prevCustomization.id === customization.id ? { ...prevCustomization , selected: false } : prevCustomization ));
        setSelectedCustomization(prev => prev.filter( prevCustomization => prevCustomization !== customization.id));
      }else{
        setCustomizations( prev => prev.map( prevCustomization => prevCustomization.id === customization.id ? { ...prevCustomization , selected: true } : prevCustomization ));
        setSelectedCustomization( prev => [...prev , customization.id]);
      }
    }
  }

  const submitCustomizations = async() => {
    if(selectedCustomization.length > 0){
      try{
        await Axios.post(`/packages/extras/${selectedPackage.id}` ,{ extras: selectedCustomization, addExtras:true }, { headers : { accessToken: localStorage.getItem('AccessToken')}});
        setPackages(allPackages.map( element => ({ ...element , selected: false })))
        setSelectedCustomization([]);
        setAlreadySelectedCustomization([]);
        setCustomizations([]);
        toast( 'Customization Added Successfully!' , { type : 'success' });
        setSelectedPackage({});
        unSelectPackage();
        setShow(false);
        // document.location.reload()
      }catch(err){
        httpStatus(err);
        // setTimeout(() => {
        //   document.location.reload()
        // }, 0);
      }
    }else{
      setShow(false);
    }
  }

  useEffect(() => {
    if( selectedCustomization && customizations && alreadySelectedCustomization ){
      setTotal(alreadySelectedCustomization.concat(selectedCustomization).map( id => customizations.filter( customization => customization.id === id ).length > 0 ? customizations.filter( customization => customization.id === id )[0]?.cost : 0 ).reduce((accu , current) => accu = accu + current ,0));
    }
  },[ selectedCustomization ,customizations, alreadySelectedCustomization ]);

  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} centered>
        <div className='modal-container'>
          <div className='modal-heading'>
              <div className='modal-heading-name'>Customization</div>
              <div className='modal-close-button' onClick={() => closeModal()}><CloseButton /></div>
          </div>
          <div className='customizations-container'>
            {
              customizations.length > 0 && customizations.map( customization => 
                (
                  <div className={ customization.selected 
                  ? 'customization-container selected-customization-container cursor' : 'customization-container cursor' } onClick={() => selectCustomization(customization)} key={customization.id}>
                    <div className='customization-heading'> <span className='bold-primary-text bold-text'>${customization.cost}</span> {' '} {customization.name} </div>
                  </div>
                )
              )
            }
          </div>
          <hr />
          <div className='total-heading'>Total : ${total}</div>
          <div className='correct-submit-button cursor' onClick={() => submitCustomizations()}><span className="material-symbols-outlined color-white">done</span></div>
        </div>
    </Modal>
  )
}

export default CustomizationModal