import React, { useState } from 'react';
import './FormComponents.css';

const NormalInput = (props) => {
  const [ togglePassword , setTogglePassword ] = useState(false);
  const [ focused , setFocused ] = useState(false);
  const { label , errorMessage, type,  ...restElements } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  return (
    <div className='normal-input-container'>
        {
          type !== 'password'
          ? <label className='label'>
              { props.required 
              ? <span>{label} <span className='red-text'>*</span></span> 
              : <span>{label}</span> 
              }
              <input className='input' {...restElements} onBlur={handleFocus} focused={focused.toString()} />
              <div className='normal-input-error'>{errorMessage}</div>
            </label>
          : <label className='label'>
              { props.required 
              ? <span>{label} <span className='red-text'>*</span></span> 
              : <span>{label}</span> 
              }
              <div className='normal-input-input'>
                <input className='input normal-input-input' type={ togglePassword ? 'text' : 'password' } {...restElements} onBlur={handleFocus} focused={focused.toString()} />
                <span className="material-symbols-outlined password-eye-icon" onClick={() => setTogglePassword(!togglePassword)}>{ togglePassword ? 'visibility' : 'visibility_off'}</span>
                <div className='normal-input-error'>{errorMessage}</div>
              </div>
            </label>
        }
    </div>
  )
}

export default NormalInput