import React , { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AccordianForShippedPackage, MobileShippedPackageDetails, PackageStrip } from '../../components';
import TopSection from '../../layout/TopSection/TopSection';
import './myshipment.css';
import { Axios } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { httpStatus, statusImgById, statusNameById } from '../../utils';
import { hotjar } from 'react-hotjar';
import { getAlignedAddress } from '../../global';

const MyShipmentPage = () => {
  const { userDetails } = useSelector( state => state.userData );
  const [ selectedPackage , setSelectedPackage ] = useState({});
  const [ shippedPackages , setshippedPackages ] = useState([]);
  const [ shippedAddress , setshippedAddress ] = useState('');
  const [ contents , setContents ] = useState('');
  const [ dimension , setDimension ] = useState('');
  const [ summary, setSummary ] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    hotjar.initialize(3576088, 6);
  }, []);

  useEffect(() => {
    (async() => {
      try{
        const shippedPackagesRes = await Axios.get('/packages/shipped' , { headers : { accessToken : localStorage.getItem('AccessToken')}});
        setshippedPackages(shippedPackagesRes.data.data.packages.slice().reverse().map( p => ({...p , selected: false })))
      }catch(err){
        httpStatus(err);
      }
    })()
  },[]);

  const packageClicked = async (packageData) => {
    if(packageData){
      const summaryData = await Axios.get(`packages/summary/${packageData.id}?full=true` , { headers : { accessToken : localStorage.getItem('AccessToken')}});
      setSummary(summaryData.data.data)
      if(packageData.shippingAddress.length){
        setshippedAddress(packageData.shippingAddress[0])
      }
      if(packageData.packageContent){
        let packageContent = JSON.parse(packageData.packageContent)
        let content=[]
        for (let e of packageContent){
          content.push(e.name)
        }
        setContents(content.join(','))
      }
      if(packageData.dimensions){
        let dimension = JSON.parse(packageData.dimensions)
        dimension = `${dimension.length}*${dimension.width}*${dimension.height} in | ${packageData.weight} lb`
        setDimension(dimension)
      }
      setSelectedPackage(packageData)
      setshippedPackages( prev => prev.map( p => p.id === packageData.id ? { ...p , selected: true } : { ...p , selected: false }));
    }
  }

  const totalPackageCost=(id)=>{
    // let revertCost =  summary.revertCost && Object.keys(summary.revertCost).length > 0 ? Object.keys(summary.revertCost).map(id=>summary.revertCost[id]).reduce((accu,current)=>accu+=current,0):0 ;
    let overTimeCost=0.00;
    summary.overTimeCost &&  Object.keys(summary.overTimeCost).length > 0 && Object.keys(summary.overTimeCost).map(c=>{overTimeCost +=parseFloat(summary.overTimeCost[c])});
    overTimeCost = overTimeCost?overTimeCost:0.00
    let extrasCost = Object.keys(summary.extras).map(selectedExtra => summary.extras[selectedExtra].map(extra => extra.cost)).flat(1).reduce((accu , current) => accu += current , 0);
    let shipmentCost = summary.package.shipmentCost ? summary.package.shipmentCost :0.00;
    let splitCost =summary.splits && summary.splits[0] && summary.splits[0]?.cost > 0 ? summary.splits[0]?.cost: 0;
    //  selectedPackage.splitPaymentId  && selectedPackage.splitPaymentId.cost ? selectedPackage.splitPaymentId.cost : 0 ;
    let couponCost = summary.coupon ? summary.coupon.discountType==='%' ? (((parseFloat(shipmentCost)+parseFloat(splitCost)+parseFloat(overTimeCost)+parseFloat(extrasCost))/100)*summary.coupon.cost).toFixed(2) : summary.coupon.cost : 0.00;
    return [parseFloat(parseFloat(shipmentCost)+parseFloat(splitCost)+parseFloat(overTimeCost)+parseFloat(extrasCost)).toFixed(2),parseFloat(parseFloat(shipmentCost)+parseFloat(splitCost)+parseFloat(overTimeCost)+parseFloat(extrasCost)-parseFloat(couponCost)).toFixed(2),parseFloat(parseFloat(shipmentCost)+parseFloat(splitCost)+parseFloat(overTimeCost)+parseFloat(extrasCost)).toFixed(2)]
  }

  const getOutgoingLink = (id , serviceName) => {
    if(serviceName.includes('USPS')){
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${id}`
    }else if(serviceName.includes('FedEx')){
      return `https://fedex.com/fedextrack/no-results-found?trknbr=${id}`
    }else if(serviceName.includes('DHL')){
      return `https://www.dhl.com/in-en/home/tracking.html?tracking-id=${id}`
    }
}

  return (
    <div className='my-shipment-container'>
      <TopSection isDashboardPage={false}/>
      <div className='mobile-dashboard-container'>
              <div className='mobile-dashboard-packages-container'>
                {
                  shippedPackages.length > 0
                  ? shippedPackages.map( packages => <div key={packages.id} className='mobile-myshipment-container'><MobileShippedPackageDetails packageData={packages} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} packageClicked={packageClicked} packageContent={contents} totalPackageCost={totalPackageCost} summary={summary} shippedAddress={shippedAddress} dimension={dimension} contents={contents} getOutgoingLink={getOutgoingLink}  /></div>)
                  : <div className='no-packages-container'>
                      <img src='/images/no-package.svg' alt='no-packages-img' className="dashboard-empty-packages-img" />
                      <h2 className='big-gray-bold-text'>Welcome back, {userDetails.firstName}</h2> 
                      <div className='text-border-color'>You don't have any packages yet. <br/> To begin, start shopping.</div>                 
                    </div>
                }
              </div>
      </div>
      <div className='packages-list-and-package-details'>
          <div className='packages-table'>
            <div className='dashboard-table'>
              <div className='table-headings'>
                <div className='table-heading flex05'>Status</div>
                <div className='table-heading flex05'>Package Id</div>
                <div className='table-heading'>Incomming track ID</div>
                <div className='table-heading flex06'>Recieved on</div>
                <div className='table-heading'>Product Name</div>
              </div>
              <div className='table-content'>
                { shippedPackages.length > 0 
                ? shippedPackages.map( packages => <div key={packages.id}><PackageStrip data={packages} setSelectedPackage={setSelectedPackage} packageClicked={packageClicked} shipped={false} /></div>)
                : <div className='no-packages-container'>
                    <img src='/images/no-package.svg' alt='no-packages-img' className='dashboard-empty-packages-img' />
                    <h2 className='big-gray-bold-text'>Welcome back, {userDetails?.firstName}</h2> 
                    <div className='text-border-color'>You don't have any packages yet. <br/> To begin, start shopping.</div>                 
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='package-details'>
           { Object.keys(selectedPackage).length === 0 
           ? (<><img src='/images/noPackagesImage.svg' alt='no-packages-img' className='no-packages-img' />
           <div>No Packages Have Selected yet</div></>)
           : (<div className='shipped-package-content-container'>
                  <div className='package-name-img'>
                   <div className='package-name-number'>
                     <div className='package-name'>{selectedPackage.consolidate?"Combined Package":contents}</div>
                     <div className='gray-text'> Pack ID: {selectedPackage.id}</div>
                   </div>
                   <div className='package-img'>
                     <img width={75} src={statusImgById(selectedPackage.status)} alt='status-img' title={statusNameById(selectedPackage.status)} />
                   </div>
                  </div>
                  <div className='package-dimensions'>
                   <div className='package-content-card-heading'>Shipping To</div>
                   <div className='package-dimension'>{getAlignedAddress(shippedAddress)}</div>
                  </div>
                  <div className='package-dimensions'>
                   <div className='package-content-card-heading'>Outgoing Track ID</div>
                   {
                     selectedPackage?.outcomingTrack
                     ? 
                     <a href={getOutgoingLink(selectedPackage?.outcomingTrack , selectedPackage.serviceName)}  rel="noopener noreferrer" target="_blank">{selectedPackage?.outcomingTrack}</a>
                     : <div className='package-dimension'>{''}</div>
                   }
                  </div>
                  <div className='package-dimensions'>
                   <div className='package-content-card-heading'>Dimension</div>
                   <div className='package-dimension'> {dimension} </div>
                  </div>
                  <div className='package-dimensions'>
                   <div className='package-content-card-heading'>Shipping Method</div>
                   <div className='package-dimension'>{selectedPackage.serviceName}</div>
                  </div>
                  <div className='single-package-details'>
                      <div className='package-content-card-heading'>{ selectedPackage.consolidate === 1 ? 'Combined Package' : 'Single Package'} Content</div>
                      <div className='package-table'>
                      <div className='package-table-headings'>
                          <div className='package-table-heading'>Package ID</div>
                          <div className='package-table-heading flex04 text-center'>Name</div>
                          <div className='package-table-heading flex04 text-center'>Quantity</div>
                          <div className='package-table-heading flex04 text-center'>Unit Cost</div>
                          <div className='package-table-heading flex04 text-center'>Total</div>
                        </div>
                        {summary.packageContent && Object.keys(summary.packageContent).length>0 && 
                        Object.keys(summary.packageContent).map(id=>summary.packageContent[id].map(c=>
                          <div className='package-table-headings' key={c.id}>
                          <div className='package-table-data'>{c.packageId}</div>
                          <div className='package-table-data flex04 text-center'>{c.name}</div>
                          <div className='package-table-data flex04 text-center'>{c.quantity}</div>
                          <div className='package-table-data flex04 text-center'>{c.unitCost}</div>
                          <div className='package-table-data flex04 text-center'>{c.quantity*c.unitCost}</div>
                        </div>))}
                        <div className='package-content-card-heading summry-heading-text'>Summary</div>
                        <div className='package-table-headings'>
                          <div className='package-table-heading'>Item Description</div>
                          <div className='package-table-heading flex04 text-center'>Qty</div>
                          <div className='package-table-heading'>Price (USD)</div>
                        </div>
                        <div className='package-table-contents'>
                          {
                            Object.keys(summary).length > 0 && selectedPackage.consolidate !== 0 && selectedPackage.consolidatedPackageId.map( consolidatedPackage => <div key={consolidatedPackage.id}>
                            <AccordianForShippedPackage packageData={consolidatedPackage} showId={true} overtimeCost={summary.overTimeCost && Object.keys(summary.overTimeCost).length > 0 ? summary.overTimeCost[consolidatedPackage.id]:false} revertCost={summary.revertCost && Object.keys(summary.revertCost).length > 0 ? summary.revertCost[consolidatedPackage.id]:false} extras={summary.extras && Object.keys(summary.extras).length > 0 ? summary.extras[consolidatedPackage.id]:false} packageContent={summary.packageContent && Object.keys(summary.packageContent).length > 0 ? summary.packageContent[consolidatedPackage.id]:false}/></div>)
                          }
                          <div className='package-table-content'>
                            {/* <div className='package-table-data bold-text'>{selectedPackage.incomingTrack}</div> */}
                            <div className='package-table-data flex04 text-center'></div>
                            <div className='package-table-data'></div>
                          </div>
                          {
                            summary.extras && Object.keys(summary.extras).length > 0 && summary.extras[selectedPackage.id] && summary.extras[selectedPackage.id].map( extra => 
                              <div className='package-table-content' key={extra.id}>
                                <div className='package-table-data'>{extra.name}</div>
                                <div className='package-table-data flex04 text-center'>{extra.quantity}</div>
                                <div className='package-table-data'>${extra.cost}</div>
                              </div>
                            ) 
                          }
                          { summary?.splits?.[0]?.cost > 0 &&
                          <div className='package-table-content'>
                              <div className='package-table-data'>Split Payment Cost</div>
                              <div className='package-table-data flex04 text-center'>1</div>
                              <div className='package-table-data'>${summary.splits[0]?.cost}</div>
                            </div>
                            }
                          {
                            summary.overTimeCost && Object.keys(summary.overTimeCost).length > 0 && summary.overTimeCost[selectedPackage.id] > 0 &&
                            <div className='package-table-content'>
                              <div className='package-table-data'>Over Time Cost</div>
                              <div className='package-table-data flex04 text-center'>1</div>
                              <div className='package-table-data'>${summary.overTimeCost[selectedPackage.id]}</div>
                            </div>
                          }
                          {
                            summary.revertCost && Object.keys(summary.revertCost).length > 0 && summary.revertCost[selectedPackage.id] > 0 &&
                            <div className='package-table-content'>
                              <div className='package-table-data'>Revert Cost</div>
                              <div className='package-table-data flex04 text-center'>1</div>
                              <div className='package-table-data'>${summary.revertCost[selectedPackage.id]}</div>
                            </div>
                          }
                          {
                            summary.package.shipmentCost !== 0 &&
                            <div className='package-table-content'>
                              <div className='package-table-data'> Shipment Cost </div>
                              <div className='package-table-data flex04 text-center'>1</div>
                              <div className='package-table-data'>${summary.package.shipmentCost}</div>
                            </div>
                          }
                          
                          { summary.package.totalCost !== 0 && <div className='package-table-content'>
                            <div className='package-table-card-heading'>Order Total</div>
                            <div className='package-table-data flex04 text-center'></div>
                            <div className='package-table-card-heading'>{totalPackageCost(selectedPackage.id)[0] }</div>
                          </div>
                          }
                          {
                            summary.coupon &&
                            <div className='package-table-content'>
                              <div className='package-table-data'> Discount {summary.coupon.discountType}{summary.coupon.cost} off </div>
                              <div className='package-table-data flex04 text-center'>1</div>
                              <div className='package-table-data'>-${summary.coupon.discountType==='%' ? (((totalPackageCost(selectedPackage.id)[1])/100)*summary.coupon.cost).toFixed(2) : summary.coupon.cost }</div>
                            </div>
                          }
                          { summary.coupon && summary.package.totalCost !== 0 && <div className='package-table-content'>
                            <div className='package-table-card-heading'>Final Total</div>
                            <div className='package-table-data flex04 text-center'></div>
                            <div className='package-table-card-heading'>{totalPackageCost(selectedPackage.id)[1] <= 0 ? "0.00" : totalPackageCost(selectedPackage.id)[1] }</div>
                          </div>
                          }
                          {summary.splits && summary.splits.length > 0  && <div className='package-table-content'>
                              <div className='package-table-data'> Completed Splits</div>
                              <div className='package-table-data flex04 text-center'></div>
                              <div className='package-table-data'></div>
                            </div>}
                           {
                            summary.splits && summary.splits.length > 0 && summary.splits.map((split,index) => 
                              <div className='package-table-content' key={split.id}>
                                <div className='package-table-data'>{index+1}</div>
                                {split.discountType && split.couponCost ?<div className='package-table-data flex04 text-center'> Discount {split.discountType}{split.couponCost} off</div>:<div className='package-table-data flex04 text-center'></div>}
                                <div className='package-table-data'>{split.finalAmount}</div>
                              </div>
                            ) 
                          }
                          { summary.splits && summary.splits.length > 0 && selectedPackage.paid === 0 && <div className='split-payment-button' onClick={() => navigate('/dashboard/payment-method' , { state: { state: [selectedPackage.id], 
                          selectedSplitData: summary.splits[summary.splits.length-1] , total:(summary.splits[summary.splits.length-1].amount / summary.splits[summary.splits.length-1].totalInstallments).toFixed(2)} })}><span>Split Payment {summary.splits[summary.splits.length-1].currentInstallment} / {summary.splits[summary.splits.length-1].totalInstallments}</span><span>${(summary.splits[summary.splits.length-1].amount / summary.splits[summary.splits.length-1].totalInstallments).toFixed(2)} / {summary.splits[summary.splits.length-1].amount}</span> </div> }
                        </div>
                      </div>
                    </div>
       </div>)}
          </div>
      </div> 
    </div>
  )
}

export default MyShipmentPage