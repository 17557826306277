
export const usePayment = () => {
    function clearNumber (value = '') {
        return value.replace(/\D+/g, '')
      }
      
      function formatCreditCardNumber (value) {
        if (!value) {
          return value
        }
      
        const issuer = 'dinersclub'
        // const clearValue = clearNumber(value)
        let nextValue
      
        switch (issuer) {
          case 'amex':
            nextValue = `${value.slice(0, 4)} ${value.slice(
              4,
              10
            )} ${value.slice(10, 15)}`
            break
          case 'dinersclub':
            nextValue = `${value.slice(0, 4)} ${value.slice(
              4,
              10
            )} ${value.slice(10, 14)}`
            break
          default:
            nextValue = `${value.slice(0, 4)} ${value.slice(
              4,
              8
            )} ${value.slice(8, 12)} ${value.slice(12, 19)}`
            break
        }
      
        return nextValue.trim()
      }
      
     function formatCVC (value, prevValue, allValues = {}) {
        const clearValue = clearNumber(value)
        let maxLength = 3
    
      
        return clearValue.slice(0, maxLength)
      }
      
     function formatExpirationDate (value) {
        const clearValue = clearNumber(value)
      
        if (clearValue.length >= 3) {
          return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
        }
      
        return clearValue
      }

  return { formatCreditCardNumber, formatCVC, formatExpirationDate };
}