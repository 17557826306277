import React, { useState, useEffect } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { QuestionnaireForm, toast } from '../components';
import { Axios } from '../hooks';
import { refreshTopSectionData } from '../store/topSectionData';
import { httpStatus } from '../utils';

const QuestionnaireModal = ({ unSelectPackage, show , setShow, selectedPackage, setSelectedPackage }) => {
  const [ formDatas , setFormDatas ] = useState([]);
  const [ total , setTotal ] = useState(0);
  const [ idsToDelete , setIdsToDelete ] = useState([]);
  const dispatch = useDispatch();
  const [ validated, setValidated ] = useState(false);


 const closeModalHandler = () => {
      setShow(false);
      setIdsToDelete([]);
 }

  window.addEventListener('click' , (e) => {
    if(e.target.className === 'fade modal show'){
      closeModalHandler();
    }
 });

 useEffect(() => {
  if(selectedPackage.packageContent && show === true){
    const packageContent = selectedPackage.packageContent;
    if(packageContent.length===0){
      setFormDatas([{ name: '', quantity: '', unitCost: '', totalPrice: '',  id: 'dummyID'}]);
    }else{
      setFormDatas(packageContent?.map( packageDetail => ({...packageDetail, totalPrice: packageDetail.unitCost * packageDetail.quantity})));
    }
  }
},[selectedPackage , show]);
 
  const addForm = () =>  {
    setFormDatas([...formDatas , { id: 'dummyID', name: '', quantity: '', unitCost: '', totalPrice: ''}]);
  }


  const reduceForm = ( index, currentForm ) => {
    setIdsToDelete(prev => [...prev , currentForm.id]);
    if(formDatas[index].id === 'dummyID'){
      let data = [...formDatas];
      data.splice(index,1);
      setFormDatas(data);
      setIdsToDelete( prev => prev.filter( prevForm => prevForm.id !== 'dummyID'))
    }else{
      let data = [...formDatas];
      data.splice(index,1);
      setFormDatas(data);
    }
  }



  useEffect(() => {
    const total = formDatas.reduce((accu , current) => accu += current.totalPrice , 0);
    setTotal(total);

    return () => {
      setTotal(0);
    }
  },[ formDatas ]);

  const checkDataIsEditedOrNot = (oldArr, newArr) => {
      let editedIds = [];
      let oldArrIds = oldArr.map( element => element.id );
      let editArray = newArr.filter( element => oldArrIds.includes(element.id));
      
      for( let i = 0; i < editArray.length ; i++){
        let oldData = oldArr.filter( el => el.id === editArray[i].id )[0];
        let newData = editArray.filter( el => el.id === editArray[i].id )[0];

        if( oldData['name'] !== newData['name']){
          editedIds.push(newData.id);
          continue;
        }
        if( oldData['quantity'] !== newData['quantity']){
          editedIds.push(newData.id);
          continue;
        }
        if( oldData['unitCost'] !== newData['unitCost']){
          editedIds.push(newData.id);
          continue;
        }
      }

      return editedIds;
  }
  
  const submitQuestionnaire = async() => {
    if( formDatas.filter( element => element['name'] === '' || element['quantity'] === '' || element['unitCost'] === '' ).length === 0 ){
      setValidated(false);
      try{
        if(formDatas.filter(e=>parseInt(e.quantity)<=0 || parseInt(e.unitCost)<=0).length){
          toast( 'Quantity and Unitcost should be more then Zero' , { type : 'info' });
          return 
        }
        if(formDatas.filter(e=> e.quantity.toString().includes('.') ).length > 0 ){
          toast( 'Quantity Should not be in decimal' , { type : 'info' });
          return 
        }
        if(formDatas.filter(e=>e.quantity.toString().includes('e') || e.unitCost.toString().includes('e')).length > 0){
          toast("Quantity and Unitcost should not allow 'e'" , { type : 'info' });
          return 
        }
        const packageContent = selectedPackage.packageContent;
        const editedIds = checkDataIsEditedOrNot(packageContent,formDatas);
  
        if( formDatas.filter( element => element.id === 'dummyID' ).length > 0) 
        await Axios.post( `/packages/content/${selectedPackage.id}`, { contents: formDatas.filter( element => element.id === 'dummyID' ) } , { headers: { accessToken: localStorage.getItem('AccessToken')}});
  
        if( editedIds.length > 0 ) 
        await Axios.put( `/packages/content/${selectedPackage.id}`, { contents: formDatas.filter( element => editedIds.includes(element.id)) } , { headers: { accessToken: localStorage.getItem('AccessToken')}});
        
        if( idsToDelete.filter( id => id !== 'dummyID').length > 0 ) 
        await Axios.delete( `/packages/content/${selectedPackage.id}?ids=${idsToDelete.filter( id => id !== 'dummyID')}`, { headers: { accessToken: localStorage.getItem('AccessToken')}});
        unSelectPackage();
        setSelectedPackage({});
        dispatch(refreshTopSectionData());
        setIdsToDelete([]);
        setShow(false);
        // document.location.reload();
      }catch(err){
        console.log(err)
        httpStatus(err);
      }
    }else{
      setValidated(true);
    }
  }

  useEffect(() => {
    if(selectedPackage && show === true ){
      const packageContent = selectedPackage.packageContent;
      if(packageContent.length === 0 ){
        setFormDatas([{ name: '', quantity: '', unitCost: '', totalPrice: '',  id: 'dummyID'}]);
      }else if(packageContent.length !==0 ){
        setFormDatas(packageContent.map( packageDetail => ({...packageDetail, totalPrice: packageDetail.unitCost * packageDetail.quantity})));
      }
    }
  
  },[selectedPackage , show]);
    
  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} centered>
        <div className='modal-container'>
          <div className='modal-heading'>
              <div className='modal-heading-name'>Incomming tracking ID: {selectedPackage.incomingTrack}</div>
              <div className='modal-close-button' onClick={() => closeModalHandler()}><CloseButton /></div>
          </div>
          <div className='forms'>
            {
                formDatas.map( (form, index) => <div key={index}><QuestionnaireForm currentForm={form} index={index} formDatas={formDatas} setFormDatas={setFormDatas} addForm={addForm} reduceForm={reduceForm} validated={validated} setValidated={setValidated} /></div>)
            }
          </div>
          <hr />
          <div className='package-total-price'>Package Total Price {total}</div>
          <div className='questionnaire-terms-and-conditions'>I accept the terms and conditions. I declare that I am not shipping items that fall under the ‘Do Not Ship’ category</div>
          <div className='questionnaire-note'>Please note: Incorrect information may cause delay in delivering packages. Please ensure all fields are correct. </div>
          <div className='correct-submit-button cursor' onClick={() => submitQuestionnaire()}>
            <span className="material-symbols-outlined color-white">done</span>
          </div>
        </div>
    </Modal>
  )
}

export default QuestionnaireModal