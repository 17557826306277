import React , { useState, useEffect } from 'react';
import './authentication.css';
import { Link, useNavigate } from 'react-router-dom';
import { Axios } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthentication } from '../../store/authenticationSlice';
import { toast } from '../../components/index';
import { setUserDetails } from '../../store/userDetailsSlice';
import { SocialAuth } from '../../components/SocialAuth';
import { deviceDetect } from 'react-device-detect';
import { addMarkeingData, showPopupFunc } from '../../store/warehouseSlice';
import { InputChange, LoginInputs } from './loginRegisterInputs';
import NormalInput from '../../components/FormComponents/NormalInput';
import { Spinner } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { httpStatus } from '../../utils';
import { updateToggleSidebar } from '../../store/toggleSidebarSlice';

const Login = () => {
  const { browserName,browserFullVersion,engineName,engineVersion,osName,osVersion } = deviceDetect()
  const { settings } = useSelector( state => state.userData );
  const [ loginDetails , setLoginDetails] = useState({ email: '' , password: '', clientId: settings?.clientId });
  const dispatch = useDispatch();
  const [ loading , setLoading ] = useState(false);
  const [ isForgetPassword , setIsForgetPassword ] = useState(false);
  const [ emailToSendPassword , setEmailTosendPassword ] = useState('');
  const [ registerDisable , setRegisterDisabled ] = useState(false);
  const navigate = useNavigate();

  const submitHandler = async(event) => {
    event.preventDefault();
      setLoading(true);
      try{
        const res = await Axios.post('/customers/login', { ...loginDetails, email: loginDetails.email.trim().toLocaleLowerCase() ,browserName,browserFullVersion,engineName,engineVersion,osName,osVersion });
        localStorage.setItem('AccessToken' , res.data.data.accessToken );
        const marketingRes = await Axios.get('/customers/marketing' , { headers : { accessToken : localStorage.getItem('AccessToken')}});
        dispatch(addMarkeingData(marketingRes.data.data));
        dispatch(setUserDetails(res.data.data.user));
        setLoginDetails({email: '' , password: '', clientId: settings?.clientId , checkbox: false});
        setLoading(false);
        toast('Login Success', { type : 'success' });
        dispatch(setAuthentication(true));
        dispatch(updateToggleSidebar(false));
        navigate('/dashboard');
        marketingRes?.data?.data?.popup?.length && setTimeout(() => dispatch(showPopupFunc(true)), 1000);
      }catch(err){
        setLoading(false);
        httpStatus(err);
      }
  }

  const forgetPasswordHandler = async(e) => {
    e.preventDefault();
    if(emailToSendPassword){
      try{
        const res = await Axios.post('/customers/forgotPassword/sendLink', { email: emailToSendPassword , url: window.location.origin , clientId: settings?.clientId  });
        if(res.data.code === 200){
          toast( 'Email has sent! ' , { type : 'success' });
        }
      }catch(err){
        httpStatus(err);
      }
    }
  }

  // uncomment things when pushing code
  useEffect(() => {
    if(settings.googleRecaptureKey){
      setRegisterDisabled(true);
    }else{
      setRegisterDisabled(false);
    }
  }, [ settings.googleRecaptureKey ]);

  const handleCaptchaChange = (value) => {
    if (value) {
      setRegisterDisabled(false);
    }
  }


  return (
    <div className='login-container'>
      <div className='login-card'>
        {
          isForgetPassword
          ? <div className='login-content forget-password-content'>
              <h1 className='login-heading'>Forgot Password</h1>
              <form className='form' onSubmit={forgetPasswordHandler}>
                <label className='label'>
                  Email*
                  <input className='input' type='email' value={emailToSendPassword.toLowerCase()} name='email' onChange={(event) => setEmailTosendPassword(event.target.value)} placeholder='mail@website.com' required />
                </label>
                <button className={`button form-button ${ loading && 'disabled' }`} type='submit'>Send { loading && <Spinner size='sm' /> }</button>
              </form>
              
              <div className='create-account-link'>
                If you remember password? <span onClick={() => setIsForgetPassword(false)} className='link cursor'>Login</span>
              </div>
            </div>
          : <div className='login-content'>
              <h1 className='login-heading'>Login</h1>
              <SocialAuth page='login' />
              <div className='cross-line'>Sign in with Email</div>
              <form onSubmit={submitHandler} className='personal-details-form'>
                {
                  LoginInputs.map( input => <div key={input.id}><NormalInput {...input} onChange={(e) => InputChange(e, setLoginDetails)} value={loginDetails[input.name]} /></div>)
                }
                <div className='create-account-link login-forget-password-link'>
                  <Link  onClick={() => setIsForgetPassword(true)} className='link'>Forgot Password?</Link>
                </div>
                {
                  settings.googleRecaptureKey
                    ? <label>
                        <ReCAPTCHA sitekey={settings.googleRecaptureKey} onChange={handleCaptchaChange} />
                      </label>
                    : null
                }
                <input className={`button form-button ${ registerDisable && 'disabled' }`} type='submit' value='LOGIN' />
              </form>
              
              <div className='create-account-link'>
                Not registerd yet? <Link  to={'/register'} className='link'>Create an Account</Link>
              </div>
            </div>
        }
        <div className='login-text-container'>
        <div className='common-text'>
         You are one step away to ship with <strong> most affordable, fastest and most reliable</strong> Shipping company in USA!!
        </div>
        <div className='colored-text'>
        <strong>login now and start shipping</strong>
        </div>
        </div>
        <img className='login-img' src='/images/loginImg.svg' alt='login-img'/>
      </div>
    </div>
  )
}

export { Login }