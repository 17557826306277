import React, { useState } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';


const CouponModal = ({ show , setShow, couponData }) => {

  window.addEventListener('click' , (e) => {
    if(e.target.className === 'fade modal show'){
      setShow(false);
    }
 });

 const CouponInfo = ({ coupon }) => {
  const [ showText , setShowText ] = useState(false);

  const copyText = () => {
    window.navigator.clipboard.writeText(coupon.code);
    setShowText(true);
    setTimeout(() => {
      setShowText(false);
    },1000);
  }

  return (
    <div className='refer-code-copy-container' key={coupon.id}>
        <div className='bold-text'>Name: {coupon.name}</div>
        <div className='bold-text'>Coupon Code</div>
        <div className='referral-input-button'>
            <input type='text' value={coupon.code} className='referral-input' />
            <div className='copy-icon-div'>
            <span className="material-symbols-outlined copy-icon" onClick={() => copyText()}>content_copy</span>
            { showText && 
            (
                <>
                <div className='copied-text'>Copied!</div>
                <div className='point'></div>
                </>
            )}
            </div>
        </div>
        <div>Coupon Value: {coupon.discountType === '$' && coupon.discountType}{coupon.cost}{coupon.discountType === '%' && coupon.discountType}</div>
        <div>Coupon Expires On: {coupon.expiryTime.slice(0,10)}</div>
    </div>
  )
 }

  return (
    <Modal size='md' aria-labelledby="contained-modal-title-vcenter" show={show} centered>  
         <div className='modal-container'>
            <div className='modal-heading' onClick={() => setShow(false)}>
              <div>
                <div className='modal-heading-name'>Coupons</div>
              </div>
              <div className='modal-close-button' onClick={() => setShow(false)}><CloseButton /></div>
            </div>
            { couponData.length > 0
              ? <div className='refer-and-earn-container'>
                  {
                      couponData.map( coupon => <CouponInfo coupon={coupon} />)
                  }
                </div>
              : <h4 style={{ marginTop: '1rem'}}>You don't have coupons</h4>
          }
           </div>
    </Modal>
  )
}

export default CouponModal