import React , { useState, useEffect } from 'react';
import './authentication.css';
import { Link, useNavigate } from 'react-router-dom';
import { Axios, useCountries } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthentication } from '../../store/authenticationSlice';
import { toast } from '../../components/index';
import { setUserDetails } from '../../store/userDetailsSlice';
import ReCAPTCHA from "react-google-recaptcha";
import { SocialAuth } from '../../components/SocialAuth';
import { deviceDetect } from 'react-device-detect';
import NormalInput from '../../components/FormComponents/NormalInput';
import { InputChange, RegisterInputs } from './loginRegisterInputs';
import SelectInput from '../../components/FormComponents/SelectInput';
import { Spinner } from 'react-bootstrap';
import { httpStatus } from '../../utils';
import { updateToggleSidebar } from '../../store/toggleSidebarSlice';

const Register = () => {
  const {browserName,browserFullVersion,engineName,engineVersion,osName,osVersion} = deviceDetect()
  const { settings } = useSelector( state => state.userData );
  const [registerDetails , setRegisterDetails] = useState({ firstName:'', lastName:'', email: '' , password: '', confirmPassword:'', countryId: '', clientId: settings?.clientId , referralCode: ''});
  const { countries } = useCountries();
  const [ loading , setLoading ] = useState(false);
  const [ registerDisable , setRegisterDisabled ] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  // uncomment things when pushing code
  useEffect(() => {
    if(settings.googleRecaptureKey){
      setRegisterDisabled(true);
    }else{
      setRegisterDisabled(false);
    }
  }, [ settings.googleRecaptureKey ]);

  const handleCaptchaChange = (value) => {
    if (value) {
      setRegisterDisabled(false);
    }
  }

  
  const submitHandler = async(event) => {
    event.preventDefault();
    setLoading(true);
    try{
      const res = await Axios.post('/customers/signup', { ...registerDetails , email: registerDetails.email.trim().toLocaleLowerCase() , referralCode: registerDetails.referralCode.toUpperCase(),
      browserName,browserFullVersion,engineName,engineVersion,osName,osVersion });
      localStorage.setItem('AccessToken' , res.data.data.accessToken );
      dispatch(setUserDetails(res.data.data.user));
      setRegisterDetails({firstName:'', lastName:'', email: '' , password: '', confirmPassword:'', countryId: '', clientId: settings.clientId , });
      dispatch(setAuthentication(true));
      setLoading(false);
      dispatch(updateToggleSidebar(false));
      navigate('/dashboard');
      toast('Signup Successfully!', { type : 'success' });
    }catch(err){
      setLoading(false);
      httpStatus(err);
    }
  }

  
  return (
    <div className='register-container'>
      <div className='login-card'>
        <div className='login-content register-content'>
          <h1 className='login-heading'>Register</h1>
          <form onSubmit={submitHandler}>
            <div className='two-inputs'>
              <NormalInput { ...RegisterInputs[0] } onChange={(e) => InputChange(e, setRegisterDetails)} value={registerDetails.firstName}  />
              <NormalInput { ...RegisterInputs[1] } onChange={(e) => InputChange(e, setRegisterDetails)} value={registerDetails.lastName}  />
            </div>
            <NormalInput { ...RegisterInputs[2] } onChange={(e) => InputChange(e, setRegisterDetails)} value={registerDetails.email} />
            <NormalInput { ...RegisterInputs[3] } onChange={(e) => InputChange(e, setRegisterDetails)} value={registerDetails.password} />
            <NormalInput { ...RegisterInputs[4] } onChange={(e) => InputChange(e, setRegisterDetails)} value={registerDetails.confirmPassword} pattern={`${registerDetails.password}`} />
            <SelectInput { ...RegisterInputs[5] } onChange={(e) => InputChange(e, setRegisterDetails)} value={registerDetails.countryId} options={countries} />
            <NormalInput { ...RegisterInputs[6] } onChange={(e) => InputChange(e, setRegisterDetails)} value={registerDetails.referralCode.toUpperCase()} />
            {
              settings.googleRecaptureKey
                ? <label>
                    <ReCAPTCHA sitekey={settings.googleRecaptureKey} onChange={handleCaptchaChange} />
                  </label>
                : null
            }
            <button className={`button form-button ${ loading && 'disabled' } ${ registerDisable && 'disabled' }`} id="registerBtn" type='submit'>CREATE ACCOUNT { loading && <Spinner size='sm' /> }</button>
          </form>
          <div className='signup-with-google-facebook'>
            {(settings.googleKeyForLogin || settings.facebookKeyForLogin ) && <div>SignUp With</div>}
            <SocialAuth page='register'/>
          </div>
          <div className='create-account-link'>
            Already have an account? <Link  to={'/'} className='link'>Log in</Link>
          </div>
        </div>
        <div className='signup-text-container'>
        <div className='common-text'>
         <strong className='colored-text'> Signup now and ship </strong> what you love from the <strong> USA to Globally </strong>
        </div>
        </div>
        <img className='login-img register-img' src='/images/registerImg.svg' alt='login-img'/>
      </div>
    </div>
  )
}

export { Register }