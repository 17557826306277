import React , { useState  } from 'react';
import { Spinner } from 'react-bootstrap';
import './Card.css'
import '../../pages/AddressAndPaymentPage/AddressAndPaymentPage.css';

const WalletMoney = ({ proceedForPayment, setFinalTotal, balance ,finalTotal, isLoading}) => {
  const preAmount = {twenty:20,fifty:50,hundared:100,twoHundred:200,fiveHundred:500}
  const amountselect = (value)=> setFinalTotal(value>0?value:0)
  return (
    <div className='order-summary-container summary-container card'>
        <div className='summary-total'>Wallet Balance <span>${balance?balance:"0.00"}</span></div>
        <input value= {finalTotal} type='number' placeholder='Enter Amount ($)' min={10} className='wallet-amount-input' onChange={(e)=>amountselect(e.target.value)}></input>
        <p className='wallet-amount-message'>Minimum amount should be $10 atleast</p>
        <h4 className='select-amo-heading'>Select an amount</h4>
        <ul className='wallet-amo-ul'>
          <li value={preAmount.twenty} onClick={(e)=>amountselect(e.target.value)}>${preAmount.twenty}</li>
          <li value={preAmount.fifty} onClick={(e)=>amountselect(e.target.value)}>${preAmount.fifty}</li>
          <li value={preAmount.hundared} onClick={(e)=>amountselect(e.target.value)}>${preAmount.hundared}</li>
          <li value={preAmount.twoHundred} onClick={(e)=>amountselect(e.target.value)}>${preAmount.twoHundred}</li>
          <li value={preAmount.fiveHundred} onClick={(e)=>amountselect(e.target.value)}>${preAmount.fiveHundred}</li>
        </ul>
       <div className={ isLoading ? 'secondary-white-button summary-payment-button cursor disabled' : 'secondary-white-button summary-payment-button cursor'} onClick={proceedForPayment}>{ 'Confirm your Payment' } {' '} {' '} { isLoading && <Spinner size='sm' />}</div>
    </div>
  )
}

export default WalletMoney