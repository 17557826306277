import React , { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { QuestionnaireModal } from '../../Modals';
import { dateTimeFormat, statusImgById, statusNameById } from '../../utils';
import './PackageStrip.css';

const PackageStrip = ({ data, packageClicked , shipped, setSelectedPackage , unSelectPackage, mulitiplePackages}) => {
  const [ selected , setSelected ] = useState(false);
  const [ packageContent , setPackageContent ] = useState([]);
  const [ openQuestionnaireModal , setOpenQuestionnaireModal ] = useState(false);
  const { settings } = useSelector( state=> state.userData );

  const setselectedPackage = (selectedPackage) => {
    setSelected(!selected);
    packageClicked(selectedPackage);
  }

  const disposedDays = (arrivedDate,revert) => {
    const dateArrivedDays = (parseInt((new Date() - new Date(arrivedDate)) / (1000 * 3600 * 24)))
    if(revert)  return (dateArrivedDays + settings.revertDays) - (parseInt((new Date() - new Date(arrivedDate)) / (1000 * 3600 * 24)));
    let rem = 60 - dateArrivedDays;
    if(rem>30){
      return `Free Storage for ${rem-30} days` 
    }else{
      return `Dispose in ${30-rem} days`
    }
  }

  useEffect(() => {
    if(data.packageContent){
      shipped ? setPackageContent(data.packageContent) : setPackageContent(JSON.parse(data.packageContent))
    }
  },[ data ]);

  return (
    <>
    <div key={data.id} className={ data.selected ? 'selected-accordian-strip cursor' : 'accordian-strip cursor'}>
        <div className='table-content flex05 strip-table-content-img' onClick={() => setselectedPackage(data)}>
          <img className='table-package-img' src={statusImgById(data.status)} alt='status-img' title={statusNameById(data.status)} width={55}/>
          { data.splitPaymentId !== 0 && data.paid === 0 && <img width={30} src='/images/doller-img.svg' alt='split-img' className='doller-img' />}
        </div>
        <div className='table-content flex05' onClick={() => setselectedPackage(data)}>{data.id}</div>
        <div className='table-content' onClick={() => setselectedPackage(data)}>{data.incomingTrack}</div>
        <div className='table-content flex06' onClick={() => setselectedPackage(data)}>{dateTimeFormat(data.dateArrived)}</div>
        <div className='table-content' onClick={() => setselectedPackage(data)}>{ data.consolidate? "Combined Package" : packageContent?.[0]?.name ? packageContent?.[0]?.name : ''}</div>
        { shipped ? data.disposed === 0
            ?  <div className='table-content flex08'></div>
            :  <>
                <div className='red-button disposed-days-button'>{disposedDays(data.dateArrived, data.revert )}</div>
                {
                  data.consolidate 
                  ? <div className='table-content flex08 edit-pen-icon'></div>
                  : <div className='table-content flex08 edit-pen-icon'>
                      <div className='pen-edit-icon' onClick={() => setOpenQuestionnaireModal(true)}>
                        <span className="material-symbols-outlined edit-pen">edit</span>
                      </div>
                    </div>
                }
              </>
          : null 
        }
    <QuestionnaireModal show={openQuestionnaireModal} setShow={setOpenQuestionnaireModal} selectedPackage={data} setSelectedPackage={setSelectedPackage} unSelectPackage={unSelectPackage} />
    </div>
     {mulitiplePackages && mulitiplePackages.length > 0 && data.status<=2 && <p className='question-error'>Please fill out Questionnaire!</p>}
     </>
  )
}

export default PackageStrip