import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { CardCvcElement,useElements } from "@stripe/react-stripe-js";
import { getImageByCardName } from '../../../utils';
import { usePayment } from '../../../hooks';
import { getAlignedAddressWithBrs } from '../../../global';

const AddressPaymentCard = ({ element, setCvc,cvc,setCardAndAddresses, selectAddressAndCardFunc, isStripe }) => {
  const { card , addressId } = element;
  const elements = useElements();
  const { formatCVC } = usePayment();
  
  const getCvc = () => {
    setTimeout(()=>{
      setCvc(elements.getElement(CardCvcElement))
    },100);
  }
  return (
    <div className={ element.selected ? 'selected-payment-detail-container cursor' : 'payment-detail-container cursor'} key={card.id}>
        <div className='card-detail-section'>
        <div className='card-name' onClick={() => selectAddressAndCardFunc(element)}>{card.name}</div>
        <div className='card-number' onClick={() => selectAddressAndCardFunc(element)}>XXXX-XXXX-XXXX-{card.last4}</div>
        <div className='card-expire-date' onClick={() => selectAddressAndCardFunc(element)}>Expires on: {card.exp_month}/{card.exp_year}</div>
        { isStripe 
        ? 
        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>First name</Form.Label>
            <Form.Control
              required
              type="number"
              maxlength="3"
              placeholder="CVV"
              className='form-input'
              name='cvv'
              value={element.cvv}
              width={5}
              style={{ width: '6rem' }}
              onChange={ (e) => setCardAndAddresses( prev => prev.map( ele => ele.id === element.id ? { ...ele , CVV: formatCVC(e.target.value) } : ele ))}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>

        : <Row>
            <Col>
              <div className={'cardNumStyle'}>
                <CardCvcElement onChange={() => getCvc()}
                />
              </div>
            </Col>
          </Row>
        }
        <div className='card-provider-icon' onClick={() => selectAddressAndCardFunc(element)}><img width={50} src={getImageByCardName(card.brand)} alt='card-provider-img' /></div>
        </div>
        <div className='card-address-section' onClick={() => selectAddressAndCardFunc(element)}>
            <div className='address-name-edit-delete-buttons'>
            <h1 className='address-fname-lname'>{addressId?.firstName}{' '}{addressId?.lastName}</h1>
            </div>
            <div className='main-address'>
            { getAlignedAddressWithBrs(addressId)}
            </div>
        </div>
    </div>
  )
}

export default AddressPaymentCard