import React ,{ useState , useEffect } from 'react';
import './Sidebar.css';
import { NavLink , useLocation } from 'react-router-dom';
import { activeDashboardIcon, activeProfile, activeShippingCalc, activeShippment, DashboardIcon, InstagramIcon, LeftArrow, LinkedInIcon, ProfileIcon, QuickLink, ShipCalcIcon, ShipmentIcon, SidebarFacebookIcon, TwitterIcon,Support,SupportIcon } from '../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateToggleSidebar } from '../../store/toggleSidebarSlice';
import { Axios } from '../../hooks';
import { httpStatus } from '../../utils';

const Sidebar = () => {
  const { pathname } = useLocation();
  const { toggleSidebar } = useSelector((state) => state.toggleSidebar);
  const dispatch = useDispatch();
  const [ links , setLinks ] = useState({policyLinks: [], quicklinks: [] , socialLinks:[]});
  const { settings } = useSelector( state => state.userData );
  const [ responsiveToggle , setResponsiveToggle ] = useState(false);

  useEffect(() => {
    (async() => {
      try{
        const res = await Axios.get(`/customers/quicklinks` , { headers : { accessToken : localStorage.getItem('AccessToken')}});
        setLinks({...res.data.data});
      }catch(err){
        httpStatus(err)
      }
    })()
  } , []);

  const getSocialLinkImgByName = (linkName) => {
    switch(linkName){
      case 'Face Book': return SidebarFacebookIcon
      case 'Twitter': return TwitterIcon
      case 'YouTube': return '/images/youtube-icon.png'
      case 'LinkedIn': return LinkedInIcon
      case 'Instagram': return InstagramIcon
      default : return InstagramIcon
    }
  }

  useEffect(() => {
    if( window.innerWidth <= 992 ){
      dispatch(updateToggleSidebar(false));
    }
  }, []);

  const toggleResponsiveFunc = () => {
    setResponsiveToggle(!responsiveToggle);
    dispatch(updateToggleSidebar( toggleSidebar === true ? false : true ));
   }

  return (
    <>
      { window.innerWidth <= 992 && 
      <div className={ responsiveToggle ? 'sidebar-main-container' : 'display-none'}>
        <div className={ responsiveToggle ? 'sidebar-container mobile-sidebar-container' : 'display-none'}>
      <div className={'sidebar-upper-content'}>
        {
          <>        
          {settings.companyLogo && <div className='logo'>
              <img src={`data:image/jpeg;base64,${settings.companyLogo}`} alt='company-logo' className='my-package-forwarder-logo' />
              <img src={LeftArrow} alt='left-arrow' className='left-arrow-icon cursor' onClick={() => toggleResponsiveFunc()} />
            </div>}
          </>
        }
        <div className={'nav-links'}>
          <NavLink onClick={() => toggleResponsiveFunc()} className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/dashboard'><img className='navlink-img' src={ pathname === '/dashboard' ? activeDashboardIcon : DashboardIcon} alt='dashboard-icon' /><div className={'link-text'}>Dashboard</div></NavLink>
          <NavLink onClick={() => toggleResponsiveFunc()} className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/shipcalc' ><img className='navlink-img' src={ pathname === '/shipcalc' ? activeShippingCalc : ShipCalcIcon} alt='shipping-icon' /><div     className={'link-text'}>Shipping calc</div></NavLink>
          <NavLink onClick={() => toggleResponsiveFunc()} className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/myshipment'><img className='navlink-img'src={ pathname === '/myshipment' ? activeShippment : ShipmentIcon} alt='shipment-icon' /><div      className={'link-text'}>My Shipment </div></NavLink>
          <NavLink onClick={() => toggleResponsiveFunc()} className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/profile'><img className='navlink-img'   src={ pathname === '/profile' ? activeProfile  : ProfileIcon} alt='profile-icon' /><div className={'link-text'}>Profile </div></NavLink>
          <NavLink onClick={() => toggleResponsiveFunc()} className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/shopping'><img className='navlink-img'   src={ pathname === '/shopping' ? '/images/activeShopping.svg'  : '/images/shoppingIcon.svg'} alt='profile-icon' /><div className={'link-text'}>Shopping</div></NavLink>
          <NavLink onClick={() => toggleResponsiveFunc()} className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/support'><img className='navlink-img'   src={ pathname === '/support' ? Support : SupportIcon } alt='profile-icon' /><div className={'link-text'}>Support</div></NavLink>
        </div>
        <hr/>
        {
          links.quicklinks.length > 0 &&
          <div className='quick-link-container'>
                <div className='link-heading'>
                  <img src={QuickLink} alt='quick-link-icon' className='quick-link-img cursor' /> 
                  <div className='quick-link-text'>Quick Links</div> 
                </div>
                <div className='external-links'>
                  {
                    links.quicklinks.length > 0 && links.quicklinks.map( quicklink => <a rel="noreferrer" href={quicklink.link} target='_blank' key={quicklink.id}>{quicklink.name}</a>)
                  }
                </div>
          </div>
        }

        {/* {
          responsiveToggle && links.socialLinks.length > 0 &&
          <img src={QuickLink} alt='quick-link-icon' className='quick-link-img cursor' /> 
        } */}
        </div>

        <div className={'sidebar-lower-content'}>
          { 
          links.socialLinks.length > 0 &&
            <div className='social-media-links-container'>
              <div className='social-media-links-text'>Get Social</div>
              <div className={'social-media-links'}>
                {
                  links.socialLinks.length > 0 && links.socialLinks.map( socialLink => <a href={socialLink.link} rel="noreferrer" target='_blank' className='social-media-link' key={socialLink.id}><img className='social-media-link-img' title={socialLink.name} src={getSocialLinkImgByName(socialLink.name)} alt='instagram-icon' /></a>)
                }
              </div>
            </div>
          }

          {
            !responsiveToggle &&
            (
              <div className='text-links'>
                {
                  links.policyLinks.length > 0 && links.policyLinks.map( policyLink => <a rel="noreferrer" href={policyLink.link} target='_blank' className='text-link' key={policyLink.id}>{policyLink.name}</a>)
                }
              </div>
            )
          }
        </div>
        </div>
        <div className='sidebar-empty-space' onClick={() => toggleResponsiveFunc()}></div>
      </div>
      }
      { window.innerWidth > 992 && <div className={ toggleSidebar ? 'small-sidebar-container' : 'sidebar-container'}>
      <div className={ toggleSidebar ? 'center-sidebar-upper-content' : 'sidebar-upper-content' }>
        {
          toggleSidebar
          ? settings.companyLogo && <div className='verticle-logo'>
              <img src='/images/mpf-short-logo.svg' alt='company-logo' className='cursor mpf-small-logo' onClick={() => dispatch(updateToggleSidebar(false))} />
              <img src='/images/right-arrow.svg' alt='left-arrow' className='left-arrow-icon cursor' onClick={() => dispatch(updateToggleSidebar(false))} />
            </div>            
          : settings.companyLogo && <div className='logo'>
              <img src={`data:image/jpeg;base64,${settings.companyLogo}`} alt='company-logo' className='my-package-forwarder-logo' />
              <img src={LeftArrow} alt='left-arrow' className='left-arrow-icon cursor' onClick={() => dispatch(updateToggleSidebar(true))} />
            </div>
          
        }
        <div className={ toggleSidebar ? 'center-nav-links' : 'nav-links'}>
        <NavLink className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/dashboard'><img className='navlink-img' src={ pathname === '/dashboard' ? activeDashboardIcon : DashboardIcon} alt='dashboard-icon' /><div className={toggleSidebar ? 'display-none' : 'link-text'}>Dashboard</div></NavLink>
            <NavLink className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/shipcalc' ><img className='navlink-img' src={ pathname === '/shipcalc' ? activeShippingCalc : ShipCalcIcon} alt='shipping-icon' /><div className={toggleSidebar ? 'display-none' : 'link-text'}>Shipping calc</div></NavLink>
            <NavLink className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/myshipment'><img className='navlink-img'src={ pathname === '/myshipment' ? activeShippment : ShipmentIcon} alt='shipment-icon' /><div  className={toggleSidebar ? 'display-none' : 'link-text'}>My Shipment </div></NavLink>
            <NavLink className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/profile'><img className='navlink-img'   src={ pathname === '/profile' ? activeProfile  : ProfileIcon} alt='profile-icon' /><div className={toggleSidebar ? 'display-none' : 'link-text'}>Profile </div></NavLink>
            <NavLink className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/shopping'><img className='navlink-img'   src={ pathname === '/shopping' ? '/images/activeShopping.svg'  : '/images/shoppingIcon.svg'} alt='profile-icon' /><div className={toggleSidebar ? 'display-none' : 'link-text'}>Shopping</div></NavLink>
            <NavLink className={({isActive}) => isActive ? 'active-link' : 'navlink'} to='/support'><img className='navlink-img'   src={ pathname === '/support' ? Support  : SupportIcon } alt='profile-icon' /><div className={toggleSidebar ? 'display-none' : 'link-text'}>Support</div></NavLink>
          </div>
        <hr/>
        {
          !toggleSidebar && links.quicklinks.length > 0 &&
          <div className='quick-link-container'>
                <div className='link-heading'>
                  <img src={QuickLink} alt='quick-link-icon' className='quick-link-img cursor' /> 
                  <div className='quick-link-text'>Quick Links</div> 
                </div>
                <div className='external-links'>
                  {
                    links.quicklinks.length > 0 && links.quicklinks.map( quicklink => <a rel="noreferrer" href={quicklink.link} target='_blank' key={quicklink.id}>{quicklink.name}</a>)
                  }
                </div>
          </div>
        }

        {
          toggleSidebar && links.socialLinks.length > 0 &&
          <img src={QuickLink} alt='quick-link-icon' className='quick-link-img cursor' /> 
        }
        </div>

        <div className={ toggleSidebar ? 'center-sidebar-lower-content' : 'sidebar-lower-content'}>
          { 
          links.socialLinks.length > 0 &&
            <div className='social-media-links-container'>
              <div className='social-media-links-text'>Get Social</div>
              <div className={ toggleSidebar ? 'verticle-social-media-links' : 'social-media-links'}>
                {
                  links.socialLinks.length > 0 && links.socialLinks.map( socialLink => <a href={socialLink.link} rel="noreferrer" target='_blank' className='social-media-link' key={socialLink.id}><img className='social-media-link-img' title={socialLink.name} src={getSocialLinkImgByName(socialLink.name)} alt='instagram-icon' /></a>)
                }
              </div>
            </div>
          }

          {
            !toggleSidebar &&
            (
              <div className='text-links'>
                {
                  links.policyLinks.length > 0 && links.policyLinks.map( policyLink => <a rel="noreferrer" href={policyLink.link} target='_blank' className='text-link' key={policyLink.id}>{policyLink.name}</a>)
                }
              </div>
            )
          }
        </div>
      </div>}
      <div className={ responsiveToggle ? 'display-none' : 'mobile-side-bar'}>
        <img src={`/images/responsive.btn.png`} alt='toggle-button' className='responsive-button cursor' onClick={() => toggleResponsiveFunc()} />
        <img src={`data:image/jpeg;base64,${settings.companyLogo}`} alt='company-logo' className='mobile-my-package-forwarder-logo' />
      </div>
    </>
  )
}

export default Sidebar