import React from 'react';
import './MobileShippedPackageDetails.css';
import { statusImgById, statusNameById } from '../../../utils';
import AccordianForShippedPackage from '../AccordianForShippedPackage/AccordianForShippedPackage';
import { useNavigate } from 'react-router-dom';
import { getAlignedAddress } from '../../../global';

const MobileShippedPackageDetails = ({ packageData, selectedPackage, packageClicked, summary, shippedAddress, dimension, contents, totalPackageCost, getOutgoingLink }) => {
    const navigate = useNavigate();
  return (
    <>
        <div className={ packageData.selected ? 'selected-mobile-package-detail-card' : 'mobile-package-detail-card'}>
            <div className='status-img-modal-icons-container'>
                <div className='package-img' onClick={() => packageClicked(packageData)}>
                    <img src={statusImgById(packageData.status)} alt='package-img' width={100} className='package-status-img' />
                    <div className='gray-bold--text'>{statusNameById(packageData.status)}</div>
                </div>
                <div className='modal-icons'>
                    {/* <img onClick={() => setOpenQuestionnaireModal(!openQuestionnaireModal)} className='mobile-package-icon' src='/images/mobile-edit-package-icon.svg' alt='edit-img' /> */}
                    {/* <img className='mobile-package-icon summary-mobile-icon' src='/images/summary-img.svg' alt='summary-img' /> */}
                    { packageData.paid === 0 && packageData.splitPaymentId !== 0 && <img src='/images/doller-img.svg' alt='split-img' />}
                </div>
            </div>
            <div className='mobile-package-detail-container' onClick={() => packageClicked(packageData)}>
                <div className='mobile-package-detail-name-values'>
                    <div className='detail-name'>Incoming track ID</div>
                    <div className='value'>{packageData.incomingTrack || ''}</div>
                </div>
                <div className='mobile-package-detail-name-values'>
                    <div className='detail-name'>Package Id</div>
                    <div className='value'>{packageData.id || ''}</div>
                </div>
                <div className='mobile-package-detail-name-values'>
                    <div className='detail-name'>Recieved On</div>
                    <div className='value'>{packageData?.dateArrived?.slice(0,10) || ''}</div>
                </div>
                <div className='mobile-package-detail-name-values'>
                    <div className='detail-name'>Product Name</div>
                    <div className='value'>{ packageData.consolidate ? "Combined Package" : JSON.parse(packageData.packageContent)?.[0]?.name || '' }</div>
                </div>
            </div>
        </div>
        {
            packageData.selected
            ? <div className='package-details'>
                    { Object.keys(selectedPackage).length === 0 
                    ? (<><img src='/images/noPackagesImage.svg' alt='no-packages-img' className='no-packages-img' />
                    <div>No Packages Have Selected yet</div></>)
                    : (<div className='shipped-package-content-container'>
                        <div className='package-name-img'>
                            <div className='package-name-number'>
                            <div className='package-name'>{selectedPackage.consolidate?"Combined Package":contents}</div>
                            <div className='gray-text'> Pack ID: {selectedPackage.id}</div>
                            </div>
                            <div className='package-img'>
                            <img width={75} src={statusImgById(selectedPackage.status)} alt='status-img' title={statusNameById(selectedPackage.status)} />
                            </div>
                        </div>
                        <div className='package-dimensions'>
                            <div className='package-content-card-heading'>Shipping To</div>
                            <div className='package-dimension'>{getAlignedAddress(shippedAddress)}</div>
                        </div>
                        <div className='package-dimensions'>
                            <div className='package-content-card-heading'>Outgoing Track ID</div>
                            {
                                selectedPackage?.outcomingTrack
                                ? <a target="_blank" rel="noreferrer" href={getOutgoingLink(selectedPackage?.outcomingTrack , selectedPackage.serviceName)}>{selectedPackage?.outcomingTrack}</a>
                                : <div className='package-dimension'>{''}</div>
                            }  
                        </div>
                        <div className='package-dimensions'>
                            <div className='package-content-card-heading'>Dimension</div>
                            <div className='package-dimension'> {dimension} </div>
                        </div>
                        <div className='package-dimensions'>
                            <div className='package-content-card-heading'>Shipping Method</div>
                            <div className='package-dimension'>{selectedPackage.serviceName}</div>
                        </div>
                        <div className='single-package-details'>
                            <div className='package-content-card-heading'>{ selectedPackage.consolidate === 1 ? 'Combined Package' : 'Single Package'} Content</div>
                            <div className='package-table'>
                            <div className='package-table-headings'>
                                <div className='package-table-heading'>Package ID</div>
                                <div className='package-table-heading flex04 text-center'>Name</div>
                                <div className='package-table-heading flex04 text-center'>Quantity</div>
                                <div className='package-table-heading flex04 text-center'>Unit Cost</div>
                                <div className='package-table-heading flex04 text-center'>Total</div>
                                </div>
                                {summary.packageContent && Object.keys(summary.packageContent).length>0 && 
                                Object.keys(summary.packageContent).map(id=>summary.packageContent[id].map(c=>
                                <div className='package-table-headings' key={c.id}>
                                <div className='package-table-data'>{c.packageId}</div>
                                <div className='package-table-data flex04 text-center'>{c.name}</div>
                                <div className='package-table-data flex04 text-center'>{c.quantity}</div>
                                <div className='package-table-data flex04 text-center'>{c.unitCost}</div>
                                <div className='package-table-data flex04 text-center'>{c.quantity*c.unitCost}</div>
                                </div>))}
                                <div className='package-content-card-heading summry-heading-text'>Summary</div>
                                <div className='package-table-headings'>
                                <div className='package-table-heading'>Item Description</div>
                                <div className='package-table-heading flex04 text-center'>Qty</div>
                                <div className='package-table-heading'>Price (USD)</div>
                                </div>
                                <div className='package-table-contents'>
                                {
                                    Object.keys(summary).length > 0 && selectedPackage.consolidate !== 0 && selectedPackage.consolidatedPackageId.map( consolidatedPackage => <AccordianForShippedPackage packageData={consolidatedPackage} showId={true} overtimeCost={summary.overTimeCost && Object.keys(summary.overTimeCost).length > 0 ? summary.overTimeCost[consolidatedPackage.id]:false} revertCost={summary.revertCost && Object.keys(summary.revertCost).length > 0 ? summary.revertCost[consolidatedPackage.id]:false} extras={summary.extras && Object.keys(summary.extras).length > 0 ? summary.extras[consolidatedPackage.id]:false} packageContent={summary.packageContent && Object.keys(summary.packageContent).length > 0 ? summary.packageContent[consolidatedPackage.id]:false}/>)
                                }
                                <div className='package-table-content'>
                                    {/* <div className='package-table-data bold-text'>{selectedPackage.incomingTrack}</div> */}
                                    <div className='package-table-data flex04 text-center'></div>
                                    <div className='package-table-data'></div>
                                </div>
                                {
                                    summary.extras && Object.keys(summary.extras).length > 0 && summary.extras[selectedPackage.id] && summary.extras[selectedPackage.id].map( extra => 
                                    <div className='package-table-content' key={extra.id}>
                                        <div className='package-table-data'>{extra.name}</div>
                                        <div className='package-table-data flex04 text-center'>{extra.quantity}</div>
                                        <div className='package-table-data'>${extra.cost}</div>
                                    </div>
                                    ) 
                                }
                                { summary.splits && summary.splits[0] && summary.splits[0]?.cost > 0 &&
                                <div className='package-table-content'>
                                    <div className='package-table-data'>Split Payment Cost</div>
                                    <div className='package-table-data flex04 text-center'>1</div>
                                    <div className='package-table-data'>${summary.splits[0]?.cost}</div>
                                    </div>
                                    }
                                {
                                    summary.overTimeCost && Object.keys(summary.overTimeCost).length > 0 && summary.overTimeCost[selectedPackage.id] > 0 &&
                                    <div className='package-table-content'>
                                    <div className='package-table-data'>Over Time Cost</div>
                                    <div className='package-table-data flex04 text-center'>1</div>
                                    <div className='package-table-data'>${summary.overTimeCost[selectedPackage.id]}</div>
                                    </div>
                                }
                                {
                                    summary.revertCost && Object.keys(summary.revertCost).length > 0 && summary.revertCost[selectedPackage.id] > 0 &&
                                    <div className='package-table-content'>
                                    <div className='package-table-data'>Revert Cost</div>
                                    <div className='package-table-data flex04 text-center'>1</div>
                                    <div className='package-table-data'>${summary.revertCost[selectedPackage.id]}</div>
                                    </div>
                                }
                                {
                                    summary.package.shipmentCost !== 0 &&
                                    <div className='package-table-content'>
                                    <div className='package-table-data'> Shipment Cost </div>
                                    <div className='package-table-data flex04 text-center'>1</div>
                                    <div className='package-table-data'>${summary.package.shipmentCost}</div>
                                    </div>
                                }
                                {
                                    summary.coupon &&
                                    <div className='package-table-content'>
                                    <div className='package-table-data'> Discount {summary.coupon.discountType}{summary.coupon.cost} off </div>
                                    <div className='package-table-data flex04 text-center'>1</div>
                                    <div className='package-table-data'>${summary.coupon.discountType==='%' ? (((totalPackageCost(selectedPackage.id)[1])/100)*summary.coupon.cost).toFixed(2) : summary.coupon.cost }</div>
                                    </div>
                                }
                                { summary.package.totalCost !== 0 && <div className='package-table-content'>
                                    <div className='package-table-card-heading'>Order Total</div>
                                    <div className='package-table-data flex04 text-center'></div>
                                    <div className='package-table-card-heading'>{totalPackageCost(selectedPackage.id)[0]}</div>
                                </div>
                                }
                                {summary.splits && summary.splits.length > 0  && <div className='package-table-content'>
                                    <div className='package-table-data'> Completed Splits</div>
                                    <div className='package-table-data flex04 text-center'></div>
                                    <div className='package-table-data'></div>
                                    </div>}
                                    {
                                    summary.splits && summary.splits.length > 0 && summary.splits.map((split,index) => 
                                    <div className='package-table-content' key={split.id}>
                                        <div className='package-table-data'>{index+1}</div>
                                        {split.discountType && split.couponCost ?<div className='package-table-data flex04 text-center'> Discount {split.discountType}{split.couponCost} off</div>:<div className='package-table-data flex04 text-center'></div>}
                                        <div className='package-table-data'>{split.finalAmount}</div>
                                    </div>
                                    ) 
                                }
                                { summary.splits && summary.splits.length > 0 && selectedPackage.paid === 0 && <div className='split-payment-button' onClick={() => navigate('/dashboard/payment-method' , { state: { state: [selectedPackage.id], 
                                selectedSplitData: summary.splits[summary.splits.length-1] , total:(summary.splits[summary.splits.length-1].amount / summary.splits[summary.splits.length-1].totalInstallments).toFixed(2)} })}><span>Split Payment {summary.splits[summary.splits.length-1].currentInstallment} / {summary.splits[summary.splits.length-1].totalInstallments}</span><span>${(summary.splits[summary.splits.length-1].amount / summary.splits[summary.splits.length-1].totalInstallments).toFixed(2)} / {summary.splits[summary.splits.length-1].amount}</span> </div> }
                                </div>
                            </div>
                            </div>
                </div>)}
              </div>
            : ''
        }
    </>
  )
}

export default MobileShippedPackageDetails