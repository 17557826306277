import React, { useState, useEffect } from 'react';
import './Ticket.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Axios, useInput, useUploadImage } from '../../hooks';
import { httpStatus, getTimeDifference, getTicketID } from '../../utils';
import { ImagesSliderModal } from '../../Modals';
import { Spinner } from 'react-bootstrap';

const Ticket = () => {
  const location = useLocation();
  const { id } = useParams()
  const ticketData = location.state;
  const { userDetails } = useSelector((state) => state.userData);
  const [messages, setMessages] = useState(null)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [messagesDetails, setMessagesDetails] = useState({ message: '', files: null,fileNames:'' })
  const { inputHandler } = useInput();
  const { uploadImage } = useUploadImage();
  const [updateMessages, setUpdateMessages] = useState(false);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [images, setImages] = useState([]);
  const [ticket, setTicket] = useState(ticketData ? ticketData : null);

  useEffect(() => {
    (async () => {
      try {
        if (ticketData) {
          const tmessages = await Axios.get(`/tickets/messages?ticketId=${ticket.id}`, { headers: { accessToken: localStorage.getItem('AccessToken') } })
          setMessages(tmessages.data.data)
        } else {
          const tmessages = await Axios.get(`/tickets/?id=${id.split('T')[1]}`, { headers: { accessToken: localStorage.getItem('AccessToken') } })
          setTicket(tmessages.data.ticket[0])
          setMessages(tmessages.data.messages)
        }

      } catch (e) {
        httpStatus(e)
      }
    })()
  }, [ticketData,id])

  useEffect(() => {
    (async () => {
      try {
        if (ticket) {
          const tmessages = await Axios.get(`/tickets/messages?ticketId=${ticket.id}`, { headers: { accessToken: localStorage.getItem('AccessToken') } })
          setMessages(tmessages.data.data)
        }
      } catch (e) {
        httpStatus(e)
      }
    })()
  }, [updateMessages])

  const changeStatus = async () => {
    try {
      await Axios.put(`/tickets/${ticket.id}`, { ticketStatus: ticket.ticketStatus !== 3 ? 3 : 4 }, { headers: { accessToken: localStorage.getItem('AccessToken') } })
      navigate('/support')
    } catch (e) {
      httpStatus(e)
    }
  };

  const uploadFiles = async () => {
    let urls = []
    for (let i = 0; i < messagesDetails.files.length; i++) {
      let imageUrl = await uploadImage({ image: messagesDetails.files[i], type: 'tickets', clientId: userDetails?.prefixSuite.toLowerCase() });
      urls.push(imageUrl.replace(/\s/g, '+'))
    }
    return urls
  };

  const sunbmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let urls
      if (messagesDetails.files) {
        urls = await uploadFiles();
      }
      await Axios.post('/tickets/messages', { ...messagesDetails, ticketId: ticket.id, ticketStatus: ticket.ticketStatus, mediaUrls: JSON.stringify(urls) },
        { headers: { accessToken: localStorage.getItem('AccessToken') } })
      setMessagesDetails({ message: '', files: null,fileNames:'' })
      setLoading(false)
      setUpdateMessages(updateMessages ? false : true)
    } catch (e) {
      setLoading(false)
      httpStatus(e)
    }
  };

  const imagesView = (image) => {
    setOpenImagesModal(true)
    image && JSON.parse(image) && JSON.parse(image).map(i => images.push({ source: i }))
  }

  const setFiles=(e)=>{
    e.preventDefault();
    let names = []
      for (let i = 0; i < e.target.files.length; i++) {
        names.push(e.target.files[i].name)
      }
    setMessagesDetails(prev => ({ ...prev, files: e.target.files,fileNames:names.join()}))
  }

  return (
    ticket ? <div className='ticket-w-100'>
      <div className='div-ticket-header hide-xs'>
        <div className='d-t-flex'>
          <h2> <span className="material-symbols-outlined left-arrow">keyboard_arrow_left</span> Tickets ID: {getTicketID(ticket.ticketType, ticket.packageId, ticket.id)}</h2>
          <div className={ticket.ticketStatus === 1 ? 'open-status' : ticket.ticketStatus === 2 ? 'inprogress-status' : ticket.ticketStatus === 3 ? 'closed-status' : ticket.ticketStatus === 4 ? 'reopen-status' : null}>{ticket.statusName}</div>
        </div>
        <div className='d-t-flex'>
          <div className={ticket.ticketStatus !== 3 ? "cus-b-btn-w-c" : "cus-b-btn-w-r"} onClick={() => { changeStatus() }}>{ticket.ticketStatus !== 3 ? "Close Ticket" : "Re-Open Ticket"}</div>
          <div className='cus-b-btn' onClick={() => { navigate('/support/new-ticket') }}>create ticket</div>
        </div>

      </div>
      <div className='div-ticket-header hide-sm'>

        <div className='cus-b-btn' onClick={() => { navigate('/support/new-ticket') }}>create ticket</div>
        <h2> <div className="material-symbols-outlined left-arrow" onClick={() => { navigate('/support') }}>keyboard_arrow_left</div>Tickets</h2>
      </div>

      <div className='td-div1'>
        <div className='m-d-flex'>
          <div>Ticket type</div>
          <div>{ticket.typesName}</div>
        </div>
        <div className='m-d-flex'>
          <div>Subject</div>
          <div>{ticket.packageId ? `${ticket.packageId} related issue` : ticket.subject}</div>
        </div>
      </div>

      <div className='ticket-details'>

        <div className='message-div'>
          <div className='td-div2'>
            {messages && messages.map((m, id) =>
              m.customerOrstaff === 0 ?
                <div className='message-right' key={id}>
                  <h2>{userDetails.firstName} {userDetails.lastName}</h2>
                  <p>{m.message}</p>
                  {JSON.parse(m.mediaUrls) && JSON.parse(m.mediaUrls).map((i, id) => <img key={id} src={i} alt='ticket-img' onClick={() => imagesView(m.mediaUrls)} className='message-images' />)}
                  <h6>{getTimeDifference(userDetails.timeZone,m.createdTime)}</h6>
                </div> : <div className='message-left' key={id}>
                  <h2>{"My Package Forwarder"}</h2>
                  <p>{m.message}</p>
                  <h6>{getTimeDifference(userDetails.timeZone,m.createdTime)}</h6>
                </div>
            )}
          </div>
          <form onSubmit={(e) => sunbmitForm(e)}>
          <span className='filesNames'>{messagesDetails.fileNames}</span>
            <div className='message-send'>
              <div clas="file_input_wrap">
                <input type="file" name="imageUpload" accept="image/*" onChange={(e) => setFiles(e)} id="imageUpload" class="hide" multiple />
                <label for="imageUpload" class="btn btn-large"><span className="material-symbols-outlined attach-icon">attach_file</span> </label>
              </div>
              <input type='text' name='message' value={messagesDetails.message} className='form-control' onChange={(e) => inputHandler(e, setMessagesDetails)} />
              <button class="btn btn-large btn-blue"><span className={`material-symbols-outlined send-icon ${loading && 'disabled'}`}>send {loading && <Spinner size='sm' />}</span></button>
            </div>
          </form>
        </div>
        <div className='hide-sm' onClick={() => { changeStatus() }}>{ticket.ticketStatus !== 3 ? <div className='cus-g-btn-c'>Close Ticket</div> : <div className='cus-g-btn-re'>Re-Open Ticket</div>}</div>

      </div>
      <ImagesSliderModal setIsOpenImages={setOpenImagesModal} isOpen={openImagesModal} images={images} />

    </div> : <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</h1>
  );
};


export default Ticket