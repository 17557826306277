import React , { useState , useEffect } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';
import { Axios } from '../hooks';
import { useSelector } from 'react-redux';
import { httpStatus } from '../utils';

const SummaryModal = ({ show , setShow , selectedPackage }) => {
    const [ extras , setExtras ] = useState([]);
    const [ packageData , setPackageData ] = useState({});
    const [ dimentions , setDimensions ] = useState({ length: '' , width: '' , height: ''});
    const [ total , setTotal ] = useState(0);
    const { selectedWarehouse } = useSelector( state => state.warehouseDetails );
    const { settings } = useSelector( state => state.userData );

    useEffect(() => {
        if( selectedPackage && show ){
            (async() => {
                try{
                    const res = await Axios.get(`/packages/summary/${selectedPackage.id}`, { headers: { accessToken: localStorage.getItem('AccessToken')}});
                    setExtras(res.data.data.extras);
                    setPackageData(res.data.data);
                    setDimensions(res.data.data.package.dimensions ? JSON.parse(res.data.data.package.dimensions) : { length: '' , width: '' , height: ''});
                }catch(err){
                    httpStatus(err);
                }
            })();
        }
    },[ selectedPackage , show ]);

    window.addEventListener('click' , (e) => {
        if(e.target.className === 'fade modal show'){
          setShow(false);
        } 
     });

     useEffect(() => {
        if(packageData){
            let overTimeCost = packageData.overTimeCost && Object.keys(packageData.overTimeCost).length >0 ? Object.keys(packageData.overTimeCost).map(id=>packageData.overTimeCost[id]).reduce((accu,current)=>accu+=current,0):0;
            let revertCost =   packageData.revertCost && Object.keys(packageData.revertCost).length >0 ? Object.keys(packageData.revertCost).map(id=>packageData.revertCost[id]).reduce((accu,current)=>accu+=current,0):0
            let extrasCost = Object.keys(extras).map(selectedExtra => extras[selectedExtra].map(extra => extra.cost)).flat(1).reduce((accu , current) => accu += current , 0);
            setTotal(extrasCost+revertCost+overTimeCost);
        }
     },[packageData]);

  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} centered>
        <div className='modal-container'>
          <div className='modal-heading' onClick={() => setShow(false)}>
            <div className='modal-heading-name'>Package Summary</div>
            <div className='modal-close-button' onClick={() => setShow(false)}><CloseButton /></div>
          </div>
          <hr />
          <div className='modal-package-summary'>
            <div className='summary-left-content'>
                <div className='gift-wrap-div-img'>
                    <img src='/images/white-giftWrap.svg' alt='gift-wrap-img' className='summary-gift-wrap-image' />
                </div>
                <div className='modal-summary-package-details'>
                    <div className='package-detail'>Package ID : <span className='black-text'>{packageData.package?.id}</span></div>
                    <div className='package-detail'>Warehouse : <span className='black-text'>{selectedWarehouse?.name}</span></div>
                    <div className='package-detail'>L*W*H : <span className='black-text'>{dimentions.length}*{dimentions.width}*{dimentions.height}{' '}in</span></div>
                    <div className='package-detail'>Weight : <span className='black-text'>{packageData.package?.weight} lb</span></div>
                </div>
            </div>
            <div className='summary-right-content'>
                <div className='package-table'>
                    <div className='package-table-headings'>
                        <div className='package-table-heading'>Charge Description</div>
                        <div className='package-table-heading flex04'>Qty</div>
                        <div className='package-table-heading'>Total Price(USD)</div>
                    </div>
                    {
                        Object.keys(extras)?.map( extrasID => (
                            <div key={extrasID}>
                            <div className='secondary-bold-text'>Package ID: {extrasID}</div>
                            <div className='package-table-contents'>
                                {
                                    extras[extrasID]?.map( (selectedExtras , index) =>{if(selectedExtras.name==='Incomplete SuiteID'){
                                        return <div className='package-table-content' key={selectedExtras.id}>
                                        <div className='package-table-data'>{selectedExtras.name} <a className='incomplete-suite' href={settings?.incompleteSuite} target="_blank">(How to Fix)</a></div>
                                         <div className='package-table-data flex04 ml1'>{selectedExtras.quantity}</div>
                                        <div className='package-table-data ml1'>${selectedExtras.cost}</div>
                                      </div>
                                    }else{
                                        return <div className='package-table-content' key={index}>
                                        <div className='package-table-data'>{selectedExtras.name}</div>
                                        <div className='package-table-data flex04 ml1'>{selectedExtras.quantity}</div>
                                        <div className='package-table-data ml1'>${selectedExtras.cost}</div>
                                    </div> 
                                    }})
                                }
                                 {
                        packageData.overTimeCost && packageData.overTimeCost[extrasID] > 0 &&
                        <div className='package-table-content'>
                            <div className='package-table-data'>Over Time Cost</div>
                            <div className='package-table-data flex04 ml1'></div>
                            <div className='package-table-data ml1'>${packageData.overTimeCost[extrasID]}</div>
                        </div>
                        }
                        {
                        packageData.revertCost && packageData.revertCost[extrasID] > 0 &&
                        <div className='package-table-content'>
                            <div className='package-table-data'>Revert Cost</div>
                            <div className='package-table-data flex04 ml1'></div>
                            <div className='package-table-data ml1'>${packageData.revertCost[extrasID]}</div>
                        </div>
                        }
                            </div>
                            </div>
                        ))
                    }
                     {
                        packageData.overTimeCost && packageData.overTimeCost[packageData.id] > 0 &&
                        <div className='package-table-content'>
                            <div className='package-table-data'>Over Time Cost</div>
                            <div className='package-table-data flex04 text-center'></div>
                            <div className='package-table-data flex01'>${packageData.overTimeCost[packageData.id]}</div>
                        </div>
                        }
                        {
                        packageData.revertCost && packageData.revertCost[packageData.id] > 0 &&
                        <div className='package-table-content'>
                            <div className='package-table-data'>Revert Cost</div>
                            <div className='package-table-data flex04 text-center'></div>
                            <div className='package-table-data flex01'>${packageData.revertCost[packageData.id]}</div>
                        </div>
                        }
                    <div className='package-table-contents'>
                        <div className='package-table-content tbd-content'>
                            <div className='package-table-data'>Shipping</div>
                            <div className='package-table-data flex04 ml1'></div>
                            <div className='package-table-data ml1'>TBD</div>
                        </div>
                        <hr />
                        <div className='package-table-content'>
                            <div className='package-table-data primary-medium-text'>Order Total</div>
                            <div className='package-table-data flex04 ml1'></div>
                            <div className='package-table-data primary-medium-text ml1'>${total}</div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <hr />
          <div className='primary-white-button cursor' onClick={() => setShow(false)}><span className="material-symbols-outlined">done</span></div>
        </div>
    </Modal>
  )
}

export default SummaryModal