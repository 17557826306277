import React,{ useEffect, useState } from 'react';
import './TopSection.css';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentWarehouse, updateSelectedWarehouse,addWarehouse, addMarkeingData } from '../../store/warehouseSlice';
import { Axios } from '../../hooks';
import { addPackages, addRecentActivitiesData,refreshTopSectionData,setshippedPackagesCount } from '../../store/topSectionData';
import { useNavigate } from 'react-router-dom';
import { setAuthentication } from '../../store/authenticationSlice';
import { dateTimeFormat, httpStatus } from '../../utils';
import { Points, Dollars } from '../../assets/icons';

const TopSection = ({ isDashboardPage, setSelectedPackage}) => {
  const { warehouses , selectedWarehouse, currentWarehouse } = useSelector((state) => state.warehouseDetails);
  const { userDetails } = useSelector((state) => state.userData);
  const { packages, refreshData , recentActivitiesData,shippedPackagesCount } = useSelector((state) => state.topSectionData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ changedWarehouse , setChangedWarehouse ] = useState(false);

  const nextWarehouse = async() => {
    setSelectedPackage({});
    setChangedWarehouse(true);
    dispatch(refreshTopSectionData());
    dispatch(updateCurrentWarehouse(currentWarehouse === warehouses.length - 1 ? 0 : currentWarehouse + 1));
    dispatch(updateSelectedWarehouse(warehouses[currentWarehouse === warehouses.length - 1 ? 0 : currentWarehouse + 1]));
    sessionStorage.setItem('selectedWarehouseCountryId' , warehouses[currentWarehouse === warehouses.length - 1 ? 0 : currentWarehouse + 1].countryId);
  }


  useEffect(() => {
    (async() => {
      try{
        if(changedWarehouse){
          if(!packages.length && selectedWarehouse.id){
            const packagesRes = await Axios.get(`/packages?wareHouseId=${selectedWarehouse.id}` , { headers : { accessToken : localStorage.getItem('AccessToken')}});
            localStorage.setItem('AccessToken' , packagesRes.data.data.accessToken );
            dispatch(addPackages(packagesRes.data.data.packages));
            dispatch(setshippedPackagesCount(packagesRes.data.data.shippedPackageCount));
            const marketingRes = await Axios.get('/customers/marketing' , { headers : { accessToken : localStorage.getItem('AccessToken')}});
            dispatch(addMarkeingData(marketingRes.data.data));
          }
        }else{
          if(!packages.length){
            const packagesRes = await Axios.get(`/packages` , { headers : { accessToken : localStorage.getItem('AccessToken')}});
            dispatch(addPackages(packagesRes.data.data.packages));
            dispatch(setshippedPackagesCount(packagesRes.data.data.shippedPackageCount));
          }
        }
        // if(!recentActivitiesData.length){
        //   const recentActivitiesRes = await Axios.get('/customers/activities' , { headers : { accessToken : localStorage.getItem('AccessToken')}});
        //   dispatch(addRecentActivitiesData(recentActivitiesRes.data.data));
        // }
        if(!warehouses.length){
          const allWarehousesRes = await Axios.get('/warehouses/customers' , { headers : { accessToken : localStorage.getItem('AccessToken')}});
          dispatch(addWarehouse(allWarehousesRes.data));
          dispatch(updateSelectedWarehouse(allWarehousesRes.data[0]));
          sessionStorage.setItem('selectedWarehouseCountryId' , allWarehousesRes.data[0].countryId);
        }
      }catch(err){
        setAuthentication(false)
        httpStatus(err)
      }
    })()
  },[ refreshData ]);

  return (<>
  {/* {window.innerWidth <= 992 && <div className='eraned-points'>
          <div className='border-points'>
            <div className='points-text'>Total Earned Points</div>
            <img src={Points} alt='points-img' className='header-points-img' />
            <div className='points-value'>234</div>
          </div>
          <div className='border-dollar'>
            <div className='dollar-text'>Dollars</div>
            <img src={Dollars} alt='dollar-img' className='header-dollar-img' />
            <div className='dollars-value'>$23</div>
          </div>
        </div>} */}
    <div className="dashboard-bgs">
        <div className="left-side-content">
            {/* <div className='shipped-and-all-packages'>
              <div className="all-packages">
                  <img src="/images/totalPackagesbg.svg" alt="totalPackagesbg" className='total-packages-img' />
                  <div className='total-packages-count'>{ packages.length }</div>
                  <div className="total-packages-text">Packages</div>
              </div>
              <div className="shipped-packages">
                  <img src="/images/shippedPackagesbg.svg" alt="shippedPackagesbg" className='total-shipped-packages-img' />
                  <div className='shippedPackages-count'>{shippedPackagesCount}</div>
                  <div className="shippedPackages-text">My Shipped <br /> Packages</div>
              </div>
            </div>  */}

            <div className='shipped-and-all-packages'>
              <div className="all-packages">
                  <img src="/images/totalPackagesbg.svg" alt="totalPackagesbg" className='total-packages-img' />
                  <div className='total-packages-count1'>{ packages.length }</div>
                  <div className="total-packages-text1">Packages</div>
              </div>
              <div className="all-packages shipped-packages1">
                  <img src="/images/shippedPackagesbg.svg" alt="shippedPackagesbg" className='total-shipped-packages-img' />
                  <div className='shippedPackages-count1'>{shippedPackagesCount}</div>
                  <div className="shippedPackages-text1">My Shipped <br /> Packages</div>
              </div>
            </div> 
            {
              window.innerWidth <= 992 
              ? <div className='secondary-button' onClick={() => navigate('/dashboard/recent-activity' , { state: null })}>Recent Activity</div>
              : <div className="recent-activities recen-act-new" onClick={() => navigate('/support')}>
              {/* <div className="activities-heading-link">
                 <div className="activities-heading"> Recent Activities </div>
                 { recentActivitiesData.length > 0 && (<div onClick={() => navigate('/dashboard/recent-activity' , { state: null })} className="activities-link cursor"> See all the Activities </div>)}
              </div> */}
              {/* <div className="activities">
                 { recentActivitiesData.length > 0 ? recentActivitiesData.slice(0,3).map( activity => (
                  <div className='activity-container' key={activity.id}>
                    <div className='recent-activity-img-text'>
                      <div className="activity-img">
                      <img src={   
                        activity.activity.includes('address') ? '/images/location-icon.svg' : 
                        activity.activity.includes('Consolidate') ? '/images/consolidation-icon.svg' : 
                        activity.activity.includes('payment') ? '/images/payment-icon.svg' : '/images/payment-icon.svg' } alt="activity-icon" className='activity-icon' />
                      </div>
                      <div className="activity-text">{activity.activity}</div>
                    </div>
                    <div className="activity-time">{dateTimeFormat(activity.time,true)}</div>
                  </div>    
                 ))
                : <div>
                    <img src='/images/no-recent-activity.svg' alt='no-recent-activity-img' /> 
                    <div className='text-border-color'>No recent activities to show</div>                 
                  </div>
                }
              </div> */}
              {/* <img src='images/support-ads.svg' alt='support-img' onClick={()=>navigate('/support')}/> */}
              <div className='recen-act-new-inner'>
              <h4>Introducing<br/> 
              MPF Ticket Support</h4>
              <p>Explore MPF Ticket Support: Empower seamless ticketing<br/> experiences, ensuring efficiency and guest satisfaction</p>
              <div className='btn-blue btn'>Explore Now</div>
              </div>
              <img src="/images/recent-acr.png" alt="shippedPackagesbg" className='recent-act-image' />
            </div>}
        </div>
        <div className="right-side-content">
               <img src="/images/warehousesbg.svg" alt="shippedPackagesbg" className='total-shipped-packages-img' />
               <div className='warehouses-slider-container'>
                {
                  isDashboardPage
                  ? 
                  warehouses.length === 1
                  ? <div className=''>
                  {
                    warehouses.map( warehouse => selectedWarehouse.id === warehouse.id && (<img key={warehouse.id} src={`/flags/${warehouse.iso2.toLowerCase()}.png`} alt='country-flag' className='single-warehouse-flag' />)
                    )
                  }
                </div>
                  : <div className='flags'>
                  { warehouses.length > 1 && 
                    <div className='forword-btn' onClick={() => nextWarehouse()}>
                      <span className="material-symbols-outlined text-white-bold chevron-icon cursor">chevron_right</span>
                    </div>
                  }
                  {
                    warehouses.map( (warehouse, index) => (
                      <div key={warehouse.id}> 
                        { 
                          currentWarehouse === index && (<img key={warehouse.id} src={`/flags/${warehouse.iso2.toLowerCase()}.png`} alt='country-flag' className='active-warehouse-flag' />)
                        }
                        { ( currentWarehouse === warehouses.length - 1 ? 0 : currentWarehouse + 1 ) === index && warehouses.length > 1 && (<img key={warehouse.id} src={`/flags/${warehouse.iso2.toLowerCase()}.png`} alt='country-flag' className='warehouse-flag previous-warehouse-flag' />)
                        }
                      </div>
                    ))
                  }
                </div>
                  : <div className='single-flag'>
                      {
                        warehouses.map( warehouse => selectedWarehouse.id === warehouse.id && (<img key={warehouse.id} src={`/flags/${warehouse.iso2.toLowerCase()}.png`} alt='country-flag' className='single-warehouse-flag' />)
                        )
                      }
                    </div> 
                }
                {
                  selectedWarehouse &&
                  <div className='warehouse-details1'>
                    <div className='warehouse-fname-lname'>{selectedWarehouse.name}</div>
                    <div className='warehouse-address text-white-bold'>{selectedWarehouse.addressLine1}, <br />{selectedWarehouse.addressLine2 ? `${selectedWarehouse.addressLine2},` : ''}</div>
                    <div className='warehouse-suiteid'>SuiteId: {userDetails?.prefixSuite}{userDetails?.suiteId}</div>
                    <div className='warehouse-city-state text-white-bold'>{selectedWarehouse?.cityName || ''}{selectedWarehouse?.cityName && ','}{' '}{`${selectedWarehouse?.stateName},` || ''}</div>
                    <div className='warehouse-zip-country text-white-bold'>{selectedWarehouse.zip}{' '}{selectedWarehouse.countryName}</div>
                </div>
                }
               </div>
        </div>
    </div>
    </>)
}

export default TopSection