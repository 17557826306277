import React, { useState , useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../pages/AddressAndPaymentPage/AddressAndPaymentPage.css';
import { OrderSummaryCard,PaymentInfoBillingAddressForm, AddressCard } from '../index.js';
import { Axios } from '../../hooks';
import { refreshTopSectionData } from '../../store/topSectionData';
import { v4 as uuidv4 } from 'uuid';
import CyberSourcePaymentCard from '../Cards/CyberSourcePaymentCard/CyberSourcePaymentCard';
// import crypto from 'crypto';
import CryptoJS from 'crypto-js';
import { httpStatus } from '../../utils';
// import axios from 'axios';

const CyberSourcePaymentAndAddressComponent = () => {
    const [ addresses , setAddresses ] = useState([]);
    const [ formData , setFormData ] = useState({ firstName:'' , lastName:'' , phone: '', addressLine1: '', addressLine2: '', countryId:'', stateId: '', cityId: '', zip: '' });
    const [ cyberSourcePaymentData , setCyberSourcePaymentData ] = useState({ cardHolderName: '' , CVV: '' , expiryDate: '' , cardNumber: ''});
    const [ paymentCards , setPaymentCards ] = useState([]);
    const [paymentOptions, setPaymentOptions] = useState({ card: true , amazonpay: false, paypal: false });
    const location = useLocation();
    const { total , selectedSplitData , state, imagesCost } = location.state;
    const [ selectedAddress , setSelectedAddress ] = useState(0);
    const [ selectedPaymentDetails , setSelectedPaymentDetails ] = useState({});
    // const { userDetails } = useSelector( state => state.userData );   
    const [ saveCard , setSaveCard ] = useState(false);
    const [ finalTotal , setFinalTotal ] = useState(0);
    const [ couponData , setCouponData ] = useState({});
    const [ isLoading , setIsLoading ] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ totalOrder, setTotalOrder ] = useState(0);
    const [ cvc , setCvc ] = useState(false);
    const [ grossAmount, setGrossAmount ] = useState(0);
    const [ cardsAndAddresses , setCardAndAddresses ] = useState([]);

    useEffect(() => {
        if( cardsAndAddresses.length === 0 ){
            (async() => {
                try{
                    const res = await Axios.get('/payment', { headers : { accessToken: localStorage.getItem('AccessToken')}});
                    setCardAndAddresses(res.data.data);
                }catch(err){
                    httpStatus(err);
                }
            })();
        }
    } , []);

    useEffect(() => {
        if( addresses.length === 0 && paymentCards.length === 0 ){
            cardsAndAddresses.forEach( element => {
                let uuid = uuidv4();
                setAddresses( prev => [...prev , { ...element.addressId , selected: false , referenceId: uuid }]);
                let card = element.card
                setPaymentCards( prev => [...prev , { ...card , addressId: element.addressId.id , selected: false , referenceId: uuid }]);
            })
        }
    }, [ cardsAndAddresses, addresses , paymentCards ]);


    const setAsDefaultAddress = (address) => {
         if(selectedAddress){
            if(selectedAddress === address.id){
                setAddresses( prev => prev.map( prevAddress => ({ ...prevAddress , selected: false })));
                setSelectedAddress(0);
                setPaymentCards( prev => prev.map( prevCard => ({ ...prevCard , selected: false })));
                setCyberSourcePaymentData({});
            }else{
                setAddresses( prev => prev.map( prevAddress => prevAddress.id === address.id ? { ...prevAddress , selected: true } : { ...prevAddress , selected: false }));
                setSelectedAddress(address.id);
                setCyberSourcePaymentData( paymentCards.filter( element => element.referenceId === address.referenceId)[0] );
                setPaymentCards( prev => prev.map( prevCard => prevCard.referenceId === address.referenceId ? { ...prevCard , selected: true } : { ...prevCard , selected: false }));
            }
         }else{
            setSelectedAddress(address.id);
            setAddresses( prev => prev.map( prevAddress => prevAddress.id === address.id ? { ...prevAddress , selected: true } : { ...prevAddress , selected: false }));
            setCyberSourcePaymentData( paymentCards.filter( element => element.referenceId === address.referenceId)[0] );
            setPaymentCards( prev => prev.map( prevCard => prevCard.referenceId === address.referenceId ? { ...prevCard , selected: true } : { ...prevCard , selected: false }));
         }
    }

    const setCard = (cardDetails) => {
        if(cyberSourcePaymentData){
            if(cyberSourcePaymentData.id === cardDetails.id){
                setPaymentCards( prev => prev.map( prevAddress => ({ ...prevAddress , selected: false })));
                setCyberSourcePaymentData({});
                setSelectedPaymentDetails({});
                setSelectedAddress(0);
                setAddresses( prev => prev.map( prevAddress => ({ ...prevAddress , selected: false })));
            }else{
                setCyberSourcePaymentData(cardDetails);
                setSelectedPaymentDetails(cardDetails);
                setPaymentCards( prev => prev.map( prevAddress => prevAddress.id === cardDetails.id ? { ...prevAddress , selected: true } : { ...prevAddress , selected: false }));
                setSelectedAddress( addresses.filter( element => element.referenceId === cardDetails.referenceId)[0].id );
                setAddresses( prev => prev.map( prevAddress => prevAddress.referenceId === cardDetails.referenceId ? { ...prevAddress , selected: true } : { ...prevAddress , selected: false }));
            }
         }else{
            setCyberSourcePaymentData(cardDetails);
            setSelectedPaymentDetails(cardDetails);
            setPaymentCards( prev => prev.map( prevAddress => prevAddress.id === cardDetails.id ? { ...prevAddress , selected: true } : { ...prevAddress , selected: false }));
            setSelectedAddress( addresses.filter( element => element.referenceId === cardDetails.referenceId)[0].id );
            setAddresses( prev => prev.map( prevAddress => prevAddress.referenceId === cardDetails.referenceId ? { ...prevAddress , selected: true } : { ...prevAddress , selected: false }));
         }
    }

    // console.log(cyberSourcePaymentData)

    const proceedForPayment = async(event) => {
        event.preventDefault();
        setIsLoading(true);
        let addressId = null;
        // let key_id = 'b1a96308-9edf-4541-aed0-1e6d84e2164a';
        // let secrect_key = '0DBa9GSkuQ79j9fOJtUU0+JIR+qZJBvOGJaydFZ4CmI=';
        // let merchantId= 'appmanufact';
        // let requestHost = 'apitest.cybersource.com';
        // let resource = '/tms/v1/instrumentidentifiers';
        if( finalTotal === 0 ){
            const response = await Axios.post(`/payment`,
            {
                finalAmount:finalTotal.toString(),
                packageId: state,
                payment: { "final_amount":0,"countryId": couponData?.id , status: "succeeded" },
                couponId:couponData?.id,
                saveCard: saveCard,
                imagePayment: imagesCost ? true : false,
            },
            { headers : { accessToken: localStorage.getItem('AccessToken')}})
            if (response.data.status) {
                dispatch(refreshTopSectionData());
                setIsLoading(false);
                navigate('/dashboard');
                toast("payment successful",{type:'info'});
            }
        }else{
        if( Object.values(formData).includes('') ){
            toast("Please Enter Address Properly!",{type:'info'});
        }else{
            if( Object.values(cyberSourcePaymentData).includes('') ){
                toast("Please Enter Card Details Properly!",{type:'info'});
            }else{
                if(!selectedAddress && saveCard){
                    try{
                        const newAddress = await Axios.post('/customers/address' ,{...formData}, { headers: { accessToken: localStorage.getItem('AccessToken')}});
                        addressId = newAddress.data.data.insertId
                    }catch(e){
                        httpStatus(e);
                        setIsLoading(false);
                        return
                    }
                }
                // const messageBody = {
                // "paymentInformation": {
                //     "card": {
                //         "number": cyberSourcePaymentData.cardNumber,
                //     }
                //  }}
                //  var data = crypto.enc.Utf8.parse(messageBody)
                //  var hash = crypto.SHA256(data)
                //  var digestCode = crypto.enc.Base64.stringify(hash);

                //  // signature
                //  var signatureHeader = "";
                // signatureHeader += "keyid=\"" + key_id + "\"";
                // signatureHeader += ", algorithm=\"HmacSHA256\"";
                // var headersForPostMethod = "host date (request-target) digest v-c-merchant-id";
                // signatureHeader += ", headers=\"" + headersForPostMethod + "\"";

                // var signatureString = 'host: ' + requestHost;
                // signatureString += '\ndate: ' + new Date(Date.now()).toUTCString();
                // signatureString += '\n(request-target): ';
                // var targetUrlForPost = "post " + resource;
                // signatureString += targetUrlForPost + '\n';
                // signatureString += 'digest: ' + digestCode + '\n';
                // signatureString += 'v-c-merchant-id: ' + merchantId;

                // var dataSigString = crypto.enc.Utf8.parse(signatureString);
                // var secKey = crypto.enc.Base64.parse(secrect_key);
                // var hashHmac = CryptoJS.HmacSHA256(dataSigString, secKey)
                // var base64hashHmac = CryptoJS.enc.Base64.stringify(hashHmac);

                // signatureHeader += ", signature=\"" + base64hashHmac + "\"";

            // try{
            //     console.log(
            //         {
            //             "v-c-merchant-id": 'merchant123',
            //             "Host": 'apitest.cybersource.com',
            //             "Date": new Date(Date.now()).toUTCString(),
            //             "Digest": `SHA-256=${digestCode}`,
            //             "Signature": signatureHeader,
            //         }
            //     )
            //     const res = await axios.post( 'https://apitest.cybersource.com/tms/v1/instrumentidentifiers' , 
            //     {headers: {
            //         "v-c-merchant-id": merchantId,
            //         "Host": 'apitest.cybersource.com',
            //         "Date": new Date(Date.now()).toUTCString(),
            //         "Digest": `SHA-256=${digestCode}`,
            //         "Signature": signatureHeader,
            // }})
            // console.log(res);
            // } catch (error) {
            //     console.log(error)
            // }
            const createKey = () => {
                let str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789/';
                return str.split('').sort((a, b) => {return Math.random() - 0.5}).join('');
            };
            
             
            
            const key = createKey();
            
             
            
            // const cypher = (str)=> {
            //     let sha256 = crypto.createHash('sha256');
            //     sha256.update(key);
            //     let iv = crypto.randomBytes(16);
            //     let cipher = crypto.createCipheriv('aes-256-ctr', sha256.digest(), iv);
            //     let ciphertext = cipher.update(Buffer.from(str));
            //     let  encrypted = Buffer.concat([iv, ciphertext, cipher.final()]).toString('base64');
            //     return `${encrypted}.${key}`;
            // }

            const card = {
                number:cyberSourcePaymentData.cardNumber,
                securityCode:cyberSourcePaymentData.CVV,
                expirationMonth: cyberSourcePaymentData.expiryDate.split('-')[1],
                expirationYear: cyberSourcePaymentData.expiryDate.split('-')[0],
                name: cyberSourcePaymentData.cardHolderName
          }

        //   var CryptoJS = require("crypto-js");
        //   var data = [{id: 1}, {id: 2}]
          let enc_data = CryptoJS.AES.encrypt(JSON.stringify(card), key).toString();
          // Decryptvar bytes  = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));console.log(decryptedData); 

            // const enc_data= CryptoJS.HmacSHA256(card, key)
            // console.log(`${CryptoJS.enc.Base64.stringify(enc_data)}.${key}`);
            // console.log(`${enc_data}.${key}`);
            try{
                const res = Axios.post( '/payment/cybersource' , {
                    finalAmount:finalTotal.toString(),
                    address:  saveCard || selectedAddress.id ? null : { ...formData },
                    addressId: selectedAddress.id ? selectedAddress.id : saveCard ? addressId : null ,
                    packageId: state,
                    couponId:couponData?.id,
                    saveCard: saveCard,
                    imagePayment: imagesCost ? true : false,
                    token: selectedPaymentDetails ? null : `${enc_data}.${key}`,
                    tokenId: selectedPaymentDetails ? selectedPaymentDetails.id : null,
                } , { headers : { accessToken: localStorage.getItem('AccessToken')}});
                console.log(res);
            }catch(err){
                console.log(err)
            }
            }
            }
        setIsLoading(false);
    }
    };

    // try{
    //     if(!selectedAddress && Object.values(formData).includes('') ){
    //         toast('Please enter complete address or Select address', { type : 'error' });
    //         setIsLoading(false);
    //         return
    //     }else{
    //         if(!selectedAddress && saveCard){
    //             try{
    //                 const newAddress = await Axios.post('/customers/address' ,{...formData}, { headers: { accessToken: localStorage.getItem('AccessToken')}});
    //             }catch(e){
    //                 toast(e.response.data.data, { type : 'error' });
    //                 setIsLoading(false);
    //                 return
    //             }
    //         }
    //     }
    //     if(cyberSourcePaymentData.id){
    //         if(!cvc){
    //             toast('Please enter CVV' , { type: 'error'});
    //             setIsLoading(false);
    //             return
    //         }
    //     }
    //     else{
    //         if(Object.values(cyberSourcePaymentData).includes('')){
    //             toast('Please enter complete card details' , { type: 'error'});
    //             setIsLoading(false);
    //             return
    //         }
    //     }

    //     console.log(cyberSourcePaymentData)

    //     const messageBody = {
    //     "paymentInformation": {
    //         "card": {
    //             "expirationYear": cyberSourcePaymentData.exp_year ,
    //             "number": cyberSourcePaymentData.number,
    //             "securityCode": cvc,
    //             "expirationMonth": cyberSourcePaymentData.exp_month,
    //             "type": "002"
    //         }
    //     }}
    //     console.log(messageBody)

    //     // try{

    //     //     const res = await axios.post( 'https://api.cybersource.com/pts/v2/payments' , {
    //     //             "v-c-merchant-id": 'merchant123',
    //     //             "Host": 'api.cybersource.com',
    //     //             "Date": new Date().toString(),
    //     //             "Digest": 'SHA-256=bena9bhB3Jy4uPvfu1tAC0uN8AuzzM+xjqmDwR5//EA=',
    //     //             "Signature": { keyid: "b1a96308-9edf-4541-aed0-1e6d84e2164a7" },
    //     //             "algorithm": "SHA256withRSA", 
    //     //             "headers": "host date (request-target)digest v-c-merchant-id",
    //     //             "signature": ''
    //     //     })
    //     //     console.log(res);
    //     // } catch (error) {
            
    //     // }
    //     setIsLoading(false);
    // }catch(e){
    //     setIsLoading(false);
    //     toast( 'Payment Failed!, Please try after some time!', { type:'error' });
    // };

  return (
    <div className='addressAndPaymentPage-container'>
        <div className='checkout-calc-heading-subheading'>
            <div className='shipping-carrier-heading'>Select your Address & Payment Method</div>
            <div className='checkout-calc-subheading gray-text'>Dashboard → Checkout Shipping Calculator → Review Order → Payment Method</div>
        </div>
        
        <div className='payment-method-page-container'>
            <div className='address-and-order-summary-container'>
                {
                    paymentOptions.card && paymentCards.length > 0 &&
                    (
                        <div className='payment-address-payments-container'>
                            <div className='payment-method-addresses-container card'>
                                <div className='secondary-bold-text'>Saved Payment Method</div>
                                <div className='payment-cards-container'> 
                                    {
                                        paymentCards.map( cardDetail =>  <CyberSourcePaymentCard key={cardDetail.id} cardDetail={cardDetail} setCard={setCard} setCvv={setCvc} cvv={cvc} />
                                        )
                                    }   
                                </div>
                            </div>
                            <div className='payment-payment-cards-container card'>
                                <div className='secondary-bold-text'>Saved Billing Address</div>
                                <div className='payment-addresses-container'> 
                                    {
                                        addresses.map( address => <div key={address.id} className={address.selected ? 'selected-profile-address-container cursor' : 'profile-address-container card cursor'} ><AddressCard address={address} setAsDefaultAddress={setAsDefaultAddress} features={false} defaultFeature={false}  /></div>)
                                    }   
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    !paymentOptions.card &&
                    (
                        <div className='payment-address-payments-container card'>
                            <div className='payment-options'>
                                <div className={paymentOptions.card ? 'selected-payment-option cursor' : 'payment-option cursor'} onClick={() => setPaymentOptions({ card: true , amazonpay: false, paypal: false })}><img src='/images/payment-card.svg' alt='payment-method-img'/><div className='correct-icon-bg'><span className="material-symbols-outlined payment-selected-icon">done</span></div></div>
                                <div className={paymentOptions.paypal ? 'selected-payment-option cursor' : 'payment-option cursor'} onClick={() => setPaymentOptions({ card: false , amazonpay: false, paypal: true })}><img src='/images/paypal.svg' alt='payment-method-img'/><div className='correct-icon-bg'><span className="material-symbols-outlined payment-selected-icon">done</span></div></div>
                            </div>
                            <div className='third-party-payments-container'>
                                <div className='img-wallet-money'>
                                    <img src={ paymentOptions.amazonpay ? '/images/amazonpay.svg' : '/images/paypal.svg' } alt='payment-gateway-img' />
                                    <div className='wallet-money'>Wallet: ${'49.88'}</div>
                                </div>
                                <Form className='amazon-payment-form'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Enter your email or mobile number to get started.</Form.Label>
                                        <Form.Control type="email" 
                                        className='form-input'
                                        placeholder="Enter email or mobile number" />
                                    </Form.Group>
                                    <div className='forgot-text'>Forgot email?</div>
                                    <input type='submit' value='Login and Pay >' className='amazonpay-submit-button' />
                                </Form>
                            </div>                                                       
                        </div>
                    )
                }
                <OrderSummaryCard imagesCost=
                {imagesCost} proceedForPayment={proceedForPayment} total={total} selectedSplitData={selectedSplitData} setFinalTotal={setFinalTotal}  finalTotal={finalTotal} setCouponData={setCouponData} couponData={couponData} isLoading={isLoading} totalOrder={totalOrder} setTotalOrder={setTotalOrder} setGrossAmount={setGrossAmount} />
            </div>
            {
                paymentOptions.card &&
                (
                    <div className='address-and-order-summary-container'>
                        <div className='payment-address-payments-container card'>
                        <div className='payment-options'>
                            <div className={paymentOptions.card ? 'selected-payment-option cursor' : 'payment-option cursor'} onClick={() => setPaymentOptions({ card: true , amazonpay: false, paypal: false })}><img src='/images/payment-card.svg' alt='payment-method-img'/><div className='correct-icon-bg'><span className="material-symbols-outlined payment-selected-icon">done</span></div></div>
                            <div className={paymentOptions.paypal ? 'selected-payment-option cursor' : 'payment-option cursor'} onClick={() => setPaymentOptions({ card: false , amazonpay: false, paypal: true })}><img src='/images/paypal.svg' alt='payment-method-img'/><div className='correct-icon-bg'><span className="material-symbols-outlined payment-selected-icon">done</span></div></div>
                        </div>
                            { 
                                paymentOptions.card && 
                                <PaymentInfoBillingAddressForm cyberSourcePaymentData={cyberSourcePaymentData} setCyberSourcePaymentData={setCyberSourcePaymentData} cybersource={true} formData={formData} setFormData={setFormData} saveCard={saveCard} setSaveCard={setSaveCard} />
                            }                                                    
                        </div>
                        <div className='order-summary-container temp-card'></div>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default CyberSourcePaymentAndAddressComponent