import React from 'react';
import './Footer.css';
import { useSelector } from 'react-redux';

const Footer = () => {
  const { settings } = useSelector( state => state.userData );

  return (
    <div className='footer-container'>
      <div className='footer-links'>
      {/* <div>
          <img src='/images/whatsup.svg' alt='whatsup' className='img-phone'/>
          <a className='footer-link' href={`https://wa.me/+${settings.supportPhoneNumber}`}> Whatsapp</a>
        </div>
        <div>
          <img src='/images/f-phone.svg' alt='supportPhone' className='img-phone'/>
          <a className='footer-link' href={`tel:+${settings?.supportPhoneNumber || ''}`}> +{ settings.supportPhoneNumber }</a>
        </div> */}
        <div>
          <img src='/images/f-mail.svg' alt='supportEmail' />
        <a className='footer-link' href={`mailto: ${settings?.supportEmail || ''}`}> { settings.supportEmail }</a>
        </div>
        {/* <div>
        <a className='footer-link' href={`https://${settings.domainName}`}> All Rights Reserved, { new Date().getFullYear() }</a>
        © <img src='/images/footerCompanyName.svg' alt='normal-text' />
          <a className='footer-link' href={`https://${settings.domainName}`}>{ settings.companyName }</a>
        </div> */}

         <div>
        <a className='footer-link' href={`https://${settings.domainName}`}> All Rights Reserved, { new Date().getFullYear() } <span>©</span> { settings.companyName }</a>
        </div>
        
      </div>
    </div>
  )
}

export default Footer                 