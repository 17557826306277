import Accordion from 'react-bootstrap/Accordion';
import './AccordianForPackage.css';
import { useState , useEffect } from 'react';
import { Axios } from '../../../hooks';
import { ImagesSliderModal, RequestPhotosModal } from '../../../Modals';
import { useNavigate } from 'react-router-dom';
import { httpStatus } from '../../../utils';

function AccordianForPackage({ packageData, showId, isShipped, photoRequestCost }) {
  const [ packageContent , setPackageContent ] = useState([]);
  const [ dimensions , setDimensions ] = useState({length:'', width:'' , height: ''});
  const [ packageImages , setPackageImages ] = useState([]);
  const [ openRequestPhotosModal , setOpenRequestPhotosModal ] = useState(false);
  const [ openImagesModal , setOpenImagesModal ] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPackageContent(JSON.parse(packageData.packageContent));
    setDimensions(JSON.parse(packageData.dimensions));
    (async() => {
      try{
        const res = await Axios.get(`/packages/images/${packageData.id}?consolidate=true`, { headers: { accessToken: localStorage.getItem('AccessToken')}});
        res.data?.data[packageData.id].length > 0
        ? setPackageImages({ images: JSON.parse(res.data?.data[packageData.id]?.[0]?.images) , verified: res.data?.data[packageData.id]?.[0]?.verified })
        : setPackageImages({ images: [] , verified: 0});
      }catch(err){
        httpStatus(err)
      }
    })();
  },[packageData]);

  const imgPaymentProcess = async() => {
    try{ 
      const res = await Axios.get(`/customers/imagecost` , { headers : { accessToken: localStorage.getItem('AccessToken')}});
      navigate('/dashboard/payment-method' , { state: { imagesCost: res.data.data[0]?.imagesCost , state: [ packageData.id ] }});
    }catch(err){
      httpStatus(err)
    }
  }
  
  return (
    <Accordion defaultActiveKey={0} key={packageData.id}>
      <Accordion.Item>
        <Accordion.Header>
            <div className='accordian-for-package-header-container'>
                { showId && (<div className='accordian-header-package-id'>{packageData.incomingTrack}</div>)}
                <div className='accordian-header-package-name'>Package ID: {packageData.id}</div>
                <div className='accordian-header-package-name'>{packageData.incomingTrack}</div>
            </div>
        </Accordion.Header>
        <Accordion.Body>
            <div className='accordian-body-container'>
                { packageContent.length > 0 && (<>
                <div className='package-content-card'>Package Content</div>
                <div className='package-table-headings'>
                    <div className='package-table-heading flex04 text-center'>Name</div>
                    <div className='package-table-heading flex04 text-center'>Quantity</div>
                    <div className='package-table-heading flex04 text-center'>Unit Cost</div>
                    <div className='package-table-heading flex04 text-center'>Total</div>
                </div>
                {packageContent.length > 0 && 
                        packageContent.map(c=>
                          <div className='package-table-headings' key={c.id}>
                          <div className='package-table-data flex04 text-center'>{c.name}</div>
                          <div className='package-table-data flex04 text-center'>{c.quantity}</div>
                          <div className='package-table-data flex04 text-center'>{c.unitCost}</div>
                          <div className='package-table-data flex04 text-center'>{c.quantity*c.unitCost}</div>
                        </div>)}
                </>)
                }
                <div className='package-dimensions'>
                   <div className='package-content-card-heading'>Dimension</div>
                   <div className='package-dimension'> {dimensions.length}*{dimensions.width}*{dimensions.height} in | {packageData.weight}lb </div>
                </div>
                <div className='package-imgs'>
                    <div className='package-content-card-heading'>Package Images</div>
                    <div className='package-content-package-images'>
                        {
                            packageImages?.images?.length > 0
                            ? packageImages.images.map( packageImg => <img title={ packageImages.verified ? 'Click here to enlarge the package image.' : 'Click here to pay for package images.' } onClick={() => packageImages.verified ? setOpenImagesModal(true) : imgPaymentProcess() } key={packageImg} src={ packageImages.verified ? packageImg : '/images/default-pack-img.jpg' } alt='package-img' className='package-detail-package-img' />)
                            : 'No Images'
                        }
                    </div>
                  </div>
                {
                  !isShipped && packageImages.verified === 1 &&
                  (
                    <div className='request-photos-button cursor' onClick={() => setOpenRequestPhotosModal(true)}>
                      <img src='/images/requestImages.svg' alt='request-photos-img' />
                      <div className='request-photo-button-text'>Custom Photos for Package ${photoRequestCost}</div>
                    </div>
                  )
                }
            </div>
        </Accordion.Body>
      </Accordion.Item>
      <ImagesSliderModal setIsOpenImages={setOpenImagesModal} isOpen={openImagesModal} images={ packageImages.images?.map( element => ({ source: element }))} />
      <RequestPhotosModal show={openRequestPhotosModal} setShow={setOpenRequestPhotosModal} selectedPackage={packageData} />
    </Accordion>
  );
}

export default AccordianForPackage;