import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ShippingCarriersList , toast } from '../../components';
import { Axios } from '../../hooks';
import { AddAddressModal } from '../../Modals';
import { httpStatus } from '../../utils';
import './CheckoutShippingCalculatorPage.css';
import { hotjar } from 'react-hotjar';
import { getAlignedAddressWithBrs } from '../../global';

const CheckoutShippingCalculatorPage = () => {
  const  location  = useLocation();
  const { packagesToCheckout , isMultiplePakage} = location.state;
  const [ carriers , setCarriers ] = useState([]);
  const [ isLoading , setIsLoading ] = useState(false);
  const [ selectedAddress , setSelectedAddress ] = useState({});
  const [ allPackageExtras , setAllPackageExtras ] = useState([]);
  const [ addresses , setAddresses ] = useState([]);
  const [ packages , setPackages ] = useState([]);
  const [ state , setState ] = useState({});
  const [ packagesDetails , setPackagesDetails ] = useState({});
  const [ alreadySelectedCustomization , setAlreadySelectedCustomization ] = useState([]);
  const [ dimensions , setDimensions ] = useState('');
  const [ name , setName ] = useState('');
  const [ userAddresses , setUserAddresses ] = useState([]);
  const [ showAddressModal, setShowAddressModal ] = useState(false);
  const [ dataToEdit , setDataToEdit ] = useState({});
  const [ refreshAddress , setRefreshAddress ] = useState(false);

  const editAddressModal = (address) => {
    setDataToEdit(address);
    setShowAddressModal(true);
 }
 
 useEffect(() => {
  hotjar.initialize(3576088, 6);
}, []);

  useEffect(() => {
    if(packagesToCheckout.length){
    setPackages(packagesToCheckout.map( p => packagesToCheckout.indexOf(p) === 0 ? ({ ...p , selected: true }) : ({...p , selected: false}) ));
    setState(packagesToCheckout[0]);
  }
  }, [ packagesToCheckout ]);

  useEffect(() => {   
    if(Object.keys(state).length > 0 && packagesDetails ){
      if(state.dimensions){
        let dimension = JSON.parse(state.dimensions);
        dimension = `${dimension.length}*${dimension.width}*${dimension.height} in`;
        setDimensions(dimension)
      }
      if(state.packageContent){
        const packageContents = state.packageContent;
        setName(packageContents.map( element => element.name).join(','));
      }
      (async() => {
        try{
          const packageExtrasres = await Axios.get(`/packages/extras/${state.id}?consolidate=${state.consolidate ? true : false}` , { headers : { accessToken: localStorage.getItem('AccessToken')}});
          setAlreadySelectedCustomization(packageExtrasres.data.data[state.id].map( extras => extras.extrasId));
          if(packagesDetails[state.id] ){
            if(packagesDetails[state.id].selectedExtras){
              setTimeout(() => {
                setAllPackageExtras( prev => prev.map( prevExtra => (packageExtrasres.data.data[state.id].concat(packagesDetails[state.id].selectedExtras.map( extra => ({ extrasId: extra})))).map( extras => extras.extrasId).includes(prevExtra.id) ? { ...prevExtra , selected: true } : { ...prevExtra , selected: false }));
              },500);
            }
          }else{
            setTimeout(() => {
              setAllPackageExtras( prev => prev.map( prevExtra => packageExtrasres.data.data[state.id].map( extras => extras.extrasId).includes(prevExtra.id) ? { ...prevExtra , selected: true } : { ...prevExtra , selected: false }));
            },500);
          }
        }catch(err){
          httpStatus(err);
        }
      })();
    }
  },[ state , packagesDetails ]);

  useEffect(() => {
    if(Object.keys(selectedAddress).length > 0 && Object.keys(state).length > 0 ){
      if(!packagesDetails[state.id]?.carriers?.length) {
      setPackagesDetails( prev => ({...prev , [state.id] : { ...packagesDetails[state.id], carriers: [] }}));
      setIsLoading(true);
      (async() => {
        try{
          const carriersRes = await Axios.get(`/customers/shippingCost?packageId=${state.id}&addressId=${selectedAddress.id}` , { headers : { accessToken: localStorage.getItem('AccessToken')}});
          if(!carriersRes.data.shippingCost.errors){
            if (!carriersRes.data.shippingCost.length) toast('Shipping Service is not Available for Selected Addrees!' , { type : 'error' });
            if(carriersRes.data.shippingCost.map( carrier => carrier.amount).includes('NaN')){
              toast( 'Shipping Service is not Available for Selected Addrees!' , { type : 'error' });
            }else{
              setCarriers(carriersRes.data.shippingCost);
              setPackagesDetails( prev => ({...prev , [state.id] : { ...packagesDetails[state.id], carriers: carriersRes.data.shippingCost }}));
            }
          }else{
            toast( carriersRes.data.shippingCost.errors[0]?.message , { type : 'error' });
          }
          setIsLoading(false);
        }catch(err){
          httpStatus(err);
          setIsLoading(false);
        }
      })();
    }else{
      setCarriers([packagesDetails[state.id]?.carriers])
    }
  }
  },[ selectedAddress , state ]);

  useEffect(() => {
    (async() => {
        try{
          const allCustomizations = await Axios.get(`/packages/extras`, { headers: { accessToken: localStorage.getItem('AccessToken')}});
          setAllPackageExtras(allCustomizations.data.data.filter( customization => customization.name !== 'Custom Photos Request' && customization.name !== 'Consolidation Separation'));
        }catch(err){
          toast( err.response.data.error , { type : 'error' });
        }
    })();
  },[]);
  
  useEffect(() => {
      (async() => {
        try{
          const addresses = await Axios.get(`/customers/addresses` , { headers : { accessToken: localStorage.getItem('AccessToken')}});
          setUserAddresses(addresses.data.data)
        }catch(err){
          httpStatus(err);
        }
      })();
  },[ refreshAddress ]);

  useEffect(() => {
    setAddresses(userAddresses);
  },[ userAddresses ]);

  const selectedShippingCarrier = (carrier) => {
    setCarriers( prev => prev.map( prevCarrier => prevCarrier.id === carrier.id ? { ...prevCarrier, selected: true } : { ...prevCarrier, selected: false } ));
    setPackagesDetails( prev => ({...prev , [state.id] : {...packagesDetails[state.id], selectedCarrier: carrier }}));
  }

  const selectedPackageExtras = (extra,state) => {
    if(state){
      if(alreadySelectedCustomization.includes(extra.id)){
        toast( "You Can't Remove This Customization!" , { type : 'info' });
      }else{
        if(Object.keys(packagesDetails).length && packagesDetails[state.id]?.selectedExtras){
          if( packagesDetails[state.id].selectedExtras && packagesDetails[state.id].selectedExtras.includes(extra.id)){
            setPackagesDetails( prev => ({...prev , [state.id] : {...packagesDetails[state.id],
              selectedExtras: packagesDetails[state.id].selectedExtras.filter( prevExtra => prevExtra !== extra.id)}}));
              setAllPackageExtras(prev => prev.map( prevExtra => prevExtra.id === extra.id ? {...prevExtra , selected: false } : prevExtra ));
          }else{
            setPackagesDetails( prev => ({...prev ,[state.id] : {...packagesDetails[state.id],
              selectedExtras: [ ...packagesDetails[state.id].selectedExtras ,extra.id ]  }}));
              setAllPackageExtras(prev => prev.map( prevExtra => prevExtra?.id === extra.id ? { ...prevExtra , selected: true } : prevExtra ));
          }
        }else{
            setPackagesDetails( prev => ({...prev ,[state.id] : {...packagesDetails[state.id], selectedExtras : [ extra.id ]}}))
            setAllPackageExtras(prev => prev.map( prevExtra => prevExtra.id === extra.id ? { ...prevExtra , selected: true } : prevExtra ));
        }
        
      }
  }
}

  const setAsDefaultAddress = (address) => {
    if(packagesDetails[state.id]?.selectedAddress){
      if(packagesDetails[state.id]?.selectedAddress !== address.id)
      setPackagesDetails( prev => ({...prev , [state.id] : { ...packagesDetails[state.id] ,selectedAddress:address.id,carriers:[],selectedCarrier:null}}));
      setSelectedAddress(address)
    }else{
    setSelectedAddress(address)
    packages.map(state=>{
    setAddresses( prev => prev.map( prevAddress => prevAddress.id === address.id ? { ...prevAddress, selected: true } : { ...prevAddress, selected: false } ));
    setPackagesDetails( prev => ({...prev , [state.id] : { ...packagesDetails[state.id] ,selectedAddress:address.id}}))});
    }
  }

  const selectPackageForCehckout = ( checkoutPackage ) => {
    setState(checkoutPackage);
    setPackages( prev => prev.map( p => p.id === checkoutPackage.id ? {...p , selected: true } : { ...p , selected: false }));
    // setAddresses( prev => prev.map( prevAddress => ({ ...prevAddress, selected: false }) ));
    // setSelectedAddress({})
    setCarriers([])
  }

  const getSelectedExtras = () => {
    return allPackageExtras.filter( ele => alreadySelectedCustomization.includes(ele.id));
  }

  return (
    <div className='checkout-shi-cal-page-container'>
      <section className='checkout-left-section'>
        <div className='checkout-calc-heading-subheading'>
          <div className='shipping-carrier-heading'>Checkout Shipping Calculator</div>
          <div className='checkout-calc-subheading gray-text'>Dashboard → Checkout Shipping Calculator</div>
        </div>
        <div className='secondary-bold-text display-in-row'>Package ID: 
        { 
        isMultiplePakage 
        ? <div className='multiple-packages-ids'>
           { packages.map( p => 
              <div key={p.id} className={p.selected ? 'package-id selected-package-id cursor referral-input-button' : 'package-id cursor' } onClick={() => selectPackageForCehckout(p)}>{p.id}</div>)
           }
          </div> 
        : state.id 
        }
        </div>
        <div className='package-content-address-cards'>
          <div className='check-shi-calc-pack-content-card card'>
            <div className='card-package-id-name-container'>
              <div className='primary-bold-text'>ID: {state?.id}</div>
              <div className='gray-text'>{state?.consolidate ? 'Combined Package' : 'Single Package'}</div>
            </div>
            { name &&
            <div className='card-package-content-name'>
            <div className='primary-bold-text'>Package Name</div>
            <div className='gray-text'>{name}</div>
          </div>}
            <div className='primary-bold-text'>Order Summary</div>
            <div className='card-package-dimensions'>{dimensions}<br /> {state.weight}{' '}lbs</div>
          </div>
          <div className='check-shi-calc-address-card card'>
            <div className='checkout-addresses-container'>
            {
              addresses.length > 0 ? addresses.map( address => (
                <div className={packagesDetails && packagesDetails[state.id] &&  address.id===packagesDetails[state.id].selectedAddress ? 'selected-address-card-container cursor' : 'address-card-container cursor'} key={address.id}>
                  <div className='address-name-edit-delete-buttons'>
                    <h1 className='address-fname-lname'>{address.firstName}{' '}{address.lastName}</h1>
                    <span className={`material-symbols-outlined`} onClick={() => editAddressModal(address)}>edit_square</span>
                  </div>
                  <div className='main-address' onClick={() => setAsDefaultAddress(address)}>
                    { getAlignedAddressWithBrs(address)  || " " }
                  </div>
                </div>
              )) : 'No Addresses'
            }
          </div>
          </div>
        </div>
        <div className='chekputcal-all-package-extras'>
          {
            getSelectedExtras().map( extra => 
                (
                  <div className={ extra.selected ? 'selected-extra extra cursor' : 'extra cursor'} key={extra.id} onClick={() => selectedPackageExtras(extra,state)}>
                    <div className='extra-price primary-bold-big-text'>${extra.cost}</div>
                    <div className='extra-name'>{extra.name}</div>
                </div>
                )
            )
          }
          {allPackageExtras.length % 2 !== 0 && (<div className='extra border-none'></div>)}
        </div>
      </section>
      <section className='checkout-right-section'><ShippingCarriersList packagesToCheckout={packagesToCheckout.map( el => el.id )} packagesDetails={packagesDetails} isLoading={isLoading} carriers={carriers.sort((a , b) => a.amount - b.amount )} state={state} selectedShippingCarrier={selectedShippingCarrier} isShippingCalcPage={false} />
      </section>
      <AddAddressModal show={showAddressModal} setShow={setShowAddressModal} dataToEdit={dataToEdit} setRefreshAddress={setRefreshAddress} refreshAddress={refreshAddress} checkoutRestrict={true} isDataToEdit={true}/>
    </div>
  )
}

export { CheckoutShippingCalculatorPage }