import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddressAndPaymentComponent from '../../components/AddressAndPaymentComponent/AddressAndPaymentComponent';
import { useSelector } from 'react-redux';
import { CyberSourcePaymentAndAddressComponent } from '../../components';

const AddressAndPaymentPage = () => {
const { settings } = useSelector( state => state.userData );
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_TEST}`);

  return (
    settings.paymentService === 'Stripe'
    ? <Elements stripe={stripePromise}>
        <AddressAndPaymentComponent />
      </Elements>
    : <CyberSourcePaymentAndAddressComponent />
  )
}

export default AddressAndPaymentPage