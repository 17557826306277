import React from 'react';
import Carousel from 'react-images';
import './AddAddressModal.css';

const ImagesSliderModal = ({ isOpen , images, setIsOpenImages }) => {

  window.addEventListener('click' , (e) => {
    if(e.target.className === 'slider-container'){
      setIsOpenImages(false);
    }
  });

  return (
    <>{ images && isOpen ?
    <div className='slider-container'>
       {images&&<div className='slider-card'><Carousel views={images} /></div>}
    </div>
    : ''}</>
  )
}

export default ImagesSliderModal