import { uploadFile } from "react-s3";
import { toast } from "react-toastify";
import { convertDataToBinary } from "../utils";
window.Buffer = window.Buffer || require("buffer").Buffer;
const { v7: uuidv7 } = require('uuid');

export const useUploadImage = () => {

    const uploadImage = async(data) => {
        const config = {
            bucketName: process.env.REACT_APP_S3_BUCKET,
            dirName: data.clientId,
            region: "us-east-1",
            accessKeyId: process.env.REACT_APP_ACCESS,
            secretAccessKey: process.env.REACT_APP_SECRET,
            s3Url: `s3://${process.env.REACT_APP_S3_BUCKET}/${data.clientId}/`
        };
        let convertToFile = null
        const uuid7 = uuidv7()
        if (data.type) {
            convertToFile = data.image;
            config.dirName = `${data.clientId}/${data.type}/${uuid7}`
        }
        else {
            const binaryData = await convertDataToBinary(data.image);
            const fetchURL = await fetch(binaryData);
            const blobImg = await fetchURL.blob();
            convertToFile = new File([blobImg], `profile/${uuid7}`, { type: binaryData.slice(5, -1).split(';')[0] });
        }

        return uploadFile(convertToFile, config)
            .then((data2) => data2.location)
            .catch((e) => toast(e.message, { type: 'error' }));

    }

    return { uploadImage }

}