import React , { useState } from 'react';
import { CloseButton, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Axios } from '../hooks';
import { httpStatus } from '../utils';

const RequestPhotosModal = ({ selectedPackage, show , setShow, setSelectedPackage, allPackages , setPackages }) => {
    const [ requestMsg , setRequestMsg ] = useState('');

    window.addEventListener('click' , (e) => {
      if(e.target.className === 'fade modal show'){
        setShow(false);
      }
   });

   const requestPhotos = async() => {
    if(requestMsg !== ''){
      try{
        await Axios.put(`/packages/extras/photosrequest/${selectedPackage.id}`, { text: requestMsg }, { headers: { accessToken: localStorage.getItem('AccessToken')}});
        setRequestMsg('');
        toast( 'Request Sent!' , { type : 'success' });
        // setPackages(allPackages.map( element => ({ ...element , selected: false })));
        setShow(!show);
        // setSelectedPackage({});
      }catch(err){
        httpStatus(err);
      }
    }else{
      toast( 'Please Enter Some Message!' , { type : 'info' });
    }
   }

  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} centered>
        <div className='modal-container'>
          <div className='modal-heading'>
            <div className='modal-heading-name'></div>
            <div className='modal-close-button' onClick={() => setShow(false)}><CloseButton /></div>
          </div>
          <div className='request-photo-container'>
              <img src='/images/requestPhotos.svg' alt='request-photos-img' className='photos-request-modal-image' />
              <div className='img-black-bold-heading'>Request Photo <br /> of your package </div>
            </div>
            <FloatingLabel controlId="floatingTextarea2" label="Write a message...">
              <Form.Control
                as="textarea"
                placeholder="Write a message..."
                style={{ height: '15rem' }}
                className='photos-modal-textarea'
                onChange={(e) => setRequestMsg(e.target.value)}
                />
            </FloatingLabel>
          <div className='secondary-white-button send-request-button cursor' onClick={() => requestPhotos()}>Request Send <span className="material-symbols-outlined">chevron_right</span></div>
        </div>
    </Modal>
  )
}

export default RequestPhotosModal