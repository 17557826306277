import React from 'react';
import { Accordion } from 'react-bootstrap';
import AccordianForSummaryPackage from '../AccordianForSummaryPackage/AccordianForSummaryPackage';
import './ReviewOrderInvoice.css'

const ReviewOrderInvoice = ({ isMultiple ,shipmentCost , extras, id , reviewOrderDetail }) => {
  return (
    <Accordion alwaysOpen={isMultiple} >
      <Accordion.Item>
        <Accordion.Header>
            <div className='primary-bold-text'>ID: {id}</div>
        </Accordion.Header>
        <Accordion.Body>
            <div className='invoice-packages'>
              {
                reviewOrderDetail.package.consolidate === 1 
                ? reviewOrderDetail.package.consolidatedPackageId.map( consolidatedPackage => <AccordianForSummaryPackage id={id} overTimeCost={reviewOrderDetail.overTimeCost[consolidatedPackage.id]} extras={reviewOrderDetail.extras[consolidatedPackage.id]} packageData={consolidatedPackage} packageContent={reviewOrderDetail.packageContent}  showId={true} key={consolidatedPackage.id} />)
                : <AccordianForSummaryPackage id={id} extras={extras}  packageData={reviewOrderDetail.package} packageContent={reviewOrderDetail.packageContent}  showId={true}  />
              }
            </div>
            <div className='extra-features'>
              {
                reviewOrderDetail.package.consolidate === 1  && reviewOrderDetail.extras[id].map( packageExtra => (
                  <div className='order-extras-details' key={packageExtra.id}>
                    <div className='order-extras-name'>{packageExtra.name}</div>
                    <div className='order-extras-value'>${packageExtra.cost}</div>
                  </div>
                ))
              }
              {reviewOrderDetail.overTimeCost && reviewOrderDetail.overTimeCost[reviewOrderDetail.package.id]  && reviewOrderDetail.overTimeCost[reviewOrderDetail.package.id] > 0  &&
                <div className='order-extras-details' key={reviewOrderDetail.overTimeCost[reviewOrderDetail.package.id]}>
                <div className='order-extras-name'>Over Time Cost</div>
                <div className='order-extras-value'>${reviewOrderDetail.overTimeCost[reviewOrderDetail.package.id]}</div>
              </div>
              }
              <div className='order-extras-details' key={shipmentCost}>
                    <div className='order-extras-name'>Shipment Cost</div>
                    <div className='order-extras-value'>${shipmentCost}</div>
              </div>
            </div>
            <div className='invoice-total-amount'>Total: <span>${reviewOrderDetail.package.totalCost}</span></div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default ReviewOrderInvoice