import Accordion from 'react-bootstrap/Accordion';
import './AccordianForShippedPackage.css';

function AccordianForShippedPackage({ packageData, showId ,overtimeCost, extras , revertCost , packageContent }) {
  return (
    <Accordion defaultActiveKey={0} key={packageData.id}>
      <Accordion.Item>
        <Accordion.Header>
            <div className='accordian-for-package-header-container'>
                { showId && (<div className='accordian-header-package-id'>Package ID: {packageData.id}</div>)}
                <div className='accordian-header-package-name'>{packageData.incomingTrack}</div>
            </div>
        </Accordion.Header>
        <Accordion.Body>
            <div className='accordian-body-container'>
              <div className='package-table-contents'>
                {
                  extras && extras.map( extra => 
                    <div className='package-table-content' key={extra.id}>
                      <div className='package-table-data'>{extra.name}</div>
                      <div className='package-table-data flex04 text-center'>1</div>
                      <div className='package-table-data flex01'>${extra.cost}</div>
                    </div>
                  )
                }
                {
                  overtimeCost > 0 &&
                  <div className='package-table-content'>
                      <div className='package-table-data'>Over Time Cost</div>
                      <div className='package-table-data flex04 text-center'></div>
                      <div className='package-table-data flex01'>${overtimeCost}</div>
                  </div>
                }
                {
                  revertCost > 0 && 
                  <div className='package-table-content'>
                      <div className='package-table-data'>Revert Cost</div>
                      <div className='package-table-data flex04 text-center'></div>
                      <div className='package-table-data flex01'>${revertCost}</div>
                  </div>
                }
              </div>
            </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordianForShippedPackage;