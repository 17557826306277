import Accordion from 'react-bootstrap/Accordion';
import './AccordianForSummaryPackage.css';
import {  useSelector } from 'react-redux';

function AccordianForSummaryPackage({ id , overTimeCost ,extras ,packageData, packageContent, showId }) {
  const { settings } = useSelector( state => state.userData );
  return (
    <Accordion defaultActiveKey={0} key={packageData.id}>
      <Accordion.Item>
        <Accordion.Header>
            <div className='accordian-for-package-header-container'>
                { showId && (<div className='accordian-header-package-id'>{packageData.incomingTrack}</div>)}
                <div className='accordian-header-package-name'>{packageContent && packageContent[packageData.id].map( obj => obj.name).join(',')}</div>
            </div>
        </Accordion.Header>
        <Accordion.Body>
            <div className='accordian-body-container'>
              <div className='package-table-contents'>
                {
                  extras && extras.map(extra => { if(extra.name==='Incomplete SuiteID'){
                    return <div className='package-table-content' key={extra.id}>
                      <div className='package-table-data'>{extra.name} <a className='incomplete-suite' href={settings?.incompleteSuite} target="_blank">(How to Fix)</a></div>
                      <div className='package-table-data flex10 text-center'></div>
                      <div className='package-table-data flex01'>${extra.cost}</div>
                    </div>
                    }else{
                    return <div className='package-table-content' key={extra.id}>
                    <div className='package-table-data'>{extra.name}</div>
                    <div className='package-table-data flex04 text-center'></div>
                    <div className='package-table-data flex01'>${extra.cost}</div>
                  </div>
                    }})
                }
                {
                  overTimeCost > 0 &&
                  <div className='package-table-content'>
                      <div className='package-table-data'>Over Time Cost</div>
                      <div className='package-table-data flex04 text-center'></div>
                      <div className='package-table-data flex01'>${overTimeCost}</div>
                  </div>
                }
                {/* {
                  revertCost && revertCost !==0 &&
                  <div className='package-table-content'>
                      <div className='package-table-data'>Revert Cost</div>
                      <div className='package-table-data flex04 text-center'></div>
                      <div className='package-table-data flex01'>${revertCost}</div>
                  </div>
                } */}
                {/* {
                  overWeightCost && overWeightCost !==0 &&
                  <div className='package-table-content'>
                      <div className='package-table-data'>Over Weight Cost</div>
                      <div className='package-table-data flex04 text-center'>1</div>
                      <div className='package-table-data flex01'>${overWeightCost}</div>
                  </div>
                } */}
              </div>
            </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordianForSummaryPackage;