import React , { useState , useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Axios, useInput } from '../../../hooks';
import { useUploadImage } from '../../../hooks/uploadImages';
import { refreshUserData, resetUserData } from '../../../store/userDetailsSlice';
import { httpStatus } from '../../../utils';
import './PersonalDetailsForm.css';

const PersonalDetailsForm = () => {
  const [validated, setValidated] = useState(false);
  const [ loading , setLoading ] = useState(false);
  const [ details , setDetails ] = useState({ firstName: '' , lastName: '' , email: '' , phoneNumber: '' , avatar: '' , dateOfBirth:'' });
  const { inputHandler } = useInput();
  const { userDetails } = useSelector( state => state.userData );
  const { uploadImage } = useUploadImage();
  const dispatch = useDispatch();

  const handleSubmit = async(event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
      if(details.avatar.type === undefined || ["image/jpeg", "image/png", "image/jpg"].includes(details.avatar.type)){
        try{
          setLoading(true);
          let imgUrl = null;
          if(details.avatar.name){
            imgUrl = await uploadImage({ clientId: userDetails?.prefixSuite.toLowerCase() , image: details.avatar});
          }
          await Axios.put(`customers/profile` , { ...details , dateOfBirth: details.dateOfBirth || null , avatar:  details.avatar.name ? imgUrl : details.avatar } , { headers : { accessToken : localStorage.getItem('AccessToken')}});
          toast( 'User Details Updated Successfully!' , { type : 'success' });
          if(imgUrl){
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          }else{
            dispatch(resetUserData());
            dispatch(refreshUserData())
            setLoading(false);
          }
        }catch(err){
          httpStatus(err)
          setLoading(false);
        }
      }else{
        setValidated(false);
        toast( 'Please Add Images in jpeg, jpg and png format.' , { type : 'info' });
      }
    }
    setValidated(true);
  };


  useEffect(() => {
    if(userDetails){
      setTimeout(() => setDetails({
        firstName: userDetails.firstName, 
        lastName:userDetails.lastName , 
        email: userDetails.email, 
        phoneNumber:userDetails.phoneNumber ? userDetails.phoneNumber : '' , 
        avatar: userDetails.avatar || '', 
        dateOfBirth:userDetails.dateOfBirth ? userDetails.dateOfBirth.slice(0,10) : ''
      }),0);
    }
  }, [ userDetails ]);



  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit} className='personal-details-form'>
      <div className='two-inputs'>
          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="First name"
              className='form-input'
              name='firstName'
              value={details.firstName}
              onChange={ (e) => inputHandler( e , setDetails )}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
          <Form.Label className='form-label'>Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last name"
            className='form-input'
            name='lastName'
            value={details.lastName}
            onChange={ (e) => inputHandler( e , setDetails )}
          />
          <Form.Control.Feedback type="invalid" className='feedback-text'>
            Please provide a valid state.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className='two-inputs'>
        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Email</Form.Label>
            <Form.Control
              required
              disabled={true}
              type="email"
              placeholder="email@companyname.com"
              className='form-input'
              name='email'
              value={details.email}
              onChange={ (e) => inputHandler( e , setDetails )}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
          <Form.Label className='form-label'>Phone</Form.Label>
          <Form.Control
            type="number"
            placeholder="9835254575"
            className='form-input'
            name='phoneNumber'
            value={details.phoneNumber}
            onChange={ (e) => inputHandler( e , setDetails )}
          />
          <Form.Control.Feedback type="invalid" className='feedback-text'>
            Please provide a valid Number.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className='two-inputs'>
        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Date Of Birth</Form.Label>
            <Form.Control
              type="date"
              placeholder="8/13/1997"
              className='form-input'
              name='dateOfBirth'
              value={details.dateOfBirth}
              onChange={ (e) => inputHandler( e , setDetails )}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide a valid DOB.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
          <Form.Label className='form-label'>Upload Image</Form.Label>
          <Form.Control
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            className='form-input'
            name='avatar'
            onChange={ (e) => setDetails(prev => ({...prev , avatar: e.target.files[0] }))}
          />
          <Form.Control.Feedback type="invalid" className='feedback-text'>
            Please provide a Image.
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <button type="submit" className={`personal-details-form-submit-button border-none ${loading && 'disabled'}`}>Save { loading && <Spinner size='sm' />}</button>
    </Form>
  )
}

export default PersonalDetailsForm