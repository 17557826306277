import React from 'react';
import { Col, Form } from 'react-bootstrap';

const CyberSourcePaymentCard = ({ cardDetail, setCard, cvv , setCvv }) => {

  return (
    <div className={cardDetail.selected ? 'selected-card-detail-section-com card cursor' : 'card-detail-section-com card cursor'} key={ cardDetail.id } onClick={() => setCard(cardDetail)}>
        <div className='card-name'>{cardDetail.name}</div>
        <div className='card-number'>XXXX-XXXX-XXXX-{cardDetail.last4}</div>
        <div className='card-expire-date'>Expires on: {cardDetail.exp_month}/{cardDetail.exp_year}</div>
        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>First name</Form.Label>
            <Form.Control
              required
              type="number"
              maxLength={3}
              placeholder="CVV"
              className='form-input'
              name='cvv'
              value={cvv}
              width={5}
              style={{ width: '6rem' }}
              onChange={ (e) => setCvv(e.target.value)}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>
        <div className='card-provider-img'><img src='/images/visa.svg' alt='card-provider-img' className='card-provider-img' /></div>
    </div>
  )
}

export default CyberSourcePaymentCard