export const InputChange = (e, setFunc) => {
    setFunc( prev => ({ ...prev, [e.target.name]: e.target.value }));
}

export const LoginInputs = [
    {
        id: 1,
        name: 'email',
        type: 'email',
        label: 'Email',
        errorMessage: 'It should be a valid email Address.',
        pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        placeholder: 'mail123@website.com',
        required: true,
    },
    {
        id: 2,
        name: 'password',
        type: 'password',
        label: 'Password',
        errorMessage: "Password is Required.",
        placeholder: 'password@1234',
        required: true,
    }
]

export const RegisterInputs = [
    {
        id: 1,
        name: 'firstName',
        type: 'text',
        label: 'First Name',
        errorMessage: 'First Name is Mandatory.',
        placeholder: 'Jhon',
        required: true,
    },
    {
        id: 2,
        name: 'lastName',
        type: 'text',
        label: 'LastName',
        errorMessage: "Last Name is Mandatory.",
        placeholder: 'Hanks',
        required: true,
    },
    {
        id: 3,
        name: 'email',
        type: 'email',
        label: 'Email',
        errorMessage: 'It should be a valid email Address.',
        pattern: `[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$`,
        placeholder: 'mail123@website.com',
        required: true,
    },
    {
        id: 4,
        name: 'password',
        type: 'password',
        label: 'Password',
        errorMessage: "Password must contain at least 8 characters including one lowercase, uppercase, number, and special character.",
        pattern: `^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\\.!@#$%^&*-_=+\\{\\}\\(\\)\\?:<>,~;"'\\\\\\/])[a-zA-Z0-9!@#$%^&*-_=+\\.\\{\\}\\(\\)\\?:<>,~;"'\\\\\\/]{8,20}$`,
        placeholder: 'Password',
        required: true,
    },
    {
        id: 5,
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm Password',
        errorMessage: "Passwords don't match!",
        placeholder: 'Confirm Password',
        required: true,
    },
    {
        id: 6,
        name: 'countryId',
        label: 'Country',
        errorMessage: "Select Country is Mandatory!",
        required: true,
    },
    {
        id: 7,
        name: 'referralCode',
        type: 'text',
        label: 'Referral Code',
        errorMessage: "",
        placeholder: 'eg: REFER10',
        required: false,
    }
]