import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Axios } from '../../hooks';
import { getTimeDifference, httpStatus, getNotificationImage } from '../../utils';
import './Notifications.css';
import { useSelector } from 'react-redux';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [updatedNotify, setUpdatedNotify] = useState(false)
  const { flagNotifications,userDetails } = useSelector(state => state.userData);

  useEffect(() => {
    (async () => {
      try {
        const res = await Axios.get(`/customers/notifications?full=true`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
        setNotifications(res.data.data);
      } catch (err) {
        httpStatus(err)
      }
    })();
  }, [updatedNotify, flagNotifications]);

  const updateNotify = async (id, saw, ticketId) => {
    if (saw !== 1) {
      try {
        await Axios.put(`/customers/notifications/${id}`, {}, { headers: { accessToken: localStorage.getItem('AccessToken') } });
        navigate('/support', { state: ticketId })
        setUpdatedNotify(updatedNotify ? false : true)
      } catch (e) {
        httpStatus(e)
      }
    }
    else {
      navigate('/support', { state: ticketId })
    }
  }

  const deleteNotification = async (id) => {
    try {
      await Axios.delete(`/customers/notifications/${id}`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
      setUpdatedNotify(updatedNotify ? false : true)
    } catch (e) {
      httpStatus(e)
    }
  }

  return (
    <>
      {
        window.innerWidth <= 992
          ? <div onClick={() => navigate('/notifications', { state: notifications })}></div>
          : <div className='notification-container'>
            {
              notifications.length > 0
                ? notifications.slice(0, 4).map(
                  ({ id, text, ticketId, notifyTime, saw, ticketType }) =>
                    <div className={saw === 1 ? 'seen-notification' : 'unseen-notification'} key={id}>
                      <div className='notification-content'>
                        <div className='notification-title-time'>
                          <div className='ntt-div' onClick={() => updateNotify(id, saw, ticketId)}>
                            <img src={getNotificationImage(ticketType)} alt='profile-img' className='noti-img' />
                            <div className='notification-title'><span>{text}</span>
                              <div className='notification-time'>
                                {getTimeDifference(userDetails.timeZone,notifyTime)}
                              </div>
                            </div>
                          </div>
                          <span onClick={() => deleteNotification(id)} className="material-symbols-outlined color-red">delete</span>
                        </div>
                      </div>
                    </div>
                )
                : <div className='notification' style={{ margin: '0 auto' }}>No Notifications</div>
            }
            <div className='clear-notification-button' onClick={() => navigate('/notifications', { state: notifications })}>View All Notifications</div>
          </div>
      }
    </>
  )
}

export default Notifications