import React, { useState , useEffect } from 'react';
import './CreateTicket.css'
import { useNavigate } from 'react-router-dom';
import { useSelector} from 'react-redux';
import { Axios,useInput,useUploadImage } from '../../hooks';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';
import {  httpStatus } from '../../utils';
import { toast } from '../../components/index';


const CreateTicket = () => {
  const { userDetails,ticketPackages } = useSelector((state) => state.userData);
  const [ticketTypes,setTicketTypes] = useState([])
  const { inputHandler} = useInput();
  const { uploadImage }= useUploadImage();
  const [ loading , setLoading ] = useState(false);
  const navigate = useNavigate();
  const [ allPackages,setAllPackages] = useState();
  const [ticketDetails,setTicketDetails] = useState({ticketType:'',incommingTrack:'',subject:'',packageId:null,message:'',files:null})

  useEffect(()=>{
    (async()=>{
      try{
        const ttypes = await Axios.get('/tickets/types', { headers: { accessToken: localStorage.getItem('AccessToken') } })
        setTicketTypes(ttypes.data.types)
        setAllPackages(ttypes.data.packages?.map(p=>p.packageId))
        setAllPackages(prev=>[...prev,'Package Missed'])
      }catch(e){
        httpStatus(e)
      }
    })()
},[])

const uploadFiles = async() => {
  let urls =[]
  for(let i=0;i<ticketDetails.files.length;i++){
    let imageUrl = await uploadImage({ image: ticketDetails.files[i], type:'tickets',clientId:userDetails?.prefixSuite.toLowerCase()});
    urls.push(imageUrl.replace(/\s/g,'+'))
  }
  return urls
};

const sunbmitForm = async (event) =>{
  event.preventDefault();
  if(ticketDetails.packageId==='Package Missed'){
    ticketDetails.packageId = null;
  }
  if(ticketPackages.includes(parseInt(ticketDetails.packageId))){
    toast('A ticket has already been opened for this package. Please proceed with processing that ticket.',{ type : 'info' })
    return 
  }
  setLoading(true);
  try{
    let urls
    if(ticketDetails.files){
      urls = await uploadFiles();
    }
    await Axios.post('/tickets',{...ticketDetails,mediaUrls:JSON.stringify(urls)},{headers:{accessToken: localStorage.getItem('AccessToken')}})
    setLoading(false)
    navigate('/support')
  }catch(e){
    setLoading(false)
    httpStatus(e)
  }
};

const filesValidation = async(e) =>{
  let extension = ['image/jpg','image/png','image/jpeg'];
  let files = e.target.files;
  for(let i=0;i<files.length;i++){
    if( files && !(extension.includes(files[i].type))){
      toast('Only allow jpg,jpeg and png format images');
      setTicketDetails(prev => ({...prev , files:null}))
      return
    }else if(files && (e.target.files[0].size > 2000000)){
      toast('Image size should be 2MB')
      setTicketDetails(prev => ({...prev , files:null}))
      return
    }else{
      setTicketDetails(prev => ({...prev , files: files }))
    }
  }
}
  
  return (<div className='create-ticket'>
    <h2>Create Ticket</h2>
    <div className='create-t-form'>
    <Form onSubmit={sunbmitForm}>
    <Form.Group>
        <Form.Label>Ticket Type<span className='start-mark'>*</span></Form.Label>
        <Form.Select name='ticketType' value={ticketDetails.ticketType} onChange={ (e) => inputHandler( e , setTicketDetails )}>
          <option>Please Choose Issue Type</option>
          {ticketTypes.map(type=><option key={type.id} value={type.id}>{type.typesName}</option>)}
          </Form.Select>
          <Form.Control.Feedback>
          Please select Ticket type.
          </Form.Control.Feedback>
      </Form.Group>
      {ticketDetails.ticketType === '1' && <Form.Group>
        <Form.Label>Package ID</Form.Label>
          <Form.Select name='packageId' onChange={ (e) => inputHandler( e , setTicketDetails )}>
          <option>Please Choose Pacakge ID</option>
          {allPackages&& allPackages.length&&allPackages.map(id=><option key={id} value={id}>{id}</option>)}
          </Form.Select>
          <Form.Control.Feedback>
          Please provide a valid package ID.
          </Form.Control.Feedback>
      </Form.Group>}
      {(ticketDetails.packageId === 'Package Missed' && ticketDetails.ticketType === '1') && <Form.Group>
        <Form.Label>Pacakge Track ID</Form.Label>
          <Form.Control
          type="text"
          placeholder= 'Please enter package tracking number'
          className='form-control'
          name='incommingTrack'
          value={ticketDetails.incommingTrack}
          onChange={ (e) => setTicketDetails(prev=>({...prev,packageId:'Package Missed',incommingTrack:e.target.value}))}
          />
          <Form.Control.Feedback>
          Please provide a valid Pacakge Track ID.
          </Form.Control.Feedback>
      </Form.Group>}
      {(['2','3','4','5','6'].includes(ticketDetails.ticketType)) &&
       <Form.Group>
        <Form.Label>Subject</Form.Label>
          <Form.Control
          required
          type="text"
          placeholder= 'Please enter issue of subject'
          className='form-control'
          name='subject'
          maxLength={256}
          value={ticketDetails.subject}
          onChange={ (e) => inputHandler( e , setTicketDetails )}
          />
          <Form.Control.Feedback>
          Please provide a valid subject.
          </Form.Control.Feedback>
      </Form.Group>}
      <Form.Group>
        <Form.Label>Description</Form.Label>
          <Form.Control
          as="textarea"
          type="text"
          placeholder= 'Please describe your issue'
          className='form-control'
          name='message'
          rows={6}
          value={ticketDetails.message}
          onChange={ (e) => inputHandler( e , setTicketDetails )}
          />
      </Form.Group>
      <Form.Group>
        <Form.Label>Attachments</Form.Label>
          <Form.Control
          multiple={true}
          type="file"
          className='form-control'
          name='mediaUrls'
          accept="image/*"
          onChange={ (e) => filesValidation(e)}
          />
      </Form.Group>
      <Button className={`cus-btn ${loading && 'disabled'}`} type="submit">Submit Ticket { loading && <Spinner size='sm'/>}</Button>
    </Form>
    </div>

  </div>);
};

export default CreateTicket