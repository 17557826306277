import React from 'react';
import './Strip.css';
import { Col, Row } from "react-bootstrap";
import { CARD_CVC_OPTIONS, CARD_EXP_OPTIONS, CARD_NUM_OPTIONS } from './utils';

const StripeForm = ({CardExpiryElement,CardCvcElement,CardNumberElement}) => {
      
  return (
    <div className={'cardTop'}>
    <div className='payment-img-text'>
        <img src='/images/1.svg' alt='1-img' />
        <div className='secondary-bold-medium-text'>Payment Info</div>
    </div>
    <fieldset className="FormGroup">
      <div className="FormRow">
        <label className={'labelStyle'}>
          Card Number<spam className='start-mark'> *</spam>
        </label>
        <div className={'cardNumStyle'}>
          <CardNumberElement
            options={CARD_NUM_OPTIONS}
          />
        </div>
      </div>
      <div className={`FormRow ${'cardSec'}`}>
        <Row>
          <Col>
            <label className={'labelStyle'}>
              Expiry Date<spam className='start-mark'> *</spam>
            </label>
            <div className={'cardNumStyle'}>
              <CardExpiryElement
                options={CARD_EXP_OPTIONS}
              />
            </div>
          </Col>
          <Col>
            <label className={'labelStyle'}>
              CVV<spam className='start-mark'> *</spam>
            </label>
            <div className={'cardNumStyle'}>
              <CardCvcElement
                options={CARD_CVC_OPTIONS}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className={`FormRow ${'cardSec'}`}>
        <label className={'labelStyle'}>
          Card Holder Name<spam className='start-mark'> *</spam>
        </label>
        <div className={'cardNumStyle1'}>
          <input id="cardName"
            className={'cardNameStyle'}
            placeholder="Name of Person"
          />
        </div>
      </div>
    </fieldset>
  </div>
  )
}

export default StripeForm