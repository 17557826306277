import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { ChangePasswordPage, Login, Register } from '../pages'

const PublicRoutes = () => {
  const { settings } = useSelector( state => state.userData );

  return (
    Object.keys(settings).length > 0 ?
    <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/register' element={<Register/>} />
        <Route path='/password/:hash' element={<ChangePasswordPage />} />
    </Routes>
    : <h1 className='loading-text'>Loading...</h1>
  )
}

export default PublicRoutes