import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState , useEffect } from 'react';
import { CloseButton, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { AddressCard, toast } from '../components';
import { CARD_CVC_OPTIONS, CARD_EXP_OPTIONS, CARD_NUM_OPTIONS } from '../components/Forms/StripeForm/utils';
import { Axios, useCountries, useInput } from '../hooks';
import { httpStatus } from '../utils';
import './AddAddressModal.css';

const AddPaymentMethodModal = ({ show, setShow, address , refreshAddress , setRefreshAddress }) => {
    const [ newAddress,  setNewAddress ] = useState(false);
    const [ selectedAddress , setSelectedAddress ] = useState({});
    const [ addresses , setAddresses ] = useState([]);
    const [ addressData , setAddressData ] = useState({firstName:'' , lastName:'' , phone: '', addressLine1: '', addressLine2: '', countryId:'', stateId: '', cityId: '', zip: ''});
    const { inputHandler } = useInput();
    const { countries , states , cities } = useCountries( addressData.countryId , addressData.stateId );
    const [ isLoading , setIsLoading ] = useState(false);
    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        setAddresses(address.map( element => ({ ...element, selected: false })));
    }, [ address ]);

    window.addEventListener('click' , (e) => {
       if(e.target.className === 'fade modal show'){
         setShow(false);
       }
    });

    const submitHandler = async(event) => {
        event.preventDefault();
        setIsLoading(true);
        if( Object.keys(selectedAddress).length === 0 && Object.values(addressData).includes('')){
            toast('Please enter complete address or Select address', { type : 'error' });
            setIsLoading(false);
            return
        }else{
            const name = document.getElementById('cardName').value;
            let card;
            let token;
            if(!name){
                toast('Please enter complete card details' , { type: 'error'});
                setIsLoading(false);
                return
            }
            card =  elements.getElement(CardNumberElement);
            token = await stripe.createToken(card,{name:name});
            if(token.error){
                toast(token.error.message, { type : 'error' });
                setIsLoading(false);
                return
            }
            try{
                const res = await Axios.post(`/customers/profile/cards`, { token , address:{address: Object.keys(selectedAddress).length > 0 ? selectedAddress.id : {...addressData }}} , { headers : { accessToken: localStorage.getItem('AccessToken')}});
                if(res.data.status){
                    toast( res.data.data , { type: 'info'});
                    setRefreshAddress(!refreshAddress);
                    setShow(!show);
                }else{
                    toast( JSON.parse(res.data.data).raw.message , { type: 'info'});
                    setRefreshAddress(!refreshAddress);
                    setShow(!show);
                }
            }catch(err){
                httpStatus(err);
            }
            setIsLoading(false);
        }  
    };

    const setAsDefaultAddress = (address) => {
         setSelectedAddress(address);
         setAddresses( prev => prev.map( prevAddress => prevAddress.id === address.id ? { ...prevAddress, selected: true } : { ...prevAddress, selected: false } ));
    }

  return (
    <Modal size={ newAddress ? "xl" : 'lg'} aria-labelledby="contained-modal-title-vcenter" show={show} centered>
        <div className='modal-container'>
            <div className='modal-heading'>
                <div className='modal-heading-name'>Add Payment Method</div>
                <div className='modal-close-button' onClick={() => setShow(false)}><CloseButton /></div>
            </div>
            <div className='card-form-billing-address'>
                <div className='card-form-container'>
                    <div className='card-form-heading space-between'>Add Debit/Credit Card { newAddress && (<span className='back-to-addresses-link' onClick={() => setNewAddress(!newAddress)}>Back to Previous Addresses</span>)}</div>
                    <Form>
                        <div className={ newAddress ? 'card-and-address-toggle-form' : ''}>
                            <div className='modal-card-details-form'>
                            <fieldset className="FormGroup">
                                <div className="FormRow">
                                    <label className={'labelStyle'}>
                                    Card Number<spam className='start-mark'> *</spam>
                                    </label>
                                    <div className={'cardNumStyle'}>
                                    <CardNumberElement options={CARD_NUM_OPTIONS} />
                                    </div>
                                </div>
                                <div className={`FormRow ${'cardSec'}`}>
                                    <Row>
                                    <Col>
                                        <label className={'labelStyle'}>
                                        Expiry Date<spam className='start-mark'> *</spam>
                                        </label>
                                        <div className={'cardNumStyle'}>
                                        <CardExpiryElement options={CARD_EXP_OPTIONS} />
                                        </div>
                                    </Col>
                                    <Col>
                                        <label className={'labelStyle'}>
                                        CVV<spam className='start-mark'> *</spam>
                                        </label>
                                        <div className={'cardNumStyle'}>
                                        <CardCvcElement options={CARD_CVC_OPTIONS} />
                                        </div>
                                    </Col>
                                    </Row>
                                </div>
                                <div className={`FormRow ${'cardSec'}`}>
                                    <label className={'labelStyle'}>
                                    Card Holder Name<spam className='start-mark'> *</spam>
                                    </label>
                                    <div className={'cardNumStyle1'}>
                                    <input id="cardName"
                                        className={'cardNameStyle'}
                                        placeholder="Name of Person"
                                    />
                                    </div>
                                </div>
                            </fieldset>
                            </div>
                            {
                                newAddress &&
                                (
                                    <div className='modal-address-form'>
                                        <div className='two-inputs'>
                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                            <Form.Label className='form-label'>First name <spam className='start-mark'> *</spam></Form.Label>
                                            <Form.Control
                                            required
                                            type="text"
                                            placeholder="eg: Jhon"
                                            className='form-input'
                                            name='firstName'
                                            value={addressData.firstName}
                                            onChange={(e) => inputHandler(e, setAddressData)}
                                            />

                                            <Form.Control.Feedback type="invalid" className='feedback-text'>
                                            Please provide a valid First Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                        <Form.Label className='form-label'>Last name <spam className='start-mark'> *</spam></Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="eg: Hanks"
                                            className='form-input'
                                            name='lastName'
                                            value={addressData.lastName}
                                            onChange={(e) => inputHandler(e, setAddressData)}
                                        />
                                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                                        Please provide a valid Last Name.
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='two-inputs'>
                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                            <Form.Label className='form-label'>Address Line1 <spam className='start-mark'> *</spam></Form.Label>
                                            <Form.Control
                                            required
                                            type="text"
                                            placeholder="eg: street,PO,Box,Company,C/O"
                                            className='form-input'
                                            name='addressLine1'
                                            value={addressData.addressLine1}
                                            onChange={(e) => inputHandler(e, setAddressData)}
                                            />

                                            <Form.Control.Feedback type="invalid" className='feedback-text'>
                                            Please provide a valid Address.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                        <Form.Label className='form-label'>Address Line2</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="eg: Apartment, suite,building, etc"
                                            className='form-input'
                                            name='addressLine2'
                                            value={addressData.addressLine2}
                                            onChange={(e) => inputHandler(e, setAddressData)}
                                        />
                                        </Form.Group>
                                    </div>
                                    <div className='two-inputs'>
                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                            <Form.Label className='form-label'>Country <spam className='start-mark'> *</spam></Form.Label>
                                            <Form.Select aria-label="Default select example" 
                                            required
                                            className='form-input'
                                            name='countryId'
                                            value={addressData.countryId}
                                            onChange={(e) => inputHandler(e, setAddressData)}>
                                                <option value={''}>Select Country</option>
                                                { countries.length > 0 && countries.map( country => (<option key={country.id} value={country.id}>{country.name}</option>))}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid" className='feedback-text'>
                                                Please provide a valid Country.
                                            </Form.Control.Feedback>
                                        </Form.Group>


                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                        <Form.Label className='form-label'>State{states.length > 0 ? <spam className='start-mark'> *</spam>:null}</Form.Label>
                                        <Form.Select aria-label="Default select example" 
                                            required={ states.length > 0 ? true : false }
                                            className='form-input'
                                            name='stateId'
                                            value={addressData.stateId}
                                            onChange={(e) => inputHandler(e, setAddressData)}>
                                                <option value={''}>Select State</option>
                                                { states.length > 0 && states.map( state => (<option key={state.id} value={state.id}>{state.name}</option>))}
                                            </Form.Select>
                                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                                            Please provide a valid State.
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='two-inputs'>
                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                            <Form.Label className='form-label'>City{cities.length > 0 ? <spam className='start-mark'> *</spam>:null}</Form.Label>
                                            <Form.Select aria-label="Default select example" 
                                            required={ cities.length > 0 ? true : false }
                                            className='form-input'
                                            name='cityId'
                                            value={addressData.cityId}
                                            onChange={(e) => inputHandler(e, setAddressData)}>
                                                <option value={''}>Select City</option>
                                                { cities.length > 0 && cities.map( city => (<option key={city.id} value={city.id}>{city.name}</option>))}
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid" className='feedback-text'>
                                                Please provide a valid City.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                        <Form.Label className='form-label'>Zip Code <spam className='start-mark'> *</spam></Form.Label>
                                        <Form.Control
                                            required
                                            type="string"
                                            placeholder="eg: de4766"
                                            className='form-input'
                                            name='zip'
                                            value={addressData.zip}
                                            onChange={(e) => inputHandler(e, setAddressData)}
                                        />
                                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                                        Please provide a valid Zip.
                                        </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='two-inputs'>
                                        <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                                            <Form.Label className='form-label'>Phone <spam className='start-mark'> *</spam></Form.Label>
                                            <Form.Control
                                            required
                                            type="number"
                                            placeholder="eg: 9876543212"
                                            className='form-input'
                                            name='phone'
                                            value={addressData.phone}
                                            onChange={(e) => inputHandler(e, setAddressData)}
                                            />

                                            <Form.Control.Feedback type="invalid" className='feedback-text'>
                                            Please provide a valid Phone Number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <div className='form-label-input-container'></div>
                                    </div>
                                    </div>
                                )
                            }
                        </div>
                    </Form>
                </div>
                {
                    !newAddress &&
                    (
                        <div className='billing-addresses-container'>
                            <div className='card-form-heading'>Billing Addresses</div>
                            <div className='payment-addresses-container'> 
                            
                                    {
                                        addresses.map( address => <div className={ address.selected ? 'selected-profile-address-container cursor' : 'profile-address-container card cursor'} key={address.id}><AddressCard key={address.id} address={address} setAsDefaultAddress={setAsDefaultAddress} features={false} defaultFeature={false}   /></div>)
                                    }   
                            </div>
                            <div className='add-new-address-button' onClick={() => setNewAddress(!newAddress)}>Add New Billing Address</div>
                        </div>
                    )
                }
            </div>
            <div className='modal-form-buttons'>
                <div className={ isLoading ?'modal-form-button secondary-white-button cursor disabled' : 'modal-form-button secondary-white-button cursor'} onClick={submitHandler}>Save & Continue { isLoading && <Spinner size='sm' /> }</div>
                <div className='modal-form-button primary-border-button cursor' onClick={() => setShow(!show)}>Cancel</div>
            </div>
        </div>
    </Modal>
  )
}

export { AddPaymentMethodModal }