import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  warehouses: [],
  selectedWarehouse: {},
  currentWarehouse: 0,
  marketingData: { band: [] , popup: [] },
  showPopup: false
}

export const warehousesSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    addWarehouse: (state , action) => {
        state.warehouses = action.payload;
    },
    updateSelectedWarehouse: (state , action) => {
        state.selectedWarehouse = action.payload
    },
    updateCurrentWarehouse: (state , action) => {
        state.currentWarehouse = action.payload
    },
    addMarkeingData: (state , action) => {
       state.marketingData = action.payload
    },
    showPopupFunc: (state , action) => {
      state.showPopup = action.payload
    },
    resetWarehousesSlice: (state) => {
      state.warehouses= []
      state.selectedWarehouse={}
      state.currentWarehouse=0
      state.marketingData={ band: [] , popup: [] }
      state.showPopup= false
    },
  },
})

export const { addWarehouse, updateSelectedWarehouse, updateCurrentWarehouse , addMarkeingData, showPopupFunc, resetWarehousesSlice } = warehousesSlice.actions

export default warehousesSlice.reducer