import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddMoneyToWalletComponent from '../../components/AddMoneyToWalletComponent/AddMoneyToWallet';
import { useSelector } from 'react-redux';
import { CyberSourcePaymentAndAddressComponent } from '../../components';

const AddressAndPaymentPage = () => {
const { settings } = useSelector( state => state.userData );
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_TEST}`);
  return (
    settings.paymentService === 'Stripe'
    ? <Elements stripe={stripePromise}>
        <AddMoneyToWalletComponent />
      </Elements>
    : <CyberSourcePaymentAndAddressComponent />
  )
}

export default AddressAndPaymentPage