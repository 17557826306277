import React from 'react';
import { getAlignedAddressWithBrs } from '../../../global';
import './AddressCard.css';

const AddressCard = ({ makeDefault , address, features, defaultFeature, openEditAddressModal, deleteAddress, setAsDefaultAddress }) => {

  return (
    <div onClick={() => !defaultFeature && setAsDefaultAddress(address) } key={address.id}>
        <div className='address-name-edit-delete-buttons'>
            <h1 className='address-fname-lname'>{address.firstName}{' '}{address.lastName}</h1>
            {
              features && 
              (
                <div className='address-edit-delete-buttons'>
                  <span className={`material-symbols-outlined ${ !address.selected && 'address-card-icon' }`} onClick={() => openEditAddressModal(address)}>edit_square</span>
                  <span className="material-symbols-outlined red-color" onClick={() => deleteAddress(address)}>delete</span>
                </div>
              )
            }
        </div>
        <div className='main-address'>
          { getAlignedAddressWithBrs(address) || " " }
        </div>
        
        {
          defaultFeature &&
          (
            <div className='address-default-button' onClick={() => makeDefault(address.id)}>{ address.selected ? 'Default' : 'Make as default'}</div>
          )
        }
    </div>
  )
}

export default AddressCard