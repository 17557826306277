import { configureStore } from '@reduxjs/toolkit';
import authenticationSlice from './authenticationSlice';
import warehouseSlice from './warehouseSlice';
import topSectionData from './topSectionData';
import toggleSidebarSlice from './toggleSidebarSlice';
import userDetailsSlice from './userDetailsSlice';

export const store = configureStore({
  reducer: {
    warehouseDetails: warehouseSlice,
    authentication: authenticationSlice,
    topSectionData: topSectionData,
    toggleSidebar: toggleSidebarSlice,
    userData: userDetailsSlice,
  },
})