import React, { useState, useEffect } from 'react';
import { CloseButton, Col, Form, Modal } from 'react-bootstrap';
import { Axios, useCountries, useInput } from '../hooks';
import { toast } from '../components';
import './AddAddressModal.css';
import { httpStatus } from '../utils';

const AddAddressModal = ({ show , setShow, dataToEdit, refreshAddress, setRefreshAddress, checkoutRestrict,isDataToEdit }) => {
    const [validated, setValidated] = useState(false);
    const [ addressData , setAddressData ] = useState({firstName:'' , lastName:'' , phone: '', addressLine1: '', addressLine2: '', countryId:'', stateId: '', cityId: '', zip: ''});
    const { inputHandler } = useInput();
    const { countries , states , cities } = useCountries( addressData.countryId?addressData.countryId:null , addressData.stateId?addressData.stateId:null );

    window.addEventListener('click' , (e) => {
        if(e.target.className === 'fade modal show'){
          setShow(false);
          setValidated(false);
        }
     });

    const submitHandler = async(event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if(form.checkValidity() === false) {
            setValidated(true);
        }else{
            setValidated(false);
            try{
                isDataToEdit
                ? await Axios.put(`/customers/address/${dataToEdit.id}` ,{ ...addressData,stateId:states.length?addressData.stateId:null,cityId:cities.length?addressData.cityId:null }, { headers: { accessToken: localStorage.getItem('AccessToken')}})
                : await Axios.post('/customers/address' ,{ ...addressData,stateId:states.length?addressData.stateId:null,cityId:cities.length?addressData.cityId:null }, { headers: { accessToken: localStorage.getItem('AccessToken')}})
                toast( `Address ${isDataToEdit ? 'Edited' : 'Created'} Successfully` , { type : 'success' });
                setAddressData({firstName:'' , lastName:'' , phone: '', addressLine1: '', addressLine2: '', countryId:'', stateId: '', cityId: '', zip: ''});
                setShow(false);
                setRefreshAddress(!refreshAddress);
            }catch(err){
                httpStatus(err);
            }
        }
    };

    const cancelHandler = () => {
        setValidated(false);
        setAddressData({firstName:'' , lastName:'' , phone: '', addressLine1: '', addressLine2: '', countryId:'', stateId: '', cityId: '', zip: ''});
        setShow(false);
    }

    useEffect(() => {
        setTimeout(() => {
            if(isDataToEdit){
                setAddressData({
                    firstName: dataToEdit.firstName || '', 
                    lastName: dataToEdit.lastName || '', 
                    phone: dataToEdit.phone  || '',  
                    addressLine1: dataToEdit.addressLine1 || '',  
                    addressLine2: dataToEdit.addressLine2 || '',  
                    countryId:dataToEdit.countryId || '',  
                    stateId: dataToEdit.stateId || '',  
                    cityId: dataToEdit.cityId || '',  
                    zip: dataToEdit.zip || '',
                });
            }
        })
    },[isDataToEdit, dataToEdit]);


  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} centered>
        <div className='modal-container'>
            <div className='modal-heading'>
                <div className='modal-heading-name'>{ isDataToEdit ? 'Edit' : 'Add'} Address</div>
                <div className='modal-close-button' onClick={() => cancelHandler()}><CloseButton /></div>
            </div>
            <Form noValidate validated={validated} onSubmit={submitHandler}>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>First name<spam className='start-mark'> *</spam></Form.Label>
                        <Form.Control
                        required
                        type="text"
                        placeholder="eg: Jhon"
                        className='form-input'
                        name='firstName'
                        value={isDataToEdit && addressData.firstName ? addressData.firstName:null}
                        onChange={(e) => inputHandler(e, setAddressData)}
                        />

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a valid First Name.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                    <Form.Label className='form-label'>Last name<spam className='start-mark'> *</spam></Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="eg: Hanks"
                        className='form-input'
                        name='lastName'
                        value={isDataToEdit && addressData.lastName?addressData.lastName:null}
                        onChange={(e) => inputHandler(e, setAddressData)}
                    />
                    <Form.Control.Feedback type="invalid" className='feedback-text'>
                    Please provide a valid Last Name.
                    </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>Address Line1<spam className='start-mark'> *</spam></Form.Label>
                        <Form.Control
                        required
                        type="text"
                        placeholder="eg: street,PO,Box,Company,C/O"
                        className='form-input'
                        name='addressLine1'
                        value={isDataToEdit && addressData.addressLine1?addressData.addressLine1:null}
                        onChange={(e) => inputHandler(e, setAddressData)}
                        />

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a Address Line1.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                    <Form.Label className='form-label'>Address Line2</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="eg: Apartment, suite,building, etc"
                        className='form-input'
                        name='addressLine2'
                        value={isDataToEdit&& addressData.addressLine2?addressData.addressLine2:null}
                        onChange={(e) => inputHandler(e, setAddressData)}
                    />
                    <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a Address Line2.
                    </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>Country<spam className='start-mark'> *</spam></Form.Label>
                        <Form.Select aria-label="Default select example" 
                        required
                        className='form-input'
                        name='countryId'
                        disabled={ checkoutRestrict ? true: false }
                        value={isDataToEdit && addressData.countryId ?addressData.countryId:null}
                        onChange={(e) => inputHandler(e, setAddressData)}>
                            <option value={''}>Select Country</option>
                            { countries.length > 0 && countries.map( country => (<option key={country.id} value={country.id}>{country.name}</option>))}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                            Please provide a valid Country.
                        </Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                    <Form.Label className='form-label'>State{states.length > 0 ? <spam className='start-mark'> *</spam>:null}</Form.Label>
                    <Form.Select aria-label="Default select example" 
                        required={ states.length > 0 ? true : false }
                        className='form-input'
                        name='stateId'
                        disabled={ checkoutRestrict ? true: false }
                        value={isDataToEdit&& addressData.stateId ?addressData.stateId:null}
                        onChange={(e) => inputHandler(e, setAddressData)}>
                            <option value={null} >Select State</option>
                            {states.length > 0 && states.map( state => (<option key={state.id} value={state.id}>{state.name}</option>))}
                        </Form.Select>
                    <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a valid State.
                    </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>City{cities.length > 0 ? <spam className='start-mark'> *</spam>:null}</Form.Label>
                        <Form.Select aria-label="Default select example" 
                        required={ cities.length > 0 ? true : false }
                        className='form-input'
                        name='cityId'
                        disabled={ checkoutRestrict ? true: false }
                        value={isDataToEdit && addressData.cityId?addressData.cityId:null}
                        onChange={(e) => inputHandler(e, setAddressData)}>
                            <option value={null}>Select City</option>
                            { cities.length > 0 && cities.map( city => (<option key={city.id} value={city.id}>{city.name}</option>))}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                            Please provide a valid City.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                    <Form.Label className='form-label'>Zip Code<spam className='start-mark'> *</spam></Form.Label>
                    <Form.Control
                        required
                        type="string"
                        placeholder="eg: de4766"
                        className='form-input'
                        disabled={ checkoutRestrict ? true: false }
                        name='zip'
                        value={isDataToEdit && addressData.zip ? addressData.zip:null}
                        onChange={(e) => inputHandler(e, setAddressData)}
                    />
                    <Form.Control.Feedback type="invalid" className='feedback-text'>
                    Please provide a valid Zip.
                    </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className='two-inputs'>
                    <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
                        <Form.Label className='form-label'>Phone<spam className='start-mark'> *</spam></Form.Label>
                        <Form.Control
                        required
                        type="number"
                        placeholder="eg: 9876543212"
                        className='form-input'
                        name='phone'
                        value={isDataToEdit && addressData.phone ? addressData.phone:null}
                        onChange={(e) => inputHandler(e, setAddressData)}
                        />

                        <Form.Control.Feedback type="invalid" className='feedback-text'>
                        Please provide a valid Phone Number.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className='form-label-input-container'></div>
                </div>
                <div className='modal-form-buttons'>
                    <input className='modal-form-button secondary-white-button cursor' type='submit' value={ isDataToEdit ? 'Update Address' : 'Save & Continue'} />
                    <div className='modal-form-button primary-border-button cursor' onClick={() => cancelHandler()}>Cancel</div>
                </div>
            </Form>
        </div>
    </Modal>
  )
}

export { AddAddressModal }