import './App.css';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect,useState } from 'react';
import { PrivateRoutes, PublicRoutes } from './Routes';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAuthentication } from './store/authenticationSlice';
import { Axios } from './hooks';
import { setSettings } from './store/userDetailsSlice';
import { VerifyEmailPage } from './pages';
import { httpStatus } from './utils';

function App() {
  const { isAuthenticated } = useSelector( state => state.authentication );
  const { refreshUserData , settings } = useSelector( state => state.userData );
  const dispatch = useDispatch();
  const [ clientSettings , setClientSettings ] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 0)
  }, [pathname]);

  useEffect(() => {
    (async() => {
      try{
        if(!Object.keys(settings).length > 0){
          const clients = await Axios.get('/client/all');
          setClientSettings(clients.data.data.filter( c => c.domainName === window.location.host || c.domainName === "gateway.mypackageforwarder.com"))
          let settingData = clients.data.data.filter( c => c.domainName === window.location.host || c.domainName === "gateway.mypackageforwarder.com")?.[0];
          settingData && dispatch(setSettings(settingData));

          settingData?.primaryColor && document.documentElement.style.setProperty('--primary-color', settingData?.primaryColor);
          settingData?.secondaryColor && document.documentElement.style.setProperty('--secondary-color',settingData?.secondaryColor);
          let link = document.querySelector("link[rel~='icon']")
          if (!link) { 
            link = document.createElement('link');
            link.rel = 'icon'; 
            document.getElementsByTagName('head')[0].appendChild(link);
          } 
          settingData?.favIcon && (link.href = `data:image/jpeg;base64,${settingData?.favIcon}`);
          document.title = settingData?.companyName || ''
        }
      }catch(err){
        httpStatus(err)
      }
    })()
  },[ refreshUserData , dispatch, settings ]);

  useEffect(() => {
    (async() => {
      if(localStorage.getItem('AccessToken')){
        dispatch(setAuthentication(true));
        if(window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/dashboard') navigate('/dashboard');
      }else{
        if(window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/dashboard') navigate('/')
        else navigate('/')
      }
    })();
  },[ dispatch ]);

  
  return (
    clientSettings.length > 0 ?
    <div className="App">   
      <Routes>
        {
          isAuthenticated
          ? <>
              <Route path='/verify-email/:hash' element={<VerifyEmailPage />} />
              <Route path='/*' element={<PrivateRoutes />} />
            </>
          : <>
              <Route path='/verify-email/:hash' element={<VerifyEmailPage />} />
              <Route path='/*' element={<PublicRoutes/>} />
            </>
        }
      </Routes>
      <ToastContainer
      position='top-right'
      autoClose='5000'
      theme='light'
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      />
     </div>
     : <h1 style={{ display: 'flex' , justifyContent: 'center' , alignItems: 'center', height: '100vh' }}>Loading...</h1>
  );
}

export default App;
