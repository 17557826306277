import React from 'react';
import './Pagination.css';

const Pagination = ({ pageNo, setPageNo , allPages }) => {
  
    const prevPage = () => {
        setPageNo(pageNo - 1);
    }

    const nextPage = () => {
         setPageNo(pageNo + 1);
     }
  return (
    <div className={'pagination-container' }>
        <div className={pageNo !== 1 ? 'prev-btn pagination-btn' : 'not-allowed btn-disabled'} onClick={() => prevPage()}>Previous</div>
        <div className='pagination-text'>{ pageNo } {' of'} { allPages }</div>
        <div className={allPages > pageNo ? 'next-btn pagination-btn' : 'not-allowed btn-disabled'} onClick={() => nextPage()}>Next</div>
    </div>
  )
  }

export default Pagination