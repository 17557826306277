import { Route, Routes } from 'react-router-dom';
import { Footer, Header, Sidebar } from '../layout';
import { React, useEffect } from 'react';
import { AddressAndPaymentPage, CheckoutShippingCalculatorPage, DashboardPage, 
  MyShipmentPage, NotificationsPage, ProfilePage, RecentActivity, ReviewOrderPage, 
  ShippingCalcPage, ShoppingPage,WalletPage, AddMoneyToWallet,WalletTransactions,PaymentSuccess,
  CreateTicket,CustomerTickets,Ticket } from '../pages';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDetails,setFlagNotifications } from '../store/userDetailsSlice';
import { Axios } from '../hooks';
import { addMarkeingData } from '../store/warehouseSlice';
import { httpStatus } from '../utils';
import SetupNotifications from '../utils/firebase';


const PrivateRoutes = () => {
    const dispatch = useDispatch();
    const { toggleSidebar } = useSelector((state) => state.toggleSidebar);
    const { refreshData } = useSelector((state) => state.topSectionData);
    const { settings, refreshUserData, flagNotifications } = useSelector( state => state.userData );

    useEffect(()=>{(async()=>{
      let deviceToken = null
      try{
        if(!deviceToken){
          deviceToken = await SetupNotifications()
          await Axios.post(`/customers/devicetoken`, {token:deviceToken}, { headers: { accessToken: localStorage.getItem('AccessToken')}});
        }
      }catch(e){
        httpStatus(e);
      }
     
    })()},[])

    useEffect(() => {
      (async() => {
        try{
          const userDetails = await Axios.get('/customers' , { headers : { accessToken : localStorage.getItem('AccessToken')}});
          dispatch(setUserDetails(userDetails.data.data?.[0]))
          const marketingRes = await Axios.get('/customers/marketing' , { headers : { accessToken : localStorage.getItem('AccessToken')}});
          dispatch(addMarkeingData(marketingRes.data.data));
          dispatch(setFlagNotifications(flagNotifications?false:true))
        }catch(err){
          httpStatus(err)
        }
      })();
    },[ refreshData , dispatch, refreshUserData ]);
     
  return (
    <div className='authenticated-routes'>
        {
          Object.keys(settings).length > 0
          ? (
            <>
            <Sidebar className='sidebar-container-app' />
            <div className={ toggleSidebar ? 'active-right-side-part' : 'right-side-part mobile-active-right-side-part-toggle-part' }>
              <Header />
              <Routes>
                <Route path='/dashboard' element={<DashboardPage />} />     
                <Route path='/shipcalc' element={<ShippingCalcPage />} />
                <Route path='/myshipment' element={<MyShipmentPage />} />                    
                <Route path='/profile' element={<ProfilePage />} />
                <Route path='/shopping' element={<ShoppingPage />} />
                <Route path='/dashboard/recent-activity' element={<RecentActivity />} />
                <Route path='/dashboard/checkout-shipping-calculator' element={<CheckoutShippingCalculatorPage />} />
                <Route path='/dashboard/review-order' element={<ReviewOrderPage />} />
                <Route path='/dashboard/payment-method' element={<AddressAndPaymentPage />} />
                <Route path='/notifications' element={<NotificationsPage />} />
                <Route path='/wallet' element={<WalletPage />} />
                <Route path='/wallet/payment' element={<AddMoneyToWallet />} />
                <Route path='/wallet/transactions' element={<WalletTransactions />} />
                <Route path='/payment-success' element={<PaymentSuccess />} />
                <Route path='/support' element={<CustomerTickets/>}/>
                <Route path='/support/ticket/:id' element={<Ticket/>}/>
                <Route path='/support/new-ticket' element={<CreateTicket/>}/>
              </Routes>
              <Footer />
            </div>
            </>
          )
          : <h1 className='loading-text'>Loading...</h1>
        }
    </div>
  )
}

export default PrivateRoutes