import React, { useState , useEffect } from 'react';
import { CloseButton, Modal } from 'react-bootstrap';


const ReferAndEarnModal = ({ show , setShow, referCode }) => {
  const [ referralCode , setreferralCode ] = useState('');
  const [ showText , setShowText ] = useState(false);

  useEffect(() => {
    if(referCode){
      setreferralCode(referCode.toUpperCase());
    }
  }, [ referCode ])


  const copyText = () => {
      window.navigator.clipboard.writeText(referralCode);
      setShowText(true);
      setTimeout(() => {
        setShowText(false);
      },1000);
  }

  window.addEventListener('click' , (e) => {
    if(e.target.className === 'fade modal show'){
      setShow(false);
    }
 });

  return (
    <Modal size='lg' aria-labelledby="contained-modal-title-vcenter" show={show} centered>
        <div className='modal-container'>
          <div className='modal-heading' onClick={() => setShow(false)}>
            <div>
              <div className='modal-heading-name'>Refer & Earn</div>
              <div className='gray-text'>Earn $1 per friend</div>
            </div>
            <div className='modal-close-button' onClick={() => setShow(false)}><CloseButton /></div>
          </div>
          <div className='refer-and-earn-container'>
            <div className='refer-code-copy-container'>
              <div className='bold-text'>referral Code</div>
              <div className='referral-input-button'>
                <input type='text' value={referralCode} onChange={(e) => setreferralCode(e.target.value)} className='referral-input' />
                <div className='copy-icon-div'>
                  <span className="material-symbols-outlined copy-icon" onClick={() => copyText()}>content_copy</span>
                  { showText && 
                  (
                    <>
                    <div className='copied-text'>Copied!</div>
                    <div className='point'></div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className='md-bold-text'> How To earn $1 per friend</div>
            <div className='threeSteps'>
              <div className='step'>
                <div className='primary-bold-text'>STEP 1</div>
                <div className='bold-text'>Share your referral code to your friends</div>
                <div className='gray-text'>Simply click on copy and paste the message to your friends.</div>
              </div>
              <div className='step'>
                <div className='primary-bold-text'>STEP 2</div>
                <div className='bold-text'>Your friend uses your referral code</div>
                {/* <div className='gray-text'>They get a cool discounts on their first payment.</div> */}
              </div>
              <div className='step'>
                <div className='primary-bold-text'>STEP 3</div>
                <div className='bold-text'>You get upto $1 credits per friend</div>
                <div className='gray-text'>You can use the credits for your next bill.</div>
              </div>
            </div>
          </div>
        </div>
    </Modal>
  )
}

export default ReferAndEarnModal