import React,{useEffect, useState} from 'react';
import './AddAddressModal.css';

const PaymentSuccess = ({ isOpen,height,setSeconds,seconds })=>{
    const time = new Date().getTime()

    useEffect(()=>{ 
        setInterval(()=>{
        let timeDifference = Math.abs(new Date().getTime() - time);
        const second = Math.floor(timeDifference / 1000);
        setSeconds(second)
    },1000)},[]);

    const formatTime = (time) => {
        const minutes = String(Math.floor(time / 60)).padStart(1, "0");
        const seconds = String(time % 60).padStart(1, "0");
        return `${minutes}m ${seconds}s`;
      }; 

    return(
        <div style={ isOpen ? { height: height } : {} } className={ isOpen ? 'popup-div-active' : 'popup-div'}>
       { isOpen && 
        <div className='payment'>
        <div className='payment-card-succ payment-card-succ-div'>
            <img src='/images/Vector.svg' alt='payment-load' className='' />
            <div className='payment-wait'>Waiting for Payment</div>
            <div className='payment-wait-msg'>Please wait, we're connecting you to the bank</div>
            <div className='payment-wait-msg'>Once your payment is complete,<br/>we'll complete the transaction</div>
            <div className='payment-time-msg'>{formatTime(seconds)}</div>
            <div className='payment-dont'>Please don't click back or refresh your browser</div>
        </div>
    </div>
       }
    </div>
    )
}

export default PaymentSuccess