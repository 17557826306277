import React, { useState , useEffect } from 'react';
import { Axios } from '../../hooks';
import { httpStatus } from '../../utils';
import './ShoppingPage.css';

const ShoppingPage = () => {
  const [ brands , setBrands ] = useState([]);

  useEffect(() => {
    (async()=>{
      try{
        const res = await Axios.get('/customers/shopping' , { headers : { accessToken : localStorage.getItem('AccessToken')}});
        setBrands(res.data.data);
      }catch(err){
        httpStatus(err);
      }
    })();
  },[]);

  return (
    <div className='shopping-page-container'>
      <div className='shopping-heading'>Shopping</div>
      <div className='brands-imgs'>
        {
          brands.length > 0 
        ? brands.map( brand => 
            <div onClick={() => window.open( brand.websiteUrl , "_blank")} className='brand-container' key={brand.id}>
              <img src={`data:image/jpeg;base64,${brand.logo}`} alt='brand-img' className='shopping-brand-img' />
              <div>{brand.brandName}</div>
            </div>
          )
        : <div>No Shopping Brands</div>
        }
      </div>
    </div>
  )
}

export default ShoppingPage