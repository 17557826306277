import React, { useState, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Axios } from '../../hooks';
import { CouponModal } from '../../Modals';
import { httpStatus } from '../../utils';
import ReviewOrderInvoice from '../Accordians/ReviewOrderInvoice/ReviewOrderInvoice';

const OrderSummaryCard = ({ state, imagesCost, proceedForPayment, selectedSplitData, total, setFinalTotal, finalTotal, couponData, setCouponData, isLoading, totalOrder, setTotalOrder, setGrossAmount, balanceWallet, setBalanceWallet }) => {
  const [couponCode, setCouponCode] = useState('');
  const [discountAmount, setDiscountAmount] = useState(0);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [couponsData, setCouponsData] = useState([]);
  const [packagesDetail, setPackagesDetail] = useState({});
  const [walletCheck, setWalletCheck] = useState(false);
  const [walletBalance, setWalletBalance] = useState(0);
  const [splitedAmount, setSplitedAmount] = useState(0)
  const [flagAmount, setFlagAmount] = useState(0)

  const getCouponPrice = async () => {
    if (couponCode) {
      try {
        const res = await Axios.get(`coupons/check/${couponCode.toUpperCase()}`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
        setCouponData(res.data.data[0]);
        toast("Coupon Applied Successfully!", { type: 'success' });
        if (res.data.data[0].discountType && res.data.data[0].cost) {
          if (res.data.data[0].discountType === '%') {
            setDiscountAmount(parseFloat(parseFloat(total / 100) * res.data.data[0].cost).toFixed(2));
            applyCoupon(parseFloat(parseFloat(total / 100) * res.data.data[0].cost).toFixed(2))
          } else if (res.data.data[0].discountType === '$') {
            setDiscountAmount(res.data.data[0].cost)
            applyCoupon(res.data.data[0].cost)
          }
        }
      } catch (err) {
        httpStatus(err)
      }
    }
  }
  const applyCoupon = (discountAmount)=>{
    if(discountAmount){
      setFinalTotal((parseFloat(finalTotal) - parseFloat(discountAmount)) <= 0.00 ? 0.00 : (parseFloat(finalTotal) - parseFloat(discountAmount)).toFixed(2)); 
    }
  }

  useEffect(() => {
    if (imagesCost) {
        setTotalOrder(imagesCost);
        setFinalTotal(imagesCost);
    } else {
        if (selectedSplitData?.price) {
          setGrossAmount(parseFloat(total) + parseFloat(selectedSplitData.price))
          setTotalOrder(parseFloat(total) + parseFloat(selectedSplitData.price))
          setFinalTotal(((parseFloat(total) + parseFloat(selectedSplitData.price)) / selectedSplitData.splits).toFixed(2));
          setSplitedAmount(((parseFloat(total) + parseFloat(selectedSplitData.price)) / selectedSplitData.splits).toFixed(2))
        } else {
          setTotalOrder(total);
          setFinalTotal(total);
        }
    }
  }, [total, selectedSplitData, imagesCost]);

  const checkWallet = () => {
    if (walletCheck) {
      setWalletCheck(false)
      setBalanceWallet(0)
      setFinalTotal(flagAmount)
    } else {
      setWalletCheck(true)
      setFlagAmount(finalTotal)
      if (parseFloat(finalTotal) > parseFloat(walletBalance)) {
        setBalanceWallet(walletBalance)
        setFinalTotal((parseFloat(finalTotal) - parseFloat(walletBalance)).toFixed(2))
      } else {
        setBalanceWallet(finalTotal)
        setFinalTotal(0)
      }
    }
  }

  const openCouponModals = async () => {
    try {
      const res = await Axios.get(`/customers/coupons`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
      setCouponsData(res.data.data);
      setOpenCouponModal(!openCouponModal);
    } catch (err) {
      httpStatus(err)
    }
  }

  useEffect(() => {
    if (state && !imagesCost) {
      (async () => {
        for (let i = 0; i < state.length; i++) {
          try {
            const SummaryRes = await Axios.get(`/packages/summary/${state[i]}?full=true`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
            setPackagesDetail(prev => ({ ...prev, [state[i]]: SummaryRes.data.data }));
          } catch (err) {
            httpStatus(err)
          }
        }
      })();
    }
  }, [state, imagesCost]);

  useEffect(() => {
    (async () => {
      for (let i = 0; i < state.length; i++) {
        try {
          const walletBalance = await Axios.get(`/customers/wallet`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
          setWalletBalance(walletBalance.data?.data[0]?.balance)
        } catch (err) {
          httpStatus(err)
        }
      }
    })();
  }, []);

  return (
    <div className='order-summary-container summary-container card'>
      <div className='primary-bold-big-text'>Order Summary</div>
      <div className='coupon-container'>
        <Form.Control type="text" placeholder="Enter Coupon Code" value={couponCode.toUpperCase()} onChange={(e) => setCouponCode(e.target.value)} />
        <div className='coupon-apply-btn cursor' onClick={() => getCouponPrice()}>Apply</div>
      </div>
      <div className='available-coupons-link' onClick={() => openCouponModals()}>Available Coupons</div>
      <div className='review-order-details-container'>
        {!imagesCost && Object.keys(packagesDetail).length > 0 && Object.keys(packagesDetail).map(packageKey => (
          <div className='review-order-detail-container' key={packageKey}>
            <div className='review-second-heading-details order-invoice-container'>
              <ReviewOrderInvoice isMultiple={state.length > 1} shipmentCost={packagesDetail[packageKey].package.shipmentCost} extras={packagesDetail[packageKey].extras[packageKey]} id={packageKey} reviewOrderDetail={packagesDetail[packageKey]} />
            </div>
          </div>
        ))}
      </div>
      <div className='summary-items-container'>
        <div className='summary-items'>
          {!imagesCost && <div className='summary-item'>{selectedSplitData?.currentInstallment ? `Instalment ${selectedSplitData?.currentInstallment + 1} ` : 'Total Cost'}</div>}
          {imagesCost && <div className='summary-item'>Image Cost</div>}
          {selectedSplitData?.splits && selectedSplitData && <div className='summary-item'>Split Payment Cost</div>}
          {/* {Object.keys(couponData).length > 0 && <div className='summary-item'> Coupon Discount {couponData.discountType}{couponData.cost} Off </div>} */}
        </div>
        <div className='summary-item-price'>
          {!imagesCost && <div className='summary-item'>${total}</div>}
          {imagesCost && <div className='summary-item'>${imagesCost}</div>}
          {selectedSplitData?.splits && selectedSplitData && <div className='summary-item'>${selectedSplitData.price}</div>}
          {/* {Object.keys(couponData).length > 0 && <div className='summary-item'>${discountAmount}</div>} */}
        </div>
      </div>
      <hr />
      <div className='summary-total'>Total <span>${totalOrder}</span></div>
      {selectedSplitData?.splits && selectedSplitData && <div className='summary-total total-split-amounts'>Split Payments <span>{selectedSplitData.splits} X {splitedAmount}</span></div>}
      {selectedSplitData?.splits && <div className='summary-total'>Amount To Pay <span>${splitedAmount}</span></div>}
      {walletBalance > 0 && <div className='summary-items-container'>
        <p><input onChange={() => { checkWallet() }} type='checkbox' ></input> Wallet Balance</p>
        <p>${walletCheck ? (walletBalance - balanceWallet).toFixed(2) : walletBalance}</p>
      </div>}
      {walletCheck && <div className='summary-items-container minus-amount'>
        <p>Wallet Balance (-)</p>
        <p>${balanceWallet}</p>
      </div>}
      <div className='summary-items-container minus-amount'>      
      {Object.keys(couponData).length > 0 && <div className='summary-item'> Coupon Discount {couponData.discountType}{couponData.cost} Off </div>}
      {Object.keys(couponData).length > 0 && <div className='summary-item'>${discountAmount}</div>}
      </div>
       <div className='summary-total'>Total Payable<span>${finalTotal}</span></div>
      <div className={isLoading ? 'secondary-white-button summary-payment-button cursor disabled' : 'secondary-white-button summary-payment-button cursor'} onClick={proceedForPayment}>{parseInt(totalOrder) === 0 ? 'Proceed' : 'Confirm your Payment'} {' '} {' '} {isLoading && <Spinner size='sm' />}</div>
      {/* { parseInt(totalOrder) === 0 && <div className='small-button-text'>Payment details not required</div> } */}
      <CouponModal show={openCouponModal} setShow={setOpenCouponModal} couponData={couponsData} />
    </div>
  )
}

export default OrderSummaryCard