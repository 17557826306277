import React, { useState , useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { getAlignedAddressWithBrs } from '../../../global';
import './ReviewOrderDetails.css';

const ReviewOrderDetails = ({ isMultiple , id , reviewOrderDetail }) => {
  const { shippingAddress , packageContent } = reviewOrderDetail;
  const [ dimensions , setDimensions ] = useState('');
  const [ packageName , setPackageName ] = useState('');

  useEffect(() => {
    if(packageContent){
      let content=[]
      for (let e of packageContent[id]){
        content.push(e.name);
        // content.log(packageContent)
      }
      setPackageName(content.join(','))
    }
    if(reviewOrderDetail.package.dimensions){
      let dimension = JSON.parse(reviewOrderDetail.package.dimensions)
      dimension = `${dimension.length}*${dimension.width}*${dimension.height} in | ${reviewOrderDetail.package.weight} lb`
      setDimensions(dimension)
    }
  }, [ reviewOrderDetail, packageContent , id ]);
  return (
    <Accordion  alwaysOpen={isMultiple}>
      <Accordion.Item>
        <Accordion.Header>
            <div className='review-order-details-accordian-header'>
              <div className='primary-bold-text'>ID: {id}</div>
              <div className='primary-bold-text'>{shippingAddress[0].fullName}</div>
            </div>
        </Accordion.Header>
        <Accordion.Body>
            <div className='review-order-details-accordian-body'>
              <div className='two-details-container'>
                <div className='one-detail-container'>
                  <div className='order-secondary-bold-text'>Shipping Address</div>
                  <div className='order-address'>
                    <h5 className='order-primary-bold-text'>{shippingAddress[0].fullName}</h5>
                    <div className='main-address'>
                    { getAlignedAddressWithBrs(shippingAddress[0])}
                  </div>
                  </div>
                </div>
                <div className='one-detail-container'>
                  <div className='order-secondary-bold-text'>Package Details</div>
                  <div className='order-package-name'>{reviewOrderDetail.package.consolidate === 1 ? 'Consolidated Package' : 'Single Package'}</div>
                  <div className='order-primary-bold-text'>{packageName}</div>

                  <div className='one-detail-container'>
                    <div className='order-primary-bold-text'>Order Summary</div>
                    <div className='order-dimensions'>{dimensions}</div>
                  </div>
                </div>
              </div>

              <div className='two-details-container'>
                <div className='one-detail-container'>
                  <div className='order-secondary-bold-text'>Shipping 
                  Method</div>
                  <img src={reviewOrderDetail.package.provider_image_75} alt='shipping-company-img' />
                  <div className='order-primary-bold-text mt1'>{reviewOrderDetail.package.serviceName}</div>
                  <div className='estimate-delivery-details'>{reviewOrderDetail.package.duration}</div>
                  <div className='bold-big-text'>{reviewOrderDetail.package.shipmentCost} USD</div>
                </div>
              </div>
            </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default ReviewOrderDetails