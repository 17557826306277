import React , { useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import './QuestionnaireForm.css';

const QuestionnaireForm = ({ currentForm , index , formDatas , setFormDatas, addForm , reduceForm, validated, setValidated }) => {

    const handleSubmit = (event) => {
      event.preventDefault();
      const form = event.currentTarget;
      if(form.checkValidity() === false) {
        setValidated(true);
      }else{
          setValidated(false);
          addForm();
      }
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const arrData = [...formDatas]
        arrData[index][name] = value;
        arrData[index]['totalPrice'] = arrData[index].unitCost * arrData[index].quantity;
        setFormDatas(arrData);
    };

    useEffect(() => {
      currentForm.totalPrice = currentForm.quantity * currentForm.unitCost
    },[currentForm]);

  return (
    <Form noValidate validated={validated} className='questionnaire-form' onSubmit={index === formDatas.length - 1 && handleSubmit} key={currentForm.id}>
      <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Item Name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Laptop"
              className='form-input'
              name='name'
              value={currentForm.name}
              onChange={(e) => handleChange(e ,index)}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide Name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Quantity</Form.Label>
            <Form.Control
              type="number"
              placeholder="2"
              className='form-input'
              required
              pattern="[0-9]+*"
              title='Quantity Should not be in decimal.'
              name='quantity'
              value={currentForm.quantity}
              onChange={(e) =>  handleChange(e ,index)}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide Quantity.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Unit Price(USD)</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="100"
              className='form-input'
              name='unitCost'
              value={currentForm.unitCost}
              onChange={(e) => handleChange(e ,index)}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide Unit Cost.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="validationCustom01" className='form-label-input-container'>
            <Form.Label className='form-label'>Total Price (USD)</Form.Label>
            <Form.Control
              type="number"
              placeholder="200"
              className='form-input'
              name='totalPrice'
              readOnly
              value={currentForm.totalPrice}
            />

            <Form.Control.Feedback type="invalid" className='feedback-text'>
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>
        { formDatas.length > 1 && index === 0 && <div className='reduce-form' onClick={() => reduceForm(index , currentForm)}>-</div> }
        { index !== 0 && <div className='reduce-form' onClick={() => reduceForm(index , currentForm)}>-</div> }
        { index === formDatas.length - 1 && <button className='add-more-form reduce-form' type='submit'>+</button>}
    </Form>
  )
}

export default QuestionnaireForm