import React , { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import NormalInput from '../../components/FormComponents/NormalInput';
import { Axios } from '../../hooks';
import { httpStatus } from '../../utils';
import { InputChange } from '../authentication/loginRegisterInputs';
import './ChangePasswordPage.css';

const ChangePasswordPage = () => {
    const [ data , setData ] = useState({ password: '' , confirmPassword: ''});
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const createNewPasswordHandler = async(e) => {
        e.preventDefault();
        if( data.password === data.confirmPassword ){
            try{
                const res = await Axios.post('/customers/forgot/setPassword', {
                    hash:pathname.split('/')[2],
                    password: data.password
                } );
                toast( res.data.data , { type : 'success' });
                navigate('/');
              }catch(err){
                httpStatus(err);
              }
        }else{
            toast( 'Password and confirm Password must be same!' , { type : 'info' });
        }
    }

    const CreateNewPassowordData = [
      {
        id: 1,
        name: 'password',
        type: 'password',
        label: 'Password',
        errorMessage: "Password must contain at least 8 characters including one lowercase letter, one uppercase letter, one number, and one special character.",
        pattern: `^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
        placeholder: 'Password',
        required: true,
      },
      {
        id: 5,
        name: 'confirmPassword',
        type: 'password',
        label: 'Confirm Password',
        errorMessage: "Passwords don't match!",
        placeholder: 'Confirm Password',
        pattern: data.password,
        required: true,
      }
    ]

    
  return (
    <div className='login-container'>
      <div className='login-card'>
        <div className='login-content forget-password-content'>
            <h1 className='login-heading'>Create New Password</h1>
            <form className='form' onSubmit={createNewPasswordHandler}>
              {
                CreateNewPassowordData.map( input => <div key={input.id}><NormalInput onChange={(e) => InputChange(e, setData)} value={data[input.name]} {...input} /></div>)
              }
            <input className='button form-button' type='submit' value='Send' />
            </form>
            
    
        </div> 
        <div className='login-text-container'>
        <div className='common-text'>
         You are one step away to ship with <strong> most affordable, fastest and most reliable</strong> Shipping company in USA!!
        </div>
        <div className='colored-text'>
        <strong>login now and start shipping</strong>
        </div>
        </div>
        <img className='login-img' src='/images/loginImg.svg' alt='login-img'/>
      </div>
    </div>
  )
}

export default ChangePasswordPage