import React , { useState , useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShippingCarriersList, toast } from '../../components';
import { Axios, useInput } from '../../hooks';
import { AddAddressModal } from '../../Modals';
import { updateSelectedWarehouse } from '../../store/warehouseSlice';
import { httpStatus } from '../../utils';
import './shippingcalcpage.css';
import { hotjar } from 'react-hotjar';
import { getAlignedAddress, getAlignedAddressWithBrs } from '../../global';

const ShippingCalcPage = () => {
  const [ selectedCarrier , setSelectedCarrier ] = useState({});
  const [ selectedPackage , setSelectedPackage ] = useState({});
  const [ selectedShipToAddress , setSelectedShipToAddress ] = useState({});
  const [ carriers , setCarriers ] = useState([]);
  const [ shipToAddresses , setShipToAddresses ] = useState([]);
  const [ allPackages , setAllPackages ] = useState([]);
  // const [ weightUnit , setWeightUnit ] = useState(true);
  // const [ sizeUnit , setSizeUnit ] = useState(true);
  const [ packageDimensions , setPackageDimensions ] = useState({ length:'' , width:'' , height:'' , weight: ''});
  const [ isLoading , setIsLoading ] = useState(false);
  const { userDetails } = useSelector( state => state.userData );
  const { inputHandler } = useInput();
  const { selectedWarehouse } = useSelector((state) => state.warehouseDetails);
  const [ showAddressModal, setShowAddressModal ] = useState(false);
  const [ dataToEdit , setDataToEdit ] = useState({});
  const [ refreshAddress , setRefreshAddress ] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    hotjar.initialize(3576088, 6);
  }, []);

  useEffect(() => {
    (async() => {
      try{
        const res = await Axios.get(`/customers/shippingCost` , { headers : { accessToken : localStorage.getItem('AccessToken')}});
        Object.keys(selectedWarehouse).length === 0 && dispatch(updateSelectedWarehouse(res.data.wareHouse[0]));
        setShipToAddresses(res.data.addresses);
        setAllPackages(res.data.packages.map( mpfPackage => ({ ...mpfPackage , selected: false })));
      }catch(err){
        httpStatus(err);
      }
    })();
  },[ refreshAddress ]);

  const selectedShippingCarrier = (carrier) => {
     if(Object.keys(selectedCarrier).length === 0){
      setCarriers( prev => prev.map( prevCarrier => prevCarrier.id === carrier.id ? { ...prevCarrier, selected: true } : { ...prevCarrier, selected: false } ));
     }else{
      setSelectedCarrier(carrier);
      setCarriers( prev => prev.map( prevCarrier => prevCarrier.id === carrier.id ? { ...prevCarrier, selected: true } : { ...prevCarrier, selected: false } ));
     }
  }

  useEffect(() => {
    setShipToAddresses( prev => prev.map( prevAddress => prevAddress.id === selectedShipToAddress.id ? { ...prevAddress, selected: true } : { ...prevAddress, selected: false } ));
  },[selectedShipToAddress]);

  useEffect(() => {
    setAllPackages( prev => prev.map( prevPakage => prevPakage.id === selectedPackage.id ? { ...prevPakage, selected: true } : { ...prevPakage, selected: false } ));
  },[selectedPackage]);


  const selectPackage = (selectPackage) => {
    const dimensions = JSON.parse(selectPackage.dimensions);
    setPackageDimensions({ length: dimensions?.length , width: dimensions?.width , height: dimensions?.height , weight: selectPackage.weight })
    setSelectedPackage(selectPackage);
  }


const generateShippingCarriers = async() => {
  setCarriers([]);
  if(Object.keys(selectedShipToAddress).length > 0){
    if(!Object.values(packageDimensions).includes('')){
      setIsLoading(true);
      try{
        const res = await Axios.post('/shippingCost', { ...packageDimensions, sizeUnits : 'in' , weightUnits: 'lb', addressId: selectedShipToAddress.id , wareHouseId: selectedWarehouse.id , clientId: userDetails?.clientId } , { headers: { accessToken: localStorage.getItem('AccessToken')}});
        let data = res.data.data.map( data => data.amount);
        if(data.includes('NaN')){
          toast( 'Shipping Service is not Available for Selected Addrees!' , { type : 'error' });
        }else{
          setCarriers(res.data.data);
        }
        setIsLoading(false);
      }catch(err){
        setIsLoading(false);
        httpStatus(err);
      }
    }else{
      toast( 'Please Enter Package Details Properly!' , { type: 'info'} );
    }
  }else{
    toast( 'Please Select Address!' , { type: 'info'} );
  }
}   

const filterHandler = () => {
  if(carriers.length > 1){
    setIsLoading(true);
    setTimeout(() => {
      setCarriers( prev => prev.sort((a , b) => a.amount - b.amount ));
      setIsLoading(false);
    },1500);
  }else{
    toast( 'No Carriers to Filter!' , { type: 'info'} );
  }
}

const editAddressModal = (address) => {
  setDataToEdit(address);
  setShowAddressModal(true);
}

  return (
    <div className='shipping-calc-page-container'>
      <div className='data-content'>
          <div className='shipping-left-side-content'>
            <div className='shipping-left-top-content'>         
                <div className='shipping-card ship-from-card'> 
                  <div className='shipping-card-heading'>Ship From</div>
                  { Object.keys(selectedWarehouse).length > 0 &&
                  <>
                    <img src={`/flags/${selectedWarehouse.iso2.toLowerCase()}.png`} className='country-flag' alt='country-flag-img' />
                    <div className='ship-from-card-content'>
                      <div className='primary-bold-big-text'>{ selectedWarehouse.name }</div>
                      <div className='ship-from-address primary-text'> { selectedWarehouse?.addressLine1} {selectedWarehouse?.addressLine2}</div>
                      <div className='ship-from-suiteid secondary-bold-text'>Suite ID: {userDetails.prefixSuite}{userDetails.suiteId}</div>
                      <div className='ship-from-address primary-text'> { selectedWarehouse?.cityName}, {selectedWarehouse?.stateName} </div>
                      <div className='ship-from-address primary-text'> { selectedWarehouse?.zip}, {selectedWarehouse?.countryName} </div>
                      {/* <div className='ship-from-address primary-text'> { getAlignedAddress(selectedWarehouse)} </div> */}
                    </div>
                  </>
                  }
                </div>
              
                <div className='shipping-card'>             
                <div className='shipping-card-heading'>Ship To</div>
                <div className='ship-to-card'>
                <div className='ship-to-addresses'>
                  { shipToAddresses.length > 0 ? shipToAddresses.map( address => (
                      <div className={ address.selected ? 'selected-to-ship-address-card cursor' : 'to-ship-address-card cursor' } key={address.id} onClick={() => setSelectedShipToAddress(address)}>
                        <img src={`/flags/${address.iso2.toLowerCase()}.png`} className='country-flag' alt='country-flag' />
                        <div className='ship-to-address-content'>
                          <div className='ship-to-address-name primary-bold-text m0'>{address.firstName}{' '}{address.lastName}</div>
                          <span className='material-symbols-outlined pen-icon' onClick={() => editAddressModal(address)}>edit_square</span>
                          <div className='ship-to-address-address primary-text'>{ getAlignedAddress(address) }</div>
                        </div>
                      </div>
                    )) : 'No Addresses'}
                </div>
                </div>
                </div>
            </div>
            <div className='shipping-left-bottom-content'> 

                <div className='shipping-card'>
                  <div className='shipping-card-heading'>My Packages</div>
                  <div className='my-packages-card-container'>
                    <div className='my-packages'>
                      { allPackages.length > 0 ? allPackages.map( packages => (
                          <div className={packages.selected ? 'selected-my-packages-card cursor' : 'my-packages-card cursor'} key={packages.id} onClick={() => selectPackage(packages)}>
                              <div className='my-packages-trackId'> IncommingTrack: {packages.incomingTrack}</div>
                              <div className='my-packages-name'>{JSON.parse(packages.packageContent)?.[0]?.name}</div>
                              <div className='my-packages-name'>Dimensions: {JSON.parse(packages.dimensions)?.length}*{JSON.parse(packages.dimensions)?.width}*{JSON.parse(packages.dimensions)?.height} in | {packages.weight}lb</div>
                          </div>
                        )) : 'No Packages' }
                    </div>
                  </div>
                  </div>
            
                <div className='shipping-card package-details-card'>
                  <div className='shipping-card-heading'>Package Details</div>
                  <div className='package-dimensions-container'>
                    <div className='weight-lb-kg'>
                      <label htmlFor='weight' className='package-dimension-label'>Weight
                        <input type='number' placeholder='0' className='package-dimension-input' id='weight' name='weight' value={packageDimensions.weight} onChange={(e) => inputHandler(e, setPackageDimensions)} />
                      </label>
                      lb
                      {/* <div className='switch-fields'>
                        <div className={ weightUnit ? 'unit selected-unit' : 'unit'} onClick={() => setWeightUnit(!weightUnit)}>lb</div>
                        <div className={ weightUnit ? 'unit' : 'unit selected-unit'} onClick={() => setWeightUnit(!weightUnit)}>kg</div>
                      </div> */}
                    </div>
                    <div className='three-inputs'>
                      <div className='label-input'>
                        <label htmlFor='length' className='package-dimension-label'>Length
                            <input type='number' placeholder='0' className='package-dimension-input' id='length' name='length' value={packageDimensions.length} onChange={(e) => inputHandler(e, setPackageDimensions)} />
                        </label>
                      </div>
                      <div className='label-input'>
                        <label htmlFor='width' className='package-dimension-label'>Width
                            <input type='number' placeholder='0' className='package-dimension-input' id='width' name='width' value={packageDimensions.width} onChange={(e) => inputHandler(e, setPackageDimensions)} />
                        </label>
                      </div>
                        <div className='label-input'>
                          <label htmlFor='length' className='package-dimension-label'>Height
                            <input type='number' placeholder='0' className='package-dimension-input' id='height' name='height' value={packageDimensions.height} onChange={(e) => inputHandler(e, setPackageDimensions)} />
                          </label>
                        </div>
                        {/* <div className='switch-fields'>
                        <div className={ sizeUnit ? 'unit selected-unit' : 'unit'} onClick={() => setSizeUnit(!sizeUnit)}>in</div>
                        <div className={ sizeUnit ? 'unit' : 'unit selected-unit'} onClick={() => setSizeUnit(!sizeUnit)}>cm</div>
                      </div> */}
                      in
                    </div>
                  </div>
                </div> 
            </div>
          </div>
          <div className='secondary-white-button estimate-cost-button' onClick={() => generateShippingCarriers()}>Estimate Costs</div>
      </div>
      <div className='shipping-right-side-content'>
       <ShippingCarriersList isLoading={isLoading} carriers={carriers.sort((a , b) => a.amount - b.amount )} selectedShippingCarrier={selectedShippingCarrier} isShippingCalcPage={true} filterHandler={filterHandler} />
      </div>
      <AddAddressModal show={showAddressModal} setShow={setShowAddressModal} dataToEdit={dataToEdit} setRefreshAddress={setRefreshAddress} refreshAddress={refreshAddress} checkoutRestrict={true} isDataToEdit={true}/>
    </div>
  )
}

export default ShippingCalcPage