export const useInput = () => {
  const inputHandler = (event , callback) => {
    let { value , name, checked } = event.target;

    callback((prev) => {
      if(checked){
        return{
          ...prev,
          [name]:checked
         }
      }else{
       return{
        ...prev,
        [name]:value
       }
      }
    })
  }

  return { inputHandler }
}
                                                              