import React, { useState, useEffect } from 'react';
import './MobilePackageDetailAccordian.css';
import { statusImgById, statusNameById } from '../../../utils';
import PackageDetailCard from '../../Cards/PackageDetailCard';
import { Spinner } from 'react-bootstrap';
import { QuestionnaireModal } from '../../../Modals';
import { useSelector } from 'react-redux';

const MobilePackageDetailAccordian = ({ packageData, allCustomizations, customizations , setCustomizations,unSelectPackage, selectedPackage , setSelectedPackage, alreadySelectedCustomization, setAlreadySelectedCustomization, checkConsolidationSeparation, separationLoading, consolidationSeparation, selectMultiplePackage, packageClicked, allPackages , setPackages, mulitiplePackages }) => {
    const [ openQuestionnaireModal , setOpenQuestionnaireModal ] = useState(false);
    const [ packageContent , setPackageContent ] = useState([]);
    const { settings } = useSelector( state => state.userData );

    useEffect(() => {
        if(packageData.packageContent){
          setPackageContent(packageData.packageContent)
        }
    },[ packageData ]);

    //   console.log(packageContent)

    // const sliceIncoming = (income) =>{
    //     if(income) return `${income.slice(0,10)}...`
    //     return income
    // } 

  return (
    <>
        <div className={ packageData.selected ? 'selected-mobile-package-detail-card' : 'mobile-package-detail-card'}>
            <div className='status-img-modal-icons-container'>
                <div className='package-img pa-img1' onClick={() => packageClicked(packageData)}>
                    <img src={statusImgById(packageData.status)} alt='package-img' width={100} className='package-status-img' />
                    <div className='gray-bold--text'>{statusNameById(packageData.status)}</div>
                </div>
                <div className='modal-icons'>
                    { packageData.disposed === 0
                    ? null
                    : !packageData.consolidate 
                    ? <img onClick={() => setOpenQuestionnaireModal(!openQuestionnaireModal)} className='mobile-package-icon' src='/images/mobile-edit-package-icon.svg' alt='edit-img' />
                    : null }
                    {/* <img className='mobile-package-icon summary-mobile-icon' src='/images/summary-img.svg' alt='summary-img' /> */}
                </div>
            </div>
            {mulitiplePackages.length > 0 && packageData.status<=2 && <p className='question-error'>Please fill out Questionnaire!</p>}
            <div className='mobile-package-detail-container' onClick={() => packageClicked(packageData)}>
                <div className='mobile-package-detail-name-values'>
                    <div className='detail-name'>Incoming track </div>
                    <div className='value'>{packageData.incomingTrack || ''}</div>
                </div>
                <div className='mobile-package-detail-name-values'>
                    <div className='detail-name'>Package Id</div>
                    <div className='value'>{packageData.id || ''}</div>
                </div>
                <div className='mobile-package-detail-name-values'>
                    <div className='detail-name'>Recieved On</div>
                    <div className='value'>{packageData.dateArrived.slice(0,10) || ''}</div>
                </div>
                <div className='mobile-package-detail-name-values'>
                    <div className='detail-name'>Product Name</div>
                    <div className='value'>{ packageData.consolidate? "Combined Package" : packageContent?.[0]?.name ? packageContent?.[0]?.name : ''}</div>
                </div>
            </div>
        </div>
        {
            !selectMultiplePackage && packageData.selected
            ? packageData.consolidate === 1 
            ? packageData.status === 3 
            ? <div className='consolidation-package-card-container'>
                <img src='/images/waitTill-img.svg' alt='wait24hours-img' className='wait24hours' />
                <div className='secondary-bold-big-text'>Please Wait 24 Hours</div>
                <div className='primary-bold-text'>Your customizations are being handled.</div>
                <div className='gray-text'>We’ve sent your package customization <br /> to the warehouse. Check back shortly to <br /> see the updates.</div>
                { checkConsolidationSeparation() ? <div className='forward-package-button primary-separation-button cursor' onClick={() => consolidationSeparation()}>
                    <div className={`forward-package-button-text ${ separationLoading && 'disabled' }`}> Cancel Consolidation {' '} { separationLoading && <Spinner size='sm' />}</div>
                </div>:<div className='forward-package-button primary-separation-button cursor' onClick={() => consolidationSeparation()}> 
                    <div className={`forward-package-button-text ${ separationLoading && 'disabled' }`}> Cancel Consolidation Separation {' '} { separationLoading && <Spinner size='sm' />}</div></div>}
                    <div className='phone-icon-text'>
                    {/* <a href={`tel:+${settings?.supportPhoneNumber || ''}`}><img src='/images/phone-img.svg' alt='phone-icon' /></a> */}
                    <a href='/support' className='primary-bold-text'> Contact the Customer Support </a>
                </div>
              </div>
            : <PackageDetailCard allCustomizations={allCustomizations}  customizations={customizations} setCustomizations={setCustomizations} setCustomizationsunSelectPackage={unSelectPackage} selectedPackage={selectedPackage} consolidate={true} setSelectedPackage={setSelectedPackage} alreadySelectedCustomization={alreadySelectedCustomization} setAlreadySelectedCustomization={setAlreadySelectedCustomization}  allPackages={allPackages} setPackages={setPackages} />
            : <PackageDetailCard allCustomizations={allCustomizations} customizations={customizations} setCustomizations={setCustomizations} unSelectPackage={unSelectPackage} selectedPackage={selectedPackage} consolidate={false} setSelectedPackage={setSelectedPackage} alreadySelectedCustomization={alreadySelectedCustomization} setAlreadySelectedCustomization={setAlreadySelectedCustomization}  allPackages={allPackages} setPackages={setPackages} />
            : ''
        }
        <QuestionnaireModal show={openQuestionnaireModal} setShow={setOpenQuestionnaireModal} selectedPackage={packageData} setSelectedPackage={setSelectedPackage} unSelectPackage={unSelectPackage} />
    </>
  )
}

export default MobilePackageDetailAccordian