import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  packages: [],
  shippedPackages: [],
  recentActivitiesData: [],
  refreshData: false,
  shippedPackagesCount:0
}

export const topSectionDataSlice = createSlice({
  name: 'topSectionData',
  initialState,
  reducers: {
    addPackages: (state , action) => {
        state.packages = action.payload
    },
    addShippedPackages: (state , action) => {
        state.shippedPackages = action.payload
    },
    addRecentActivitiesData: (state , action) => {
      state.recentActivitiesData = action.payload
    },
    refreshTopSectionData: (state) => {
      state.packages=[]
      state.recentActivitiesData=[]
      state.shippedPackages=[]
      state.shippedPackagesCount=0
      state.refreshData = !state.refreshData
    },
    resetTopSectionData: (state) => {
      state.packages=[]
      state.recentActivitiesData=[]
      state.shippedPackages=[]
      state.shippedPackagesCount=0
    },
    setshippedPackagesCount: (state,action) => {
      state.shippedPackagesCount = action.payload
    }
  },
})

export const { addPackages, addShippedPackages, addRecentActivitiesData, refreshTopSectionData, setshippedPackagesCount, resetTopSectionData } = topSectionDataSlice.actions

export default topSectionDataSlice.reducer