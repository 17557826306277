import { Modal, Button, Form, Col } from 'react-bootstrap';
import { useState } from 'react';
import styles from './socialAuth.module.css';
import { Axios, useCountries } from '../../hooks';
import { toast } from '../../components/index';
import { setUserDetails } from '../../store/userDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { setAuthentication } from '../../store/authenticationSlice';
import { deviceDetect } from 'react-device-detect';
import './auth.css';
import { httpStatus } from '../../utils';


const AuthModal = ({ show, handleModalClose, user, socialType }) => {
  const {browserName,browserFullVersion,engineName,engineVersion,osName,osVersion} = deviceDetect()
  const { countries } = useCountries();
  const [ countryId  , setCountryId ] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { settings } = useSelector( state => state.userData );

  const handleRegister = async () => {
    if (!countries) return null;
    if (!user) return toast(`Unable to get ${socialType === "googleId" ? "Google" : "Facebook"} user`);

    if (!countryId) return toast('Unable to get country ID');
    try {
      const response = await Axios.post(`customers/signup`,
      { ...user, email: user.email.toLowerCase(), countryId: countryId, confirmPassword: user.password, [socialType]: user.password, registeredDate: new Date().toISOString().slice(0, 19).replace('T', ' '), clientId: settings?.clientId,
      browserName, browserFullVersion, engineName, engineVersion, osName, osVersion });
      localStorage.setItem('AccessToken', response.data.data.accessToken);
      dispatch(setUserDetails(response.data.data.user));
      setCountryId(0);
      handleModalClose(false);
      toast('Signup Successfully!', { type: 'success' });
      dispatch(setAuthentication(true));
      navigate('/dashboard');
    } catch (err) {
      httpStatus(err);
      setCountryId(0);
      handleModalClose(false);
    }
  }

  return (
    <Modal show={show} size='lg' onHide={() => handleModalClose(false)}>
      <Modal.Body>
        <img width={100} src="/images/socialRegister.svg" alt='world-map-img' className={styles.modalImg} />
        <h3 className={styles.countryText}>Select Country</h3>
        <div className={styles.alertText}>
          <p>We couldn't find a MPF account associated with that {socialType === "googleId" ? "Google" : "Facebook"} account. Do you want to create a new one?</p>
        </div>
        <div className={`${styles.countrySelect} form-group col-12`}>
        <Form.Group as={Col} controlId="validationCustom01" className='countries-in-modal'>
            <Form.Label className='select-country-lable'>Country</Form.Label>
            <Form.Select aria-label="Default select example" 
            required
            className='form-input'
            name='countryId'
            value={countryId}
            onChange={(e) => setCountryId(e.target.value)}>
                <option value={''}>Select Country</option>
                { countries.length > 0 && countries.map( country => (<option key={country.id} value={country.id}>{country.name}</option>))}
            </Form.Select>

            <Form.Control.Feedback type="invalid" className='feedback-text'>
                Please provide a valid Country.
            </Form.Control.Feedback>
        </Form.Group>     
        </div>

        <div className={styles.btnDiv}>
          <Button onClick={handleRegister} id="registerBtn">Register</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AuthModal;