export const CARD_NUM_OPTIONS = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "15px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#9C9C9C" },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    },
  };
  
export const CARD_EXP_OPTIONS = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "15px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#9C9C9C" },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    },
  };
  
 export const CARD_CVC_OPTIONS = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "15px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#9C9C9C" },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    },
  };