import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from '../../components';
import { Axios } from '../../hooks';
import { refreshTopSectionData } from '../../store/topSectionData';
import { httpStatus, statusImgById, statusNameById } from '../../utils';

const SelectMultiplePackageCard = ({ packagesToCombined, unSelectMultiplePackages,setConsolidate,getCostOfCustomizationByName }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userDetails } = useSelector( state => state.userData );

    

    const combinedPackage = async() => {
        try{
            await Axios.post('packages/consolidation' , { consolidatedItems: packagesToCombined.map( packageToCombine => packageToCombine.id)} , { headers: { accessToken: localStorage.getItem('AccessToken')}});
            unSelectMultiplePackages();
            toast( 'Consolidation Successfully!' , { type : 'success' });
            dispatch(refreshTopSectionData());
        }catch(err){
            httpStatus(err);
        }
    }

    const forwordPackage = () => {
        if( userDetails.verified === 1){
            for(let i=0; packagesToCombined.length; i++){
                if(packagesToCombined[i].status<=2){
                  toast("Please fill out the questionnaire!",{ type : 'info' })
                  return
                }else if(packagesToCombined[i].status>9){
                  toast(`The ${packagesToCombined[i].id} hold or return package does not allow to forward`,{ type : 'info' })
                  return
                }
              }
              navigate('/dashboard/checkout-shipping-calculator' , { state : { packagesToCheckout : packagesToCombined , isMultiplePakage: true } } );        
        }else{
            toast( "Please verify your email!" , { type : 'info' });
            return
        }
    }

  return (
    <div className='multiple-packages-container'>
        <div className='packages-to-combined-container'>
            {
            packagesToCombined && packagesToCombined.map( packageToCombine => (
                <div className='combined-package-detail-container' key={packageToCombine.id}>
                <div className='package-name-img'>
                    <div className='package-name-number'>
                    <div className='package-name'>{packageToCombine.packageContent?.[0]?.name}</div>
                    <div className='gray-text'>{packageToCombine.incomingTrack}</div>
                    </div>
                    <div className='package-img'>
                    <img src={statusImgById(packageToCombine.status)} alt='package-img' />
                    <div className='gray-text'>{statusNameById(packageToCombine.status)}</div>
                    </div>
                </div>
                <div className='package-dimensions'>
                    <div className='package-content-card-heading'>Dimensions</div>
                    <div className='package-dimension'>{JSON.parse(packageToCombine.dimensions)?.length}*{JSON.parse(packageToCombine.dimensions)?.width}*{JSON.parse(packageToCombine.dimensions)?.height} in | {packageToCombine.weight}lb</div>
                </div>
                </div>
            ))
            }
        </div>
        <div className='combined-package-details-buttons'>
            <div className='combined-package-button cursor' onClick={() => setConsolidate()}>
            <div className='forward-package-button-text'>Combining ${getCostOfCustomizationByName('Consolidation')} / Package</div>
            <span className="material-symbols-outlined">chevron_right</span>
            </div>
            <div className='forward-package-button cursor' onClick={() => forwordPackage()}>
            <div className='forward-package-button-text'>Forward Package</div>
            <span className="material-symbols-outlined">chevron_right</span>
            </div>
        </div>
    </div>
  )
}

export default SelectMultiplePackageCard