import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';

const SetupNotifications = async () => {
  try {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGEINGSENDERID,
      appId: process.env.REACT_APP_FIREBASE_APPID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
    }
    const firebaseApp = await initializeApp(firebaseConfig);
    const messaging = await getMessaging(firebaseApp);
    var token = null
    // Request permission for notifications
    let permission = await Notification.requestPermission();

    if (permission === 'granted') {
      // Get the FCM token
      token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKEY });
    } else if (permission !== "denied") {
      permission = await Notification.requestPermission();
      if (permission === 'granted') {
        token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPIDKE });

      }
    } else {
      console.log('Notification permission denied.');
    }
    // Handle foreground notifications
    onMessage(messaging, async (payload) => {
      // Handle the notification or update your UI
      await onNotification(payload)
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
  return token
};

async function onNotification(theNotification) {
  const { notification,data } = theNotification;
  notification.data = data
  notification.icon = 'https://mypackageforwarder.com/wp-content/uploads/2024/05/notificationbg.png'
  notification.onclick = data.link_url
  notification.image = 'https://mypackageforwarder.com/wp-content/uploads/2024/05/notificationbg.png'
  
  if ('serviceWorker' in navigator) {
    // this will register the service worker or update it. More on service worker soon
    await navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: './' }).then(function (registration) {
      setTimeout(() => {
        // display the notificaiton
        registration.showNotification('My Package Forwarder',notification).then(done => {
        }).catch(err => {
          console.error("Error sending notificaiton to user", err);
        });
        registration.update();
      }, 100);
    }).catch(function (err) {
      console.log("Service Worker Failed to Register", err);
    });
  }
}

export default SetupNotifications;