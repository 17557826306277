import { useState, useEffect } from 'react';
import { Axios } from './axios';
import { httpStatus } from '../utils';

export const useCountries = ( countryId , stateId ) => {
  const [ countries , setCountries ] = useState([]);
  const [ states, setStates ] = useState([]);
  const [ cities, setCities ] = useState([]);

  useEffect(() => {
    (async() => {
      try{
        let res = await Axios.get('/countries');
        setCountries(res.data.data);
        setStates([])
        setCities([])
      }catch(err){
        httpStatus(err)
      }
    })()
  },[]);

  useEffect(() => {
    if(countryId){
      (async() => {
        try{
          let res = await Axios.get(`/states/${countryId}`);
          setStates(res.data.data);
          setCities([])
        }catch(err){
          httpStatus(err);
        }
      })()
    }
  },[countryId]);

  useEffect(() => {
    if(stateId){
      (async() => {
        try{
          let res = await Axios.get(`/cities/${stateId}`);
          setCities(res.data.data);
        }catch(err){
          httpStatus(err);
        }
      })()
    }
  },[stateId]);

  return { countries , states , cities };
}