import React from 'react'
import { CloseButton, Modal } from 'react-bootstrap';
import './AddAddressModal.css';

const ConfirmationModal = ({ show , setShow, title, longText, Func }) => {

    window.addEventListener('click' , (e) => {
        if(e.target.className === 'fade modal show'){
          setShow(false);
        }
     });

     
  return (
    <Modal size='md' aria-labelledby="contained-modal-title-vcenter" show={show} centered>  
        <div className='modal-container'>
            <div className='modal-heading' onClick={() => setShow(false)}>
              <div>
                <div className='modal-heading-name'>{title}</div>
              </div>
              <div className='modal-close-button' onClick={() => setShow(false)}><CloseButton /></div>
            </div>
            <div className='confirmation-modal-normal-text'>{longText}</div>
            <div className='two-buttons'>
                <div className='primary-button cursor' onClick={Func}>Yes</div>
                <div className='primary-border-button cursor' onClick={() => setShow(false)}>No</div>
            </div>
        </div>
    </Modal>
  )
}

export default ConfirmationModal