import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HeaderProfileIcon } from '../../assets/icons';
import { Notifications, toast } from '../../components';
import { Axios } from '../../hooks';
import { ReferAndEarnModal } from '../../Modals';
import { setAuthentication } from '../../store/authenticationSlice';
import { resetTopSectionData } from '../../store/topSectionData';
import { resetUserData } from '../../store/userDetailsSlice';
import { resetWarehousesSlice } from '../../store/warehouseSlice';
import { httpStatus } from '../../utils';
import './Header.css';

const Header = () => {
  const [openReferModal, setOpenReferModal] = useState(false);
  const [referCode, setReferCode] = useState('false');
  const dispatch = useDispatch();
  const { userDetails,flagNotifications,updateWallet } = useSelector(state => state.userData);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [walletBalance,setWalletBalance] = useState(0);
  const [newNotify,setNewNotify] = useState(0)

  useEffect(() => {
    (async () => {
      try {
        const res = await Axios.get(`/customers/notifications?full=true`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
        setNotifications(res.data?.data);
        const data = res.data?.data;
        let count =0
        for(let i=0;i<data.length;i++){
          if(data[i].saw===0)count=count+1
        }
        setNewNotify(count)
      } catch (err) {
        toast(err.response.data.data, { type: 'error' });
      }
    })();
  }, [flagNotifications]);
  
  useEffect(() => {
    (async () => {
      try {
        const wallet = await Axios.get(`customers/wallet/balance`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
        setWalletBalance(Object.keys(wallet.data.data[0]).length?wallet.data.data[0].balance:0);
      } catch (err) {
        toast(err.response.data.data, { type: 'error' });
      }
    })();
  }, [updateWallet]);

  useEffect(() => {
    if (userDetails) {
      if (Object.keys(userDetails).length > 0) {
        setUserData(userDetails);
      }
    }
  }, [userDetails]);


  const logoutHandler = async () => {
    try {
      const logout = await Axios.delete(`/customers/logout`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
      dispatch(resetUserData());
      dispatch(resetWarehousesSlice());
      dispatch(resetTopSectionData());
      dispatch(setAuthentication(false));
      navigate('/');
      toast(logout.data.data, { type: 'success' });
      localStorage.clear();
    } catch (err) {
      dispatch(resetUserData());
      dispatch(resetWarehousesSlice());
      dispatch(resetTopSectionData());
      dispatch(setAuthentication(false));
      navigate('/');
      httpStatus(err);
      localStorage.clear();
    }
  }

  const verifyEmailHandler = async () => {
    try {
      await Axios.post(`/customers/verifyEmail/sendLink`, { email: userData.email, url: window.location.origin, clientId: userDetails.clientId }, { headers: { accessToken: localStorage.getItem('AccessToken') } });
      toast('Email Sent! Please Check Inbox', { type: 'success' });
    } catch (err) {
      httpStatus(err)
    }
  }

  const openReferAndEarnModal = async () => {
    try {
      const res = await Axios.get(`/customers/refercode`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
      setReferCode(res.data.data?.[0]?.referCode);
      setOpenReferModal(true);
    } catch (err) {
      httpStatus(err);
    }
  }

  return (
    <>
    <h1 className='header-heading-text hide-sm'>Hello <span className='heading-span'>{userData.firstName}{' '}{userData.lastName},</span></h1>
    <div className='header-container'>
      <div className='header-heading'>
        <h1 className='header-heading-text hide-xs'>Hello <span className='heading-span'>{userData.firstName}{' '}{userData.lastName},</span></h1>
        <div className='heading-text'>Welcome Back! {userData.verified === 0 && <span className='verify-email-text hide-xs'> <span className='link cursor' onClick={() => verifyEmailHandler()}> Please verify your email </span></span>}</div>
      </div>
      <div className='notification-profile-container'>
        {/* {window.innerWidth>992 && <div className='eraned-points'>
          <div className='border-points'>
            <div className='points-text'>Total Earned Points</div>
            <img src={Points} alt='points-img' className='header-points-img' />
            <span className='points-value'>{userData.totalPoints}</span>
          </div>
          <div className='border-dollar'>
            <div className='heading-text'>Dollars</div>
            <img src={Dollars} alt='dollar-img' className='header-dollar-img' />
            <span className='dollars-value'>{userData.totalPoints?userData.totalPoints/4:0}</span>
          </div>
        </div>} */}
        <div className='notification-container' onClick={() => navigate('/wallet')}>
          <div className='wallet-bader'>
            <div className='wallet-border'>
              <span class="material-symbols-outlined wallet-bader">
                account_balance_wallet
                <span className='wallet-value'>${walletBalance}</span>
              </span>
            </div>
          </div>
          {/* <span className="material-symbols-outlined bell-icon" onClick={() => window.innerWidth <= 992 && navigate('/notifications', { state: notifications })}>notifications</span> */}
          {/* <div className='notification-batch'>3</div> */}
          {/* {window.innerWidth > 992 && <div className='notification-hover-content'><Notifications notifications={notifications} /></div>} */}
          {<div className='wallet-hover'>Wallet</div>}
          {/* </div> */}
        </div>
        <div className='notification-container'>
          <div className='bell-batch'>
            <span className={newNotify ? "material-symbols-outlined bell-icon new-notity":"material-symbols-outlined bell-icon"} 
            onClick={() => window.innerWidth <= 992 && navigate('/notifications', { state: notifications })}>
              {newNotify?"notifications_active":"notifications"}{newNotify?<span className='notifi-after'>{newNotify}</span>:null}</span>
            {/* <div className='notification-batch'>3</div> */}
            {window.innerWidth > 992 && <div className='notification-hover-content'><Notifications notifications={notifications} /></div>}
          </div>
        </div>
        <div className='profile-container'>
          <img src={userData.avatar ? `${userData.avatar}?${Math.random()}` : HeaderProfileIcon} alt='profile-img' className='header-profile-img' />
          <div className='profile-name'>{userData.firstName}{' '}{userData.lastName}</div>
          <span className="material-symbols-outlined">expand_more</span>
          <div className='profile-hover-content'>
            <div className='hover-button' onClick={() => navigate('/wallet')}>Wallet</div>
            <div className='hover-button' onClick={() => openReferAndEarnModal()}>Refer & Earn</div>
            <div className='hover-button' onClick={() => logoutHandler()}>Logout</div>
          </div>
        </div>
      </div>
      <ReferAndEarnModal show={openReferModal} setShow={setOpenReferModal} referCode={referCode} />
    </div>
          {userData.verified === 0 && <span className='verify-email-text hide-sm'> <span className='link cursor' onClick={() => verifyEmailHandler()}> Please verify your email </span></span>}
          </>)
}

export default Header