import React, { useEffect } from 'react';
import './paymentSuccess.css';
import { illustration } from '../../assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';


const PaymentSuccess = () => {
    const location = useLocation();
    const { transaction_id,packageIds,finalTotal,totalOrder } = location.state;
    const navigate = useNavigate();
     useEffect(()=>{(()=>{
        if(transaction_id && process.env.REACT_APP_MODE==='PROD'){
            window.gtag('config', 'AW-10956689427');
            window.gtag('event', 'conversion', {'send_to': 'AW-10956689427/y3xrCJ_y3fwYEJOgx-go',
            'value': finalTotal,
            'currency': 'USD',
            'transaction_id': transaction_id});
        }
     })()},[])

    return (
        <div className='payment'>
            <div className='payment-card'>
                <img src={illustration} alt='payment-img' className='payment-img' />
                <div className='payment-thank'>Thank You</div>
                <div className='payment-msg'>Your payment was successfully completed</div>
                <div className='payment-paid'>Total amount paid</div>
                <div className='payment-amount'>${totalOrder}</div>
                {packageIds && (<div className='payment-package'>Package ID : {packageIds.join(',')}</div>)}
                {packageIds &&(<div className='payment-text'>The package will be shipped shortly,<br />the tracking number will be shared.</div>)}
                <div className='payment-button' onClick={()=>navigate('/dashboard')}>
                    <div className='payment-dashboard'>Return to Dashboard</div>
                </div>
            </div>
        </div>
    )
}

export { PaymentSuccess }