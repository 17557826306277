import React from 'react';
import './AddAddressModal.css';
import { useNavigate } from 'react-router-dom';

const PaymentFailed = ({failPaymentMessage, isOpen,setFlagPaymentFail,setIsLoading,setFlagPaymentSuccess})=>{
    const navigate = useNavigate()

    const setFaildFlag = ()=>{
        if (failPaymentMessage==='NOTSHIPPED'){
            navigate('/support')
        }else{
            setFlagPaymentSuccess(false)
            setFlagPaymentFail(false)
            setIsLoading(false)
        }
    }
    
    return(
        <div className={ isOpen ? 'popup-div-active' : 'popup-div'}>
       { isOpen && 
        <div className='payment'>
        <div className='payment-card-succ payment-card-succ-div'>
            <img src='/images/payment-fail.svg' alt='payment-load' className='' />
            <div className='payment-wait'>{failPaymentMessage !=='NOTSHIPPED'?"Payment Failed":"Shipment Not Creadted"}</div>
            {failPaymentMessage !=='NOTSHIPPED' ? <div className='payment-fail-msg'>{failPaymentMessage}</div>:<div className='payment-sucess-msg'>Payment Successful</div>}
            <div className='payment-wait-msg'>Hey there, seems like there was some trouble. <br/>we are there with you. Just hold back.</div>
            <div className='payment-gray'>{failPaymentMessage!=='NOTSHIPPED' ? "Please update your payment method below to continue":"Shipment not created Please Contact customer support"}</div>
            <div className='update-btn' onClick={()=>setFaildFlag()}>{failPaymentMessage === 'NOTSHIPPED'?"Customer Support":"Update Payment method"}</div>
        </div>
    </div>
       }
    </div>
    )
}

export default PaymentFailed