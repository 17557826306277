import { useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Axios } from '../../hooks';
import AuthModal from './AuthModal';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import './socialAuth.module.css';
import { toast } from '../../components/index';
import { setUserDetails } from '../../store/userDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { setAuthentication } from '../../store/authenticationSlice';
import { deviceDetect } from 'react-device-detect';
import { httpStatus } from '../../utils';


const SocialAuth = (props) => {
  const { browserName,browserFullVersion,engineName,engineVersion,osName,osVersion } = deviceDetect()
  const { page } = props;
  const [googleUser, setGoogleUser] = useState(false);
  const [facebookUser, setFacebookUser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { settings } = useSelector( state => state.userData );

  const checkUser = async (cred) => {
    if (props.page === "login") {
    try {
      let data = Object.assign({}, cred);
      delete data.lastName;
      delete data.firstName;
      delete data.fullName;

      const response = await Axios.post(`/customers/login`, { ...data,clientId: settings.clientId , socialAuth: true,
      browserName:browserName,browserFullVersion:browserFullVersion,engineName:engineName,engineVersion:engineVersion,osName:osName,osVersion:osVersion})
      localStorage.setItem('AccessToken', response.data.data.accessToken);
      dispatch(setUserDetails(response.data.data.user));
      toast('Login Success', { type : 'success' });
      dispatch(setAuthentication(true));
      navigate('/dashboard');
    } catch (err) {
      httpStatus(err);
    }
  }

  if( props.page === 'register'){
      setShowModal(true);
  }
  }

  const responseGoogle = async (response) => {
    if (response.error==="popup_closed_by_user") {
      setGoogleUser(false)
      setFacebookUser(false)
    }

    const { email, familyName, givenName, googleId, name } = response.profileObj;

    setGoogleUser({
      email,
      lastName: familyName,
      firstName: givenName,
      fullName: name,
      password: googleId
    })

    const userDetails = {
      email,
      lastName: familyName,
      firstName: givenName,
      fullName: name,
      password: googleId
    };
    setFacebookUser(false)

    await checkUser(userDetails);
  }

  const handleModalClose = (bool) => { setShowModal(bool) }

  const responseFacebook = async (response) => {
    if (response.status==="unknown") {
      setGoogleUser(false)
      setFacebookUser(false)
    }
    const { email, name, userID } = response;

    const userDetails = {
      email,
      lastName: name?.split(' ')[1],
      firstName: name?.split(' ')[0],
      fullName: name,
      password: userID
    };

    setGoogleUser(false)
    setFacebookUser(userDetails)
    await checkUser(userDetails);

  }

  const customGoogleBtn = (props) => {
    return (
      page === 'login'
      ? <div onClick={props.onClick} className='login-with-google-button button'>
          <img width={25} src='/images/googleIcon.svg' alt='google-img' />
          <span className='google-button-text'> Google </span>
        </div>
      : <img onClick={props.onClick} width={25} src='/images/googleIcon.svg' alt='google-img' />
    )
  }

  const customFaceBookBtn = (props) => {
    return (
      page === 'login'
      ? <div onClick={props.onClick} className='login-with-facebook-button button'>
          <img width={25} src='/images/facebookIcon.svg' alt='facebook-img' /> 
          <span className='facebook-button-text'> Facebook </span>
        </div>
      : <img onClick={props.onClick} width={25} src='/images/facebookIcon.svg' alt='facebook-img' />
    )
  }

  return (
    <div style={ page === 'login' ? { display: 'flex', gap: '1rem' , width: '100%', marginTop: '1rem' } : { display: 'flex', gap: '1rem' , }}>
      {settings.googleKeyForLogin?<GoogleLogin
        clientId={settings.googleKeyForLogin}
        buttonText="Login"
        onSuccess={responseGoogle}
        render={renderProps => (customGoogleBtn(renderProps))}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />:null}
      {settings.facebookKeyForLogin?<FacebookLogin
        appId={settings.facebookKeyForLogin}
        autoLoad={false}
        fields="name,email"
        textButton="Facebook"
        callback={responseFacebook}
        render={renderProps => (customFaceBookBtn(renderProps))}
        cssClass='kep-login-facebook' 
        style={{ borderRadius: '36px'}}
        />:null}
      <AuthModal show={showModal} handleModalClose={handleModalClose}
        user={(googleUser) ? googleUser : facebookUser} toast={toast} socialType={(googleUser) ? "googleId" : "facebookId"} />
    </div>
  )
}

export { SocialAuth };