import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: {},
  userAddresses: [],
  userCardDetails: [],
  settings:{},
  refreshUserData: false,
  ticketPackages:[],
  flagNotifications:true,
  updateWallet:true
}

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    setUserDetails: (state , action) => {
      state.userDetails = action.payload
    },
    setUserAddresses: (state , action) => {
        state.userAddresses = action.payload
    },
    setUserCardDetails: (state , action) => {
        state.userCardDetails = action.payload
    },
    setSettings:(state,action)=>{
      state.settings = action.payload
    },
    setTicketPackages:(state,action)=>{
      state.ticketPackages = action.payload
    },
    setFlagNotifications:(state,action)=>{
      state.flagNotifications = action.payload
    },
    setWalletBalance:(state,action)=>{
      state.updateWallet = action.payload
    },
    refreshUserData:(state)=>{
      state.settings = {}
      state.userCardDetails = []
      state.userAddresses = []
      state.userDetails = {}
      state.refreshUserData = !state.refreshUserData
    },
    resetUserData: (state) => {
      state.settings = {}
      state.userCardDetails = []
      state.userAddresses = []
      state.userDetails = {}
    }
  },
})

export const { setUserDetails, setUserAddresses, setUserCardDetails,setSettings , refreshUserData, resetUserData,setTicketPackages,setFlagNotifications,setWalletBalance } = userDetailsSlice.actions

export default userDetailsSlice.reducer