import React from 'react';
import { Spinner } from 'react-bootstrap';
import './ShippingCarriersList.css';
import { toast } from '../../components';
import { Axios } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { httpStatus } from '../../utils';

const ShippingCarriersList = ({ packagesToCheckout, packagesDetails , isLoading , carriers,state, selectedShippingCarrier, isShippingCalcPage }) => {
  const navigate = useNavigate();
  
  const saveAndContinue = async() => {
    let flag=true;
    if(packagesToCheckout.length === Object.keys(packagesDetails).length ){
      for(let i of Object.keys(packagesDetails)){
        if(!packagesDetails[i].selectedCarrier){
          flag=false;
          toast( `Please Select Shipping Carrier for Package ${i}` , { type : 'info' })}
      }
    
    }else{
      flag=false;
      toast( `Please Select Shipping Carrier for Another Packages` , { type : 'info' });
    }
    if(flag){
    for(let i of Object.keys(packagesDetails)){
      try{
          await Axios.put(`/packages/${i}`, { shippingAddress: packagesDetails[i].selectedAddress , shippingMethod: packagesDetails[i].selectedCarrier.id , shipmentCost: packagesDetails[i].selectedCarrier.amount , extras: packagesDetails[i].selectedExtras } , { headers : { accessToken: localStorage.getItem('AccessToken')}} );
        }catch(err){
          httpStatus(err);
        }
    }
  }
  flag && navigate('/dashboard/review-order' , { state : packagesToCheckout } );
  }
      
  return (
    isShippingCalcPage ?
    <>
      {/* <div className='sort-buttons-icon'>
        <div className='sort-icon-text'>
          <img src='/images/sort-icon.svg' alt='sort-icon' className='sort-icon' />
          <div className='sort-text'>sort by</div>
        </div>
        <div className='sort-buttons'>
          <div className='sort-button cursor' onClick={() => filterHandler()}>Cheapest</div>
        </div>
      </div> */}
      <div className='carriers-container'>
        { carriers.length > 0 
        ? carriers.map( carrier => 
          <div className={ 'carrier-container cursor' } key={carrier.id} onClick={() => selectedShippingCarrier(carrier)}>
            <img src={carrier.provider_image_75} alt='shipping-carrier-img' />
            <div className='carrier-name-days'>
            <div className='carrier-name'>{carrier.serviceName}</div>
              <div className='carrier-days'>{carrier.duration}</div>
            </div>
            <div className='carrier-amount'>{carrier.amount} {' '} USD</div>
          </div>)
        : isLoading 
        ? <div className='spinner'><Spinner size='lg' /></div>
        : ( <div className='oops-container'>
              <img src='/images/oops-img.svg' alt='oops-img' className='oops-img' />
              {
                isShippingCalcPage
                ? <div className='gray-text bold-gray-text'>Please Select address, package and <br /> weight to get costs</div>
                : <div className='gray-text bold-gray-text'>Select address to get costs</div>
              }
            </div>
          )
        }
      </div>
    </>
    :
    <>
    { 
      packagesDetails?.[state.id]?.carriers?.length > 0 && packagesDetails?.[state.id]?.selectedAddress
      ?   <div className='carriers-container'>
            <div className='shipping-carrier-heading'>Choose your carrier & delivery speed</div>
              <div className='shipping-carriers-container'>
              { packagesDetails[state.id].carriers && packagesDetails[state.id].carriers.map( carrier => (
                <div className={ packagesDetails && packagesDetails[state.id] && packagesDetails[state.id].selectedCarrier && carrier.id === packagesDetails[state.id].selectedCarrier.id ? 'selected-carrier-container cursor' : 'carrier-container cursor' } key={carrier.id} onClick={() => selectedShippingCarrier(carrier)}>
                    <img src={carrier.provider_image_75} alt='shipping-carrier-img' />
                  <div className='carrier-name-days'>
                  <div className='carrier-name'>{carrier.serviceName}</div>
                    <div className='carrier-days'>{carrier.duration}</div>
                  </div>
                  <div className='carrier-amount'>{carrier.amount} {' '} USD</div>
                </div>
              ))}
              {
              !isShippingCalcPage &&
              <div className={ packagesDetails && packagesDetails[state.id] && Object.keys(packagesDetails[state.id]).length > 0 ?  'carriers-save-button cursor' : 'carriers-save-button cursor disabled' } onClick={() => saveAndContinue()}>Save & Continue <span className="material-symbols-outlined text-white-bold cursor">chevron_right</span></div>
              }
            </div>
          </div>
      : isLoading 
      ? <div className='spinner'><Spinner size='lg' /></div>
      : <div className='oops-container'>
            <img src='/images/oops-img.svg' alt='oops-img' className='oops-img' />
            {
              isShippingCalcPage
              ? <div className='gray-text bold-gray-text'>Please Select address, package and <br /> weight to get costs</div>
              : <div className='gray-text bold-gray-text'>Please Select address to get costs</div>
            }
        </div> 
    }
   </>
  )
}
export default ShippingCarriersList