import React , { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Axios } from '../../hooks';
import { refreshUserData } from '../../store/userDetailsSlice';
import { httpStatus } from '../../utils';
import './VerifyEmailPage.css';

const VerifyEmailPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        (async() => {
            try{
                await Axios.post('customers/verifyEmail/checkHash', { hash: location.pathname.split('/')[2] }, { headers: { accessToken: localStorage.getItem('AccessToken')}});
                dispatch(refreshUserData());
                setTimeout(() => localStorage.getItem('AccessToken') ? navigate('/dashboard') : navigate('/') ,3000);
              }catch(err){
                httpStatus(err);
              }
        })();
    }, []);
    
  return (
    <div className='verify-email-container'>
        <h2>Verifying your email...</h2>
        <Spinner />
    </div>
  )
}

export default VerifyEmailPage