import React,{useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { getTimeDifference,httpStatus,getNotificationImage} from '../../utils';
import { hotjar } from 'react-hotjar';
import { Axios } from '../../hooks';
import { toast } from '../../components';
import './NotificationsPage.css'
import { useSelector } from 'react-redux';

const NotificationsPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [newNotify,setNewNotify] = useState(false)
    const navigate = useNavigate();
    const [updatedNotify, setUpdatedNotify] = useState(false)
    const { userDetails } = useSelector((state) => state.userData);

    useEffect(() => {
      hotjar.initialize(3576088, 6);
    }, []);
  
    useEffect(() => {
      (async () => {
        try {
          const res = await Axios.get(`/customers/notifications?full=true`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
          setNotifications(res.data.data);
          setNewNotify(res?.data?.data.map(n=>n.saw).some(s=>s===0)?true:false)
        } catch (err) {
          toast(err.response.data.data, { type: 'error' });
        }
      })();
    }, [newNotify]);

    const updateNotify = async(id,saw,ticketId)=>{ 
      if(saw!==1){
        try{
          await Axios.put(`/customers/notifications/${id}`,{}, { headers : { accessToken : localStorage.getItem('AccessToken')}});
          navigate('/support',{state:ticketId})
          setUpdatedNotify(updatedNotify?false:true)
        }catch(e){
          httpStatus(e)
        }
      }
      else{
        navigate('/support',{state:ticketId})
      }
    }

    return (
        <div className='recent-activity-container'>
            <div className='page-headings'>
              <div className='checkout-calc-heading-subheading'>
                  <div className='shipping-carrier-heading'> Notifications </div>
              </div>
            </div>
            <div className='recent-activities-container1'>
              { notifications.length > 0 
              ? notifications.map( ({ id, text,saw,ticketId, notifyTime,ticketType }) => 
              (
                <div className={saw===1?'seenmessage nac-flex':' nac-flex cursor'} key={id} onClick={()=>updateNotify(id,saw,ticketId)}>
                  
                  <div className='activity-image-heading-content'>
                  <img src={getNotificationImage(ticketType)} alt='profile-img' className='noti-img'/>
                    <div className='activity-heading-content'>
                      <div>{text}</div>
                    </div>
                  </div>
                  <div className='activity-time w-210'>{getTimeDifference(userDetails.timeZone,notifyTime)}</div>
                </div>
              ))
              :  <h3>No Notifications</h3>
              }
            </div>
        </div>
      )
}

export default NotificationsPage