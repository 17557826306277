import React, { useState, useEffect } from 'react';
import { Axios } from '../../hooks';
import { CustomizationModal, ImagesSliderModal, QuestionnaireModal, RequestPhotosModal, SummaryModal, ConsolidatePopup,ConsolidateSeparationPopup } from '../../Modals';
import AccordianForPackage from '../Accordians/AccordianForPackage/AccordianForPackage';
import { toast } from '../../components';
import { httpStatus, statusImgById, statusNameById } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './Card.css';
import { refreshTopSectionData } from '../../store/topSectionData';
import { Spinner } from 'react-bootstrap';

const PackageDetailCard = ({ allCustomizations, customizations, setCustomizations, unSelectPackage, selectedPackage, consolidate, setSelectedPackage, alreadySelectedCustomization, setAlreadySelectedCustomization, allPackages, setPackages }) => {
    const [openRequestPhotosModal, setOpenRequestPhotosModal] = useState(false);
    const [openCustomizationModal, setOpenCustomizationModal] = useState(false);
    const [openQuestionnaireModal, setOpenQuestionnaireModal] = useState(false);
    const [packageImages, setPackageImages] = useState([]);
    const [openSummaryModal, setOpenSummaryModal] = useState(false);
    const [separationPopup, setSeparationPopup] = useState(false);
    const [dimensions, setDimensions] = useState({});
    const [packageContent, setPackageContent] = useState([]);
    const navigate = useNavigate();
    const { userDetails } = useSelector(state => state.userData);
    // const [ alreadySelectedCustomization , setAlreadySelectedCustomization ] = useState([]);
    const [openImagesModal, setOpenImagesModal] = useState(false);
    // const [ checkCustomization , setCheckCustomization ]=useState({});
    const [customizationsData, setCustomizationsData] = useState([]);
    const dispatch = useDispatch();
    const [separationLoading, setSeparationLoading] = useState(false);

    const setSeparate = () => setSeparationPopup(true)

    useEffect(() => {
        if (allCustomizations.length > 0) {
            setCustomizationsData(allCustomizations.filter(customization => customization.name === 'Custom Photos Request' || customization.name === 'Consolidation Separation' || customization.name === 'Consolidation'));
        }
    }, [allCustomizations]);

    useEffect(() => {
        if (Object.keys(selectedPackage).length) {
            setDimensions(JSON.parse(selectedPackage.dimensions));
            setPackageContent(selectedPackage.packageContent);
            (async () => {
                try {
                    const res = await Axios.get(`/packages/images/${selectedPackage.id}`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
                    res.data?.data[selectedPackage.id].length > 0
                        ? setPackageImages({ images: JSON.parse(res.data?.data[selectedPackage.id]?.[0]?.images), verified: res.data?.data[selectedPackage.id]?.[0]?.verified })
                        : setPackageImages({ images: [], verified: 0 });
                } catch (err) {
                    httpStatus(err)
                }
            })();
        }
    }, [selectedPackage]);

    const forwordPackage = () => {
        if (userDetails.verified === 1) {
            if (selectedPackage.status <= 2) {
                toast("Please fill out the questionnaire!", { type: 'info' });
            }else if(selectedPackage.status>9){
                navigate('/support',{state:selectedPackage.id})
            } else {
                setPackageImages([])
                navigate('/dashboard/checkout-shipping-calculator', { state: { packagesToCheckout: [selectedPackage], isMultiplePakage: true } });
            }
        } else {
            toast("Please verify your email!", { type: 'info' });
        }
    }
    //    useEffect(() => {
    //     (async() => {
    //         try{
    //           const allCustomizationsRes = await Axios.get(`/packages/extras`, { headers: { accessToken: localStorage.getItem('AccessToken')}});
    //           console.log(allCustomizationsRes)
    //           setCustomizations(allCustomizationsRes.data.data.filter( customization => customization.name !== 'Photos Request' && customization.name !== 'Consolidation Separation'));
    //           setCustomizationsData(allCustomizationsRes.data.data.filter( customization => customization.name === 'Photos Request' || customization.name === 'Consolidation Separation'));
    //         }catch(err){
    //           toast( err.response.data.error , { type : 'error' });
    //         }
    //     })();
    //     },[ ] );

    //   useEffect(() => {
    //     (async() => {
    //       try{ 
    //         const packageExtrasres = await Axios.get(`/packages/extras/${selectedPackage.id}` , { headers : { accessToken: localStorage.getItem('AccessToken')}});
    //         setAlreadySelectedCustomization(packageExtrasres.data.data[selectedPackage.id].map( extras => extras.extrasId));
    //         console.log(packageExtrasres)
    //         console.log(packageExtrasres.data.data[selectedPackage.id].map( extras => extras.extrasId))
    //         // setCustomizations( prev => prev.map( prevExtras => (packageExtrasres.data.data[selectedPackage.id].map( extras => extras.extrasId)).includes(prevExtras.id) ? {...prevExtras , selected: true } : { ...prevExtras , selected: false }));
    //         // setCheckCustomization(packageExtrasres.data.data[selectedPackage.id].map( extras => extras))
    //     }catch(err){
    //         toast( err.response.data.error , { type : 'error' });
    //       }
    //     })();
    //    },[ selectedPackage ]);


    //   const checkCondencePackageIsDone = () => {
    //     let selecedCustomization = customizations.filter( c => alreadySelectedCustomization?.map( c => c.extrasId).includes(c.id));
    //     if( selecedCustomization.filter( c => c.name === 'Condense package').length > 0){
    //         let data = alreadySelectedCustomization?.filter( c => c.extrasId === selecedCustomization.filter( c => c.name === 'Condense package')[0].id )[0];
    //         return data.isDone === 0 ? true : false
    //     }else{
    //         return false;
    //     }
    //   }

    const imgPaymentProcess = async () => {
        try {
            const res = await Axios.get(`/customers/imagecost`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
            navigate('/dashboard/payment-method', { state: { imagesCost: res.data.data[0]?.imagesCost, state: [selectedPackage.id] } });
        } catch (err) {
            httpStatus(err);
        }
    }

    const consolidationSeparation = async () => {
        setSeparationLoading(true);
        try {
            const res = await Axios.put(`/packages/extras/consolidationseparation/${selectedPackage.id}`, {}, { headers: { accessToken: localStorage.getItem('AccessToken') } });
            dispatch(refreshTopSectionData());
            toast(res.data.data, { type: 'success' });
            setSeparationLoading(false);
            setSelectedPackage({});
        } catch (err) {
            setSeparationLoading(false);
            toast(err.response.data.error, { type: 'error' });
        }
    }

    const getCostOfCustomizationByName = (customizationName) => {
        return customizationsData.filter(customization => customization.name === customizationName)?.[0]?.cost || ''
    }


    return (
        <div className='package-content-container'>
            {(selectedPackage.status === 10 || selectedPackage.status === 11) ? <div className='hold-btn'>Your Package on hold</div>:selectedPackage.status === 12&&<div className='hold-btn'>Your Package to return</div> } 
            <div className={(selectedPackage.status === 10 || selectedPackage.status === 11 || selectedPackage.status === 12) ? 'blue-div':null}>
                <div className='package-name-img'>
                    <div className='package-name-number'>
                        <div className='package-name'>{packageContent[0]?.name}</div>
                        <div className='gray-text'>{selectedPackage.incomingTrack}</div>
                    </div>
                   <div className='package-img'>
                        <img src={statusImgById(selectedPackage.status)} alt='package-img' className='package-status-img' width={65} />
                        <div className='gray-bold--text'>{statusNameById(selectedPackage.status)}</div>
                    </div>
                </div>
                {
                    dimensions &&
                    (
                        <div className='package-dimensions'>
                            <div className='package-content-card-heading'>Dimensions</div>
                            <div className='package-dimension'>{dimensions.length} x {dimensions.width} x {dimensions.height} in | {selectedPackage.weight} lb</div>
                        </div>
                    )
                }
                {
                selectedPackage && selectedPackage.packageContent && !selectedPackage.consolidate &&  selectedPackage.packageContent.length > 0 &&(<>
                    <div className='package-content-card'>Package Content</div>
                    <div className='package-table-headings'>
                        <div className='package-table-heading flex04 text-center'>Name</div>
                        <div className='package-table-heading flex04 text-center'>Quantity</div>
                        <div className='package-table-heading flex04 text-center'>Unit Cost</div>
                        <div className='package-table-heading flex04 text-center'>Total</div>
                    </div>
                    {selectedPackage && selectedPackage.packageContent && selectedPackage.packageContent.length>0 && 
                            selectedPackage.packageContent.map(c=>
                            <div className='package-table-headings' key={c.id}>
                            <div className='package-table-data flex04 text-center'>{c.name}</div>
                            <div className='package-table-data flex04 text-center'>{c.quantity}</div>
                            <div className='package-table-data flex04 text-center'>{c.unitCost}</div>
                            <div className='package-table-data flex04 text-center'>{c.quantity*c.unitCost}</div>
                            </div>)}
                    </>)
                }
                </div>
                {
                    (
                        <>
                            <div className='package-imgs'>
                                <div className='package-content-card-heading'>Package Images</div>
                                <div className='package-content-package-images'>
                                    {
                                        packageImages?.images?.length > 0
                                            ? packageImages.images.map(packageImg => <img title={packageImages.verified ? 'Click here to enlarge the package image.' : 'Click here to pay for package images.'} onClick={() => packageImages.verified ? setOpenImagesModal(true) : imgPaymentProcess()} key={packageImg} src={packageImages.verified ? packageImg : '/images/default-pack-img.jpg'} alt='package-img' className='package-detail-package-img' />)
                                            : 'No Images'
                                    }
                                </div>
                            </div>
                            {packageImages.verified ? <div className='request-photos-button cursor' onClick={() => setOpenRequestPhotosModal(true)}>
                                <img src='/images/requestImages.svg' alt='request-photos-img' />
                                <div className='request-photo-button-text'>Custom Photos for Package ${getCostOfCustomizationByName('Custom Photos Request')}</div>
                            </div>:null}
                        </>
                    )
                }
                <div className={(selectedPackage.status === 10 || selectedPackage.status === 11 || selectedPackage.status === 12) ? 'blue-div':null}>
                {
                    consolidate &&
                    (
                        <div className='consolidated-packages'>
                            {
                                selectedPackage.consolidatedPackageId.map(consolidatedPackage => <div key={consolidatedPackage.id}><AccordianForPackage isShipped={false} packageData={consolidatedPackage} showId={false} photoRequestCost={getCostOfCustomizationByName('Custom Photos Request')} /></div>)
                            }
                        </div>
                    )
                }
                <div className='two-buttons'>
                    {selectedPackage.status > 2 ? <div className='border-button cursor' onClick={() => setOpenCustomizationModal(true)}>
                        <div></div>
                        <div className='button-text'> Customization</div>
                    </div> :
                        <div className='border-button cursor' onClick={() => setOpenQuestionnaireModal(true)}>
                            <div></div>
                            <div className='button-text'> Questionnaire</div>
                        </div>}
                    <div className='border-button cursor' onClick={() => setOpenSummaryModal(true)}>
                        <img src='/images/summary-img.svg' alt='summary-img' />
                        <div className='button-text'>Summary</div>
                    </div>
                </div>
                {
                    consolidate &&
                    <div className='forward-package-button primary-button cursor' onClick={()=>setSeparate()}>
                        <div className={`forward-package-button-text ${separationLoading && 'disabled'}`}>Consolidation Separation ${getCostOfCustomizationByName('Consolidation Separation')} / Package {' '} {separationLoading && <Spinner size='sm' />}</div>
                        {/* <span className="material-symbols-outlined">chevron_right</span> */}
                    </div>
                }
            </div>
            {(selectedPackage.status !== 10 && selectedPackage.status !== 11 && selectedPackage.status !== 12) ?<div className='forward-package-button cursor' onClick={() => forwordPackage()}>
                <div className='forward-package-button-text'>Forward Package</div>
                <span className="material-symbols-outlined">chevron_right</span>
            </div>:
            <div className='forward-package-button cursor' onClick={() => navigate('/support',{state:selectedPackage.id})}>
                <div className='forward-package-button-text'>Contact Customer Support</div>
                <span className="material-symbols-outlined">chevron_right</span>
            </div>}
            <ImagesSliderModal setIsOpenImages={setOpenImagesModal} isOpen={openImagesModal} images={packageImages.images?.map(element => ({ source: element }))} />
            <SummaryModal show={openSummaryModal} setShow={setOpenSummaryModal} selectedPackage={selectedPackage} />
            <RequestPhotosModal show={openRequestPhotosModal} setShow={setOpenRequestPhotosModal} setSelectedPackage={setSelectedPackage} selectedPackage={selectedPackage} allPackages={allPackages} setPackages={setPackages} />
            <CustomizationModal unSelectPackage={unSelectPackage} show={openCustomizationModal} setShow={setOpenCustomizationModal} setSelectedPackage={setSelectedPackage} selectedPackage={selectedPackage} alreadySelectedCustomization={alreadySelectedCustomization} customizations={customizations} setCustomizations={setCustomizations} setAlreadySelectedCustomization={setAlreadySelectedCustomization} allPackages={allPackages} setPackages={setPackages} />
            <QuestionnaireModal unSelectPackage={unSelectPackage} show={openQuestionnaireModal} setShow={setOpenQuestionnaireModal} setSelectedPackage={setSelectedPackage} selectedPackage={selectedPackage} />
            <ConsolidateSeparationPopup show={separationPopup} setShow={setSeparationPopup} consolidationSeparation={consolidationSeparation} />
        </div>
    )
}

export default PackageDetailCard