import React, { useState, useEffect } from 'react';
import './wallet.css'
import { Wallet, AddMoney } from '../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { Axios } from '../../hooks';
import { httpStatus } from '../../utils';

const WalletPage = () => {
    const navigate = useNavigate();
    const [walletData, setWalletData] = useState([])
    const [balance, setBalance] = useState(0);

    useEffect(()=>{
        (async ()=>{
            try{
                const res = await Axios.get('/customers/wallet',{ headers: { accessToken: localStorage.getItem('AccessToken') } })
                setWalletData(res.data.data)
                setBalance(res.data.data[0]?.balance)
            }catch(err){
                httpStatus(err)
            }
        })()
    },[])

    return (
        <div>
            <h3 className='wallet-name'>Wallet</h3>
            <div className='wallet-money'>
                <div className='wallet-money-ava-left'>
                    <div className="wallet-money-ava ">
                        <div className='wallet-img'>
                            <h5>Available balance</h5>
                            <img src={Wallet} alt='dollar-img' className='header-dollar-img' />
                        </div>
                        <h2 className='wallet-amount'>${balance?balance:'0.00'}</h2>
                    </div>
                    <div className="wallet-money-add" onClick={()=>navigate('/wallet/payment',{state:{balance}})}>
                            <div className='addmoney-img'>
                                <img src={AddMoney} alt='dollar-img' className='' />
                                <h6>Add Money</h6>
                                <p>Add money to wallet</p>
                            </div>
                    </div>
                </div>
                <div className='wallet-money-ava-right'>
                    <div className="wallet-money-ava table-responsive">
                        <div className='wallet-table-header'>
                            <div className='wth-1'>
                                <h5>Transactions</h5>
                                <h6 onClick={()=>{navigate('/wallet/transactions')}}>View all</h6>
                            </div>
                            <div>
                                <h6>Last 10 Transactions</h6>
                            </div>
                        </div>
                        <table className="wallet-table hide-xs">
                            <thead>
                                <tr>
                                <th scope="col">Transaction Time</th>
                                <th scope="col">Message</th>
                                <th scope="col">Debit</th>
                                <th scope="col">Credit</th>
                                <th scope="col">Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {walletData.map(w=>
                                <tr>
                                <td>{w.paymentTime.replace('T',' ').slice(0,19)}</td>
                                <td>{w.message}</td>
                                <td>{w.debitAmount}</td>
                                <td>{w.creditAmount}</td>
                                <td>{w.balance}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                        {walletData.map(w=>
                            <div className='hide-sm transaction-mobile'>
                                <div className='mobile-tran-flex'>
                                    <p>Transaction Time</p>
                                    <p>{w.paymentTime.replace('T',' ').slice(0,19)}</p>
                                </div>
                                <div className='mobile-tran-flex'>
                                    <p>Message</p>
                                    <p>{w.message}</p>
                                </div>
                                <div className='mobile-tran-flex'>
                                    <p>Debit</p>
                                    <p>{w.debitAmount}</p>
                                </div>
                                <div className='mobile-tran-flex'>
                                    <p>Credit</p>
                                    <p>{w.creditAmount}</p>
                                </div>
                                <div className='mobile-tran-flex'>
                                    <p>Balance</p>
                                    <p>{w.balance}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    
                </div>

            </div>
        </div>
    )
}

export default WalletPage