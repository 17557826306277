import React, { useState, useEffect } from 'react';
import { Axios } from '../../hooks';
import { httpStatus } from '../../utils';
import './WalletTransactions.css'
import { useInput } from '../../hooks'
import { toast } from 'react-toastify';

const WalletTransactions = () =>{
    const [walletData, setWalletData] = useState([])
    const [filterDates, setFilterDates] = useState({ fromdate: '', todate: '' });
    const { inputHandler } = useInput();

    useEffect(()=>{
        (async ()=>{
            try{ 
                const res = await Axios.get('/customers/wallet?full=true',{ headers: { accessToken: localStorage.getItem('AccessToken') } })
                setWalletData(res.data.data)
            }catch(err){
                httpStatus(err)
            }
        })()
    },[])

    const filterByDateHandler = async (e) => {
        inputHandler(e, setFilterDates);
        if (filterDates.fromdate) {
          try {
            const res = await Axios.get(`/customers/wallet?fromDate=${filterDates.fromdate}&toDate=${e.target.value}`, { headers: { accessToken: localStorage.getItem('AccessToken') } });
            setWalletData(res.data.data)
          } catch (err) {
            httpStatus(err)
          }
        } else {
          toast('Please Enter Start Date', { type: 'info' });
        }
      }

    return (
        <>
         <h3 className='wallet-name'>Wallet Transactions</h3>
        <div className='wallet-money-full-transaction'>
                    <div className="wallet-money-ava">
                        <div className='wallet-table-header'>
                            <div className='wth-1'>
                                <h5>Transactions</h5>
                            </div>
                            <div className='wallet-filter'>
                                <p>From Date:</p>
                                <input type="date" id="from" name="fromdate" onChange={(e) => inputHandler(e, setFilterDates)} value={filterDates.fromdate}></input>
                                <p>To Date:</p>
                                <input type="date" id="from" name="todate" onChange={(e) => filterByDateHandler(e)} value={filterDates.todate}></input>
                                {/* <button>Filter</button> */}
                            </div>
                        </div>
                        <div className='table-responsive'>
                        <table className="wallet-table hide-xs">
                            <thead>
                                <tr>
                                <th scope="col">Transaction Time</th>
                                <th scope="col">Message</th>
                                <th scope="col">Debit</th>
                                <th scope="col">Credit</th>
                                <th scope="col">Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {walletData.map(w=>
                                <tr>
                                <td>{w.paymentTime.replace('T',' ').slice(0,19)}</td>
                                <td>{w.message}</td>
                                <td>{w.debitAmount}</td>
                                <td>{w.creditAmount}</td>
                                <td>{w.balance}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                        {walletData.map(w=>
                            <div className='hide-sm transaction-mobile'>
                                <div className='mobile-tran-flex'>
                                    <p>Transaction Time</p>
                                    <p>{w.paymentTime.replace('T',' ').slice(0,19)}</p>
                                </div>
                                <div className='mobile-tran-flex'>
                                    <p>Message</p>
                                    <p>{w.message}</p>
                                </div>
                                <div className='mobile-tran-flex'>
                                    <p>Debit</p>
                                    <p>{w.debitAmount}</p>
                                </div>
                                <div className='mobile-tran-flex'>
                                    <p>Credit</p>
                                    <p>{w.creditAmount}</p>
                                </div>
                                <div className='mobile-tran-flex'>
                                    <p>Balance</p>
                                    <p>{w.balance}</p>
                                </div>
                            </div>
                        )}
                        </div>
                        
                    </div>
                    
                </div>
                </>
    )
}

export default WalletTransactions