import React, { useState, useEffect, useRef } from 'react';
import './CustomerTickets.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { Axios, useInput, useUploadImage } from '../../hooks';
import { httpStatus, getTimeDifference, getTicketID } from '../../utils';
import { setTicketPackages,setFlagNotifications } from '../../store/userDetailsSlice';
import { useDispatch,useSelector } from 'react-redux';
import { ImagesSliderModal } from '../../Modals';
import { HeaderProfileIcon } from '../../assets/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'react-bootstrap';

const CustomerTicket = () => {
  const [tickets, setTickets] = useState(null)
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = location;
  const { userDetails,flagNotifications } = useSelector((state) => state.userData);
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false);
  const [messagesDetails, setMessagesDetails] = useState({ message: '', files: null,fileNames:'' })
  const { inputHandler } = useInput();
  const { uploadImage } = useUploadImage();
  const [updateMessages, setUpdateMessages] = useState(false);
  const [openImagesModal, setOpenImagesModal] = useState(false);
  const [images, setImages] = useState([]);
  const [selectTicket, setSelectTicket] = useState(null);
  const messageEl = useRef(null);
  
  useEffect(() => {
    (async () => {
      try {
        const customerTickets = await Axios.get(`/tickets`, { headers: { accessToken: localStorage.getItem('AccessToken') } })
        setTickets(customerTickets.data?.data?.map(t => {
          if ((state === t.id)||(t.packageId && state===t.packageId)) {
            return ({ ...t, selected: true })
          } else {
            return ({ ...t, selected: false })
          }
        }))
        dispatch(setTicketPackages(customerTickets.data?.data?.map(t => t.packageId)))
        state && setSelectTicket(customerTickets.data?.data?.filter(t => ((t.packageId && t.packageId===state)||(t.id === state)))?.map(t => ({ ...t, selected: true }))[0])
        setLoading(customerTickets?.data?.data?.length ? false : true)
        dispatch(setFlagNotifications(flagNotifications?false:true))
      } catch (e) {
        httpStatus(e)
      }
    })()
  }, [dispatch, state])
  
  useEffect(() => {
    if (messageEl) {
      messageEl.current?.lastElementChild?.scrollIntoView({ behavior: "smooth", block: "end" })
    }
  }, [])
  
  useEffect(() => {
    (async () => {
      try {
        if (selectTicket !== null && selectTicket !== undefined) {
          const tmessages = await Axios.get(`/tickets/messages?ticketId=${selectTicket.id}`, { headers: { accessToken: localStorage.getItem('AccessToken') } })
          let data = tmessages?.data?.data;
          if (data.length) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].customerOrstaff === 0) {
                data[i]['custAvatar'] = true;
                if (data[i - 1]) data[i - 1]['custAvatar'] = false;
              } else {
                data[i]['staffAvatar'] = true;
                if (data[i - 1]) data[i - 1]['staffAvatar'] = false;
              }
            }
          }
          setMessages(data)
          setMessagesDetails({ message: '', files: null,fileNames:'' })
        }
      } catch (e) {
        httpStatus(e)
      }
    })()
  }, [updateMessages, selectTicket])

  const uploadFiles = async () => {
    let urls = []
    for (let i = 0; i < messagesDetails.files.length; i++) {
      let imageUrl = await uploadImage({ image: messagesDetails.files[i], type: 'tickets', clientId: userDetails?.prefixSuite.toLowerCase() });
      urls.push(imageUrl.replace(/\s/g, '+'))
    }
    return urls
  };

  const changeStatus = async () => {
    try {
      await Axios.put(`/tickets/${selectTicket.id}`, { ticketStatus: selectTicket.ticketStatus !== 3 ? 3 : 4 }, { headers: { accessToken: localStorage.getItem('AccessToken') } })
      navigate('/dashboard')
    } catch (e) {
      httpStatus(e)
    }
  };

  const sunbmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let urls
      if (messagesDetails.files) {
        urls = await uploadFiles();
      }
      await Axios.post('/tickets/messages', { ...messagesDetails, ticketId: selectTicket.id, ticketStatus: selectTicket.ticketStatus, mediaUrls: JSON.stringify(urls) },
        { headers: { accessToken: localStorage.getItem('AccessToken') } })
      setMessagesDetails({ message: '', files: null,fileNames:'' })
      setLoading(false)
      setUpdateMessages(updateMessages ? false : true)
    } catch (e) {
      setLoading(false)
      httpStatus(e)
    }
  };
  const messageDataFunc = async () => { }

  const imagesView = (image) => {
    setOpenImagesModal(true)
    image && JSON.parse(image) && JSON.parse(image).map(i => images.push({ source: i }))
  };

  const selectedTicket = (ticket) => {
    setSelectTicket({ ...ticket, selected: true })
    setTickets(tickets.map(t => t.id === ticket.id ? { ...t, selected: true } : { ...t, selected: false }))
  };
  
  const setFiles=(e)=>{
    e.preventDefault();
    let names = []
      for (let i = 0; i < e.target.files.length; i++) {
        names.push(e.target.files[i].name)
      }
    setMessagesDetails(prev => ({ ...prev, files: e.target.files,fileNames:names.join() }))
  }

  return (
    <div className='div-ticket-outer'>
      <div className='div-ticket-header hide-xs'>
        <h2>Tickets</h2>
        <div className='cus-b-btn' id="new-ticket" onClick={() => { navigate('/support/new-ticket') }}>create ticket</div>
      </div>
      <div className='div-flex-1'>
        <div className='df-w-40'>
          <div className='div-ticket-header hide-sm'>
            <div className='cus-b-btn' onClick={() => { navigate('/support/new-ticket') }}>create ticket</div>
            <h2>Tickets</h2>
          </div>
          {tickets ? tickets.map(ticket =>
            <><div className={ticket && ticket.selected ? 'mobile-div-flex-outer ticket-selectcss' : 'mobile-div-flex-outer'} onClick={() => selectedTicket(ticket)}>
              <div className={ticket.ticketStatus === 1 ? 'm-d-flex open-c' : ticket.ticketStatus === 2 ? 'm-d-flex inprogress-c' : ticket.ticketStatus === 3 ? 'm-d-flex closed-c' : ticket.ticketStatus === 4 ? 'm-d-flex reopen-c' : null}>
                <div>Status</div>
                <div>{ticket.statusName}</div>
              </div>
              <div className='m-d-flex'>
                <div>Ticket ID </div>
                <div>{getTicketID(ticket.ticketType, ticket.packageId, ticket.id)}</div>
              </div>
              <div className='m-d-flex'>
                <div>Ticket Type</div>
                <div>{ticket.typesName}</div>
              </div>
              <div className='m-d-flex'>
                <div>Subject</div>
                <div>{ticket.packageId ? `${ticket.packageId} related issue` : ticket.subject}</div>
              </div>
            </div>
            {ticket.selected ?
              <div className='chat-dash hide-sm'>
              {selectTicket && selectTicket.selected ? 
              <div className='ticket-details'>
                <div className='message-div'>
                  <div className='mes-div-header'>
                    <div className='mes-d-h-flex'>
                      <img src={HeaderProfileIcon} alt='profile-img' className='header-profile-img' />
                      <div>
                        <h4>Subject: {selectTicket.packageId ? `${selectTicket.packageId} related issue` : selectTicket.subject}</h4>
                        <h6>{getTimeDifference(userDetails.timeZone,selectTicket.createdTime)}</h6>
                      </div>
                    </div>
                    <h5 className={selectTicket.ticketStatus === 1 ? 'hide-xs open-c' : selectTicket.ticketStatus === 2 ? 'hide-xs inprogress-c' : selectTicket.ticketStatus === 3 ? 'hide-xs closed-c' : selectTicket.ticketStatus === 4 ? 'hide-xs reopen-c' : null}>{selectTicket.statusName}</h5>

                    <button class="btn hide-xs" onClick={() => { changeStatus() }}>{selectTicket.ticketStatus !== 3 ? "Close Ticket" : "Re-Open Ticket"}</button>
                    <div className='h m-d-f'>
                      <h5 className={selectTicket.ticketStatus === 1 ? 'hide-sm open-c' : selectTicket.ticketStatus === 2 ? 'hide-sm inprogress-c' : selectTicket.ticketStatus === 3 ? 'hide-sm closed-c' : selectTicket.ticketStatus === 4 ? 'hide-sm reopen-c' : null}>{selectTicket.statusName}</h5>
                      <button class='btn hide-sm' onClick={() => { changeStatus() }}>{selectTicket.ticketStatus !== 3 ? "Close Ticket" : "Re-Open Ticket"}</button>
                    </div>
                  </div>
                  <div className='td-div2 '>
                    <InfiniteScroll
                      isReverse={true}
                      pageStart={0}
                      loadMore={messageDataFunc}
                      hasMore={true}
                    >
                      {messages && messages.map((m, id) =>
                        m.customerOrstaff === 0 ?
                          <div className='message-dive-outer'>
                            <div className='message-right' key={id}>
                              <h2>{userDetails.firstName} {userDetails.lastName}</h2>
                              <p>{m.message}</p>
                              {JSON.parse(m.mediaUrls) && JSON.parse(m.mediaUrls).map((i, id) => <img key={id} src={i} onClick={() => imagesView(m.mediaUrls)} alt='' className='message-images' />)}
                              <div className='message-dt-right'>{getTimeDifference(userDetails.timeZone,m.createdTime)}</div>
                            </div>
                            {m.custAvatar ? <img src={userDetails.avatar ? `${userDetails.avatar}?${Math.random()}` : HeaderProfileIcon} alt='profile-img' className='message-img' /> : <div className='message-img' />}
                          </div> :
                          <div className='message-dive-outer'>
                            {m.staffAvatar ? <img src='/images/chat-logo.png' alt='profile-img' className='message-img' /> : <div className='message-img' />}
                            <div className='message-left' key={id}>
                              <h2>{"My Package Forwarder"}</h2>
                              <p>{m.message}</p>
                              {JSON.parse(m.mediaUrls) && JSON.parse(m.mediaUrls).map((i, id) => <img key={id} src={i} onClick={() => imagesView(m.mediaUrls)} alt='' className='message-images' />)}
                              <div className='message-dt-left'>{getTimeDifference(userDetails.timeZone,m.createdTime)}</div>
                            </div>
                          </div>
                      )}
                    </InfiniteScroll>
                  </div>
                  <form onSubmit={(e) => sunbmitForm(e)}>
                  <span className='filesNames'>{messagesDetails.fileNames}</span>
                    <div className='message-send'>
                      <div clas="file_input_wrap">
                        <input type="file" name="imageUpload" accept="image/*" onChange={(e) => setFiles(e)} id="imageUpload" class="hide" multiple />
                        <label for="imageUpload" class="btn btn-large"><span className="material-symbols-outlined attach-icon">attach_file</span> </label>
                      </div>
                      <input type='text' name='message' value={messagesDetails.message} className='form-control' onChange={(e) => inputHandler(e, setMessagesDetails)} />
                      <button class="btn btn-large btn-blue"><span className={`material-symbols-outlined send-icon ${loading && 'disabled'}`}>send {loading && <Spinner size='sm' />}</span> </button>
                    </div>
                  </form>
                </div>
              </div> : !loading ? 
              <div className='chat-img-center hide-xs'>
                <img src='/images/noPackagesImage.svg' alt='no-packages-img' className='img-fluid' />
                <h4>Have been selected yet</h4>
              </div> : navigate('/support/new-ticket')}
            </div>:null}
            </>
         ):null}
        </div>
        <div className='df-w-60 '>
          <div className='chat-dash hide-xs'>
            {selectTicket && selectTicket.selected ? <div className='ticket-details'>
              <div className='message-div'>
                <div className='mes-div-header'>
                  <div className='mes-d-h-flex'>
                    <img src={HeaderProfileIcon} alt='profile-img' className='header-profile-img' />
                    <div>
                      <h4>Subject: {selectTicket.packageId ? `${selectTicket.packageId} related issue` : selectTicket.subject}</h4>
                      <h6>{getTimeDifference(userDetails.timeZone,selectTicket.createdTime)}</h6>
                    </div>
                  </div>
                  <h5 className={selectTicket.ticketStatus === 1 ? 'hide-xs open-c' : selectTicket.ticketStatus === 2 ? 'hide-xs inprogress-c' : selectTicket.ticketStatus === 3 ? 'hide-xs closed-c' : selectTicket.ticketStatus === 4 ? 'hide-xs reopen-c' : null}>{selectTicket.statusName}</h5>
                  <button class="btn hide-xs" onClick={() => { changeStatus() }}>{selectTicket.ticketStatus !== 3 ? "Close Ticket" : "Re-Open Ticket"}</button>
                </div>
                <div className='h m-d-f'>
                  <h5 className={selectTicket.ticketStatus === 1 ? 'hide-sm open-c' : selectTicket.ticketStatus === 2 ? 'hide-sm inprogress-c' : selectTicket.ticketStatus === 3 ? 'hide-sm closed-c' : selectTicket.ticketStatus === 4 ? 'hide-sm reopen-c' : null}>{selectTicket.statusName}</h5>
                  <button class='btn hide-sm' onClick={() => { changeStatus() }}>{selectTicket.ticketStatus !== 3 ? "Close Ticket" : "Re-Open Ticket"}</button>
                </div>
                <div className='td-div2 '>
                  <InfiniteScroll
                    isReverse={true}
                    pageStart={0}
                    loadMore={messageDataFunc}
                    hasMore={true}
                  >
                    {messages && messages.map((m, id) =>
                      m.customerOrstaff === 0 ?
                        <div className='message-dive-outer'>
                          <div className='message-right' key={id}>
                            <h2>{userDetails.firstName} {userDetails.lastName}</h2>
                            <p>{m.message}</p>
                            {JSON.parse(m.mediaUrls) && JSON.parse(m.mediaUrls).map((i, id) => <img key={id} src={i} onClick={() => imagesView(m.mediaUrls)} alt='' className='message-images' />)}
                            <div className='message-dt-right'>{getTimeDifference(userDetails.timeZone,m.createdTime)}</div>
                          </div>
                          {m.custAvatar ? <img src={userDetails.avatar ? `${userDetails.avatar}?${Math.random()}` : HeaderProfileIcon} alt='profile-img' className='message-img' /> : <div className='message-img' />}
                        </div> :
                        <div className='message-dive-outer'>
                          {m.staffAvatar ? <img src='/images/chat-logo.png' alt='profile-img' className='message-img' /> : <div className='message-img' />}
                          <div className='message-left' key={id}>
                            <h2>{"My Package Forwarder"}</h2>
                            <p>{m.message}</p>
                            {JSON.parse(m.mediaUrls) && JSON.parse(m.mediaUrls).map((i, id) => <img key={id} src={i} onClick={() => imagesView(m.mediaUrls)} alt='' className='message-images' />)}
                            <div className='message-dt-left'>{getTimeDifference(userDetails.timeZone,m.createdTime)}</div>
                          </div>
                        </div>
                    )}
                  </InfiniteScroll>
                </div>
                <form onSubmit={(e) => sunbmitForm(e)}>
                <span className='filesNames'>{messagesDetails.fileNames}</span>
                  <div className='message-send'>
                    <div clas="file_input_wrap">
                      <input type="file" name="imageUpload" accept="image/*" onChange={(e) => setFiles(e)} id="imageUpload" class="hide" multiple />
                      <label for="imageUpload" class="btn btn-large"><span className="material-symbols-outlined attach-icon">attach_file</span> </label>
                    </div>
                    <input type='text' name='message' value={messagesDetails.message} className='form-control' onChange={(e) => inputHandler(e, setMessagesDetails)} />
                    <button class="btn btn-large btn-blue"><span className={`material-symbols-outlined send-icon ${loading && 'disabled'}`}>send {loading && <Spinner size='sm' />}</span> </button>
                  </div>
                </form>
              </div>
            </div> : !loading ? <div className='chat-img-center'>
              <img src='/images/full-support.png' alt='no-packages-img' className='img-fluid w-60'  />
              <h4>Elevating ticketing solutions to optimize efficiency and guest satisfaction</h4>
            </div> : navigate('/support/new-ticket')}
          </div>
        </div>
      </div>
      <ImagesSliderModal setIsOpenImages={setOpenImagesModal} isOpen={openImagesModal} images={images} />
    </div>
  );
};

export default CustomerTicket
