import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../pages/AddMoneyToWallet/AddMoneyToWallet.css';
import { CardExpiryElement, CardNumberElement, CardCvcElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { WalletMoney, PaymentInfoBillingAddressForm, AddressPaymentCard } from '../index.js';
import { Axios } from '../../hooks';
import { refreshTopSectionData } from '../../store/topSectionData';
import { loadStripe } from '@stripe/stripe-js';
import { httpStatus } from '../../utils';
import { hotjar } from 'react-hotjar';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaymentSuccess from '../../Modals/PaymentSuccess.js';
import PaymentFailed from '../../Modals/PaymentFailed.js'
import { setWalletBalance } from '../../store/userDetailsSlice';

const style = {
    layout: "horizontal",
    color: 'gold',
    shape: 'rect',
    label: 'paypal',
    height: 55,
    weight: 55,
    disableMaxWidth: true,
    width: 100,
    tagline: true
};


const AddMoneyToWallet = () => {
    const [formData, setFormData] = useState({ firstName: '', lastName: '', phone: '', addressLine1: '', addressLine2: '', countryId: '', stateId: '', cityId: '', zip: '' });
    const [paymentOptions, setPaymentOptions] = useState({ card: false, amazonpay: false, paypal: false });
    const location = useLocation();
    const {  balance } = location.state;
    const [selectedAddressAndCard, setSelectedAddressAndCard] = useState({});
    const { userDetails,updateWallet } = useSelector(state => state.userData);
    const elements = useElements();
    const stripe = useStripe();
    const [saveCard, setSaveCard] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_TEST}`);
    const [cvc, setCvc] = useState(false);
    const [cardsAndAddresses, setCardAndAddresses] = useState([]);
    const [finalTotal,setFinalTotal]= useState(0)
    const [flagPaymentSuccess,setFlagPaymentSuccess] = useState(false);
    const [flagPaymentFail,setFlagPaymentFail] = useState(false);
    const [failPaymentMessage,setFailPaymentMessage] = useState(null);
    const [seconds,setSeconds]= useState(0);
    // const [{ isPending }] = usePayPalScriptReducer();

    useEffect(() => {
        hotjar.initialize(3576088, 6);
    }, []);


    useEffect(() => {
            (async () => {
                try {
                    const res = await Axios.get('/payment', { headers: { accessToken: localStorage.getItem('AccessToken') } });
                    setCardAndAddresses(res.data.data.map(element => ({ ...element, selected: false })));
                    if (res.data.data.length === 0) {
                        setPaymentOptions({ card: true, amazonpay: false, paypal: false });
                    }
                } catch (err) {
                    httpStatus(err)
                }
            })();
    }, []);

    const selectAddressAndCardFunc = (cardDetails) => {
        setPaymentOptions({ card: false, amazonpay: false, paypal: false });
        if (selectedAddressAndCard) {
            if (selectedAddressAndCard.id === cardDetails.id) {
                setCardAndAddresses(prev => prev.map(element => ({ ...element, selected: false })));
                setSelectedAddressAndCard({});
            } else {
                setCardAndAddresses(prev => prev.map(element => element.id === cardDetails.id ? { ...element, selected: true } : { ...element, selected: false }));
                setSelectedAddressAndCard(cardDetails);
            }
        } else {
            setCardAndAddresses(prev => prev.map(element => element.id === cardDetails.id ? { ...element, selected: true } : { ...element, selected: false }));
            setSelectedAddressAndCard(cardDetails);
        }
    }

    const createNewAddress = async (formData) => {
        try {
            const newAddress = await Axios.post('/customers/address', { ...formData }, { headers: { accessToken: localStorage.getItem('AccessToken') } });
            // setNewaddressId(newAddress.data.data.insertId)
            return newAddress.data.data.insertId
        } catch (e) {
            httpStatus(e)
            setIsLoading(false);
            return
        }
    }

    async function createOrder() {
        setCardAndAddresses(prev => prev.map(element => ({ ...element, selected: false })));
        setSelectedAddressAndCard({});
        setPaymentOptions({ card: false, amazonpay: false, paypal: true });
        if(parseFloat(finalTotal)===0.00){
            toast('Please enter amount',{type: 'error'})
            return 
        }
        try {
            const res = await Axios.post(`payment/paypal`, { amount: finalTotal.toString() }, { headers: { accessToken: localStorage.getItem('AccessToken') } });
            return res.data.data.id
        } catch (e) {
            console.log(e)
            httpStatus(e)
        }
    }

    async function onApprove(data) {
        try {
            const res = await Axios.post(`payment/paypal/capturePayment`, data, { headers: { accessToken: localStorage.getItem('AccessToken') } });
            let result = res.data.data
            if (res.data.data.status === 'COMPLETED') {
                result.status = 'succeeded'
                await paymentSuccess(result, null, createNewAddress, 'Paypal')
            } else {
                toast(res.data.data.status, { type: 'error' })
                setIsLoading(false);
            };
        } catch (e) {
            httpStatus(e)
        }
    }

    async function onCancel(data) {
        console.log(data)
    }

    async function onError(data) {
        toast(data,{type : 'error'})
    }

    async function onClick() {
        setCardAndAddresses(prev => prev.map(element => ({ ...element, selected: false })));
        setSelectedAddressAndCard({});
        setPaymentOptions({ card: false, amazonpay: false, paypal: true });
    }

    const paymentSuccess = async (result, token, createNewAddress, paymentService) => {
        if (paymentService === 'Stripe') {
            result = result.paymentIntent
        }
        try {
            let response = await Axios.post(`/customers/wallet/amount`,
                {
                    finalAmount: finalTotal.toString(),
                    token,
                    saveCard: saveCard,
                    address: saveCard ? await createNewAddress(formData) : selectedAddressAndCard?.addressId?.id,
                    payment: result,
                    paymentService: paymentService
                },
                { headers: { accessToken: localStorage.getItem('AccessToken') } })
            if (response.data.status) {
                setFlagPaymentSuccess(false)
                dispatch(setWalletBalance(updateWallet?false:true))
                toast(response.data.data, { type: 'info' });
                setSeconds(0)
                setTimeout(() => {
                    dispatch(refreshTopSectionData());
                    navigate('/payment-success',{state:{transaction_id:result.id,packageIds:undefined,finalTotal:finalTotal,totalOrder:finalTotal}})
                    // navigate('/wallet')
                    setIsLoading(false);
                }, 3000);
            }
        } catch (e) {
            setFlagPaymentSuccess(false)
            setFlagPaymentFail(true)
            httpStatus(e)
            setIsLoading(false);
        }
    }

    const proceedForPayment = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setSeconds(0)
        if(parseFloat(finalTotal) === 0.00) {
            setIsLoading(false);
            toast(`Please enter amount`, { type: 'error' });
            return 
        }
        try {
            if (paymentOptions.card) {
                const emptyData = ['firstName', 'lastName', 'phone', 'addressLine1', 'addressLine2', 'countryId', 'zip'].filter(ele => formData[ele] === '');
                if (emptyData.length > 0) {
                    toast(`Please enter ${emptyData.join(', ')} Details`, { type: 'error' });
                    setIsLoading(false);
                    return
                }
            } else {
                if (cardsAndAddresses.length > 0 && Object.keys(selectedAddressAndCard).length === 0) {
                    toast('Please Select Card', { type: 'error' });
                    setIsLoading(false);
                    return
                }
            }

            let token;
            let card;
            if (selectedAddressAndCard.id) {
                if (!cvc) {
                    toast('Please enter CVV', { type: 'error' });
                    setIsLoading(false);
                    return
                }
            }
            else {
                const name = document.getElementById('cardName').value
                if (!name) {
                    toast('Please enter complete card details', { type: 'error' });
                    setIsLoading(false);
                    return
                }
                card = elements.getElement(CardNumberElement);
                token = await stripe.createToken(card, { name: name })
                if (token.error) {
                    toast(token.error.message, { type: 'error' });
                    setIsLoading(false);
                    return
                }
            }
            setSeconds(0)
            setFlagPaymentSuccess(true)
            const res = await Axios.post(`payment/stripe/paymentIntent`, { amount: (finalTotal * 100).toFixed(0), packageIds:[]}, { headers: { accessToken: localStorage.getItem('AccessToken') } });
            if (res.data.data.status === "requires_payment_method") {
                let result;
                if (selectedAddressAndCard?.card?.id) {
                    result = await stripe.confirmCardPayment(res.data.data.client_secret, {
                        payment_method: selectedAddressAndCard.card.id,
                        payment_method_options: {
                            card: { cvc: cvc }
                        }
                    })
                } else {
                    result = await stripe.confirmCardPayment(res.data.data.client_secret, {
                        payment_method: {
                            card: card,
                            billing_details: {
                                name: `${userDetails.firstName} +${userDetails.lastName}`,
                            },
                        }
                    });
                }
                if (!result.error && result.paymentIntent.status === "succeeded") {
                    await paymentSuccess(result, token, createNewAddress, 'Stripe')
                } else {
                    setFlagPaymentFail(true)
                    setFlagPaymentSuccess(false)
                    setFailPaymentMessage(result.error.message)
                    toast(result.error.message, { type: 'error' })
                    setIsLoading(false);
                };
            } else {
                setFlagPaymentFail(true)
                setFlagPaymentSuccess(false)
                setFailPaymentMessage(res.data.data)
                toast(res.data.data, { type: 'error' });
                setIsLoading(false);
            };
        } catch (e) {
            setFlagPaymentSuccess(false)
            setFlagPaymentFail(true)
            httpStatus(e)
            setTimeout(() => {
                dispatch(refreshTopSectionData());
                setIsLoading(false);
            }, 3000);
        };
    };

    const changePaymentMethod = () => {
        setCardAndAddresses(prev => prev.map(element => ({ ...element, selected: false })));
        setSelectedAddressAndCard({});
        setPaymentOptions({ card: true, amazonpay: false, paypal: false });
    }
    
    return (
        <div className='addressAndPaymentPage-container'>
            <div className='checkout-calc-heading-subheading'>
                <div className='shipping-carrier-heading'>Add Money To Wallet</div>
            </div>

            <div className='payment-method-page-container'>
                <div className='address-and-order-summary-container'>
                    <div className='payment-address-payments-container'>
                        {
                            cardsAndAddresses.length > 0 &&
                            (
                                <div className='payment-method-addresses-container card'>
                                    <div className='secondary-bold-text'>Saved Cards</div>
                                    <div className='payment-cards-container'>
                                        {
                                            cardsAndAddresses.map(element => <Elements stripe={stripePromise} key={element.id}><AddressPaymentCard element={element} setCvc={setCvc} cvc={cvc} selectAddressAndCardFunc={selectAddressAndCardFunc} /></Elements>)
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className='address-and-order-summary-container'>
                            <div className='payment-address-payments-container card payment_info_form'>
                                <div className='payment-options'>
                                    <div className={paymentOptions.card ? 'selected-payment-option cursor' : 'payment-option cursor'} onClick={() => changePaymentMethod()}>
                                        <img src='/images/payment-card.svg' alt='payment-method-img' className='payment-method-card-img' />
                                        {/* <div className='correct-icon-bg'>
                                            <span className="material-symbols-outlined payment-selected-icon">done</span>
                                        </div> */}
                                        <div className='card-box-text'>New Credit/Debit card</div>
                                    </div>
                                    {/* <div className={paymentOptions.paypal ? 'selected-payment-option cursor' : 'payment-option low-opacity cursor'}>
                                    <img src='/images/paypal.svg' alt='payment-method-img' className='payment-method-card-img' />
                                    <div className='correct-icon-bg'>
                                        <span className="material-symbols-outlined payment-selected-icon">done</span>
                                    </div>
                                </div> */}
                                    {/* Paypal button code */}
                                    {/* {userDetails.countryName !== 'Nigeria' && <div className='paypal-method payment-option'  > */}
                                    {/* <PayPalScriptProvider options={{ clientId: `${process.env.REACT_APP_PAYPAL_CLIENTID}`, components: "buttons", currency: "USD" }}> */}
                                            {/* {<PayPalButtons
                                                style={style}
                                                disabled={false}
                                                forceReRender={[finalTotal]}
                                                fundingSource="paypal"
                                                createOrder={createOrder}
                                                onApprove={onApprove}
                                                onCancel={onCancel}
                                                onError={onError}
                                                onClick={onClick}
                                            />} */}
                                        {/* </PayPalScriptProvider> */}
                                    {/* <div className='correct-icon-bg'> */}
                                            {/* <span className="material-symbols-outlined payment-selected-icon">done</span> */}
                                        {/* </div> */}
                                    {/* <div className='paypal-box-text'>Pay with Paypal</div> */}
                                    {/* </div>} */}
                                </div>
                                {
                                    paymentOptions.card &&
                                    <PaymentInfoBillingAddressForm formData={formData} setFormData={setFormData} CardExpiryElement={CardExpiryElement} CardCvcElement={CardCvcElement} CardNumberElement={CardNumberElement} saveCard={saveCard} setSaveCard={setSaveCard} />
                                }
                            </div>
                        </div>
                        {/* {
                            paymentOptions.paypal &&
                            (
                                <div className='payment-address-payments-container card payment_info_form'>
                                    <div className='payment-options'>
                                        <div className={paymentOptions.card ? 'selected-payment-option cursor' : 'payment-option cursor'} onClick={() => setPaymentOptions({ card: true, amazonpay: false, paypal: false })}><img src='/images/payment-card.svg' alt='payment-method-img' /><div className='correct-icon-bg'><span className="material-symbols-outlined payment-selected-icon">done</span></div></div>
                                        <div className={paymentOptions.paypal ? 'selected-payment-option cursor' : 'payment-option cursor'} onClick={() => setPaymentOptions({ card: false, amazonpay: false, paypal: true })}><img src='/images/paypal.svg' alt='payment-method-img' /><div className='correct-icon-bg'><span className="material-symbols-outlined payment-selected-icon">done</span></div></div>
                                    </div>
                                    <div className='third-party-payments-container'>
                                        <div className='img-wallet-money'>
                                            <img src={paymentOptions.amazonpay ? '/images/amazonpay.svg' : '/images/paypal.svg'} alt='payment-gateway-img' />
                                            <div className='wallet-money'>Wallet: ${'49.88'}</div>
                                        </div>
                                        <Form className='amazon-payment-form'>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Enter your email or mobile number to get started.</Form.Label>
                                                <Form.Control type="email"
                                                    className='form-input'
                                                    placeholder="Enter email or mobile number" />
                                            </Form.Group>
                                            <div className='forgot-text'>Forgot email?</div>
                                            <input type='submit' value='Login and Pay >' className='amazonpay-submit-button' />
                                        </Form>
                                    </div>
                                </div>
                            )
                        } */}
                    </div>
                    <WalletMoney proceedForPayment={proceedForPayment} finalTotal={finalTotal} setFinalTotal={setFinalTotal} balance={balance} isLoading={isLoading} />
                </div>
            </div>
            <PaymentSuccess isOpen={flagPaymentSuccess} height={window.outerHeight} setSeconds={setSeconds} seconds={seconds}/>
            <PaymentFailed failPaymentMessage={failPaymentMessage} isOpen={flagPaymentFail} setFlagPaymentFail={setFlagPaymentFail} setFlagPaymentSuccess={setFlagPaymentSuccess}/>
        </div>
    )
}

export default AddMoneyToWallet
